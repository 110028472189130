import React from 'react';

import { Button } from 'react-bootstrap';

const ButtonPanel = ({language, handleTemplate, theme}) => {
    
    return (
    <div className='d-flex w-100 flex-column gap-2 align-items-center justify-content-between'>
        <Button 
            variant={theme === 'dark' ? 'dark' : 'secondary'}
            className='w-100 mt-2' disabled
            onClick={handleTemplate}
        >
            {{'EN': 'Tree visualisation', 'FR': 'Visualisation de l\'arbre'}[language]}
            <div style={{fontSize: '0.5em'}}>Coming soon</div>
        </Button>
        <Button 
            variant={theme === 'dark' ? 'dark' : 'secondary'}
            className='w-100'
            onClick={handleTemplate}
        >
            {{'EN': 'Use code to create child', 'FR': 'Utiliser le code pour génerer un descendant'}[language]}
        </Button>
        <Button 
            variant={theme === 'dark' ? 'dark' : 'secondary'}
            className='w-100'
            onClick={handleTemplate}
        >
            {{'EN': 'Poster Template', 'FR': 'Template du Poster'}[language]}
        </Button>
    </div>
        
    );
};

export default ButtonPanel;
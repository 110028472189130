import { useState, createContext} from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children, theme, setTheme }) => {

    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            {children}
        </ThemeContext.Provider>
    );
};



import React from 'react';
import { useNavigate } from 'react-router-dom';

const TreeStats = ({language, posterData}) => {

    const navigate = useNavigate();

    return (
        <div 
            className='w-100 px-3 py-2' 
            style={{borderRadius: "10px", backgroundColor: "var(--bg-color-1)", border: "2px solid var(--bg-color-4)"}}
        > 
            <h6>{{'EN': 'Poster Genealogy', 'FR': 'Généalogie du Poster'}[language]}</h6><hr/>
            <div className='d-flex w-100 flex-row justify-content-between'>
                <div>Parent</div>
                {posterData?.data?.family_tree.ascendance[0] !== 'root' ?  
                    <div 
                        className='button-text'
                        onClick={() => navigate(`/library/${posterData?.data?.family_tree.ascendance[0]}`)}
                    >
                        {posterData?.data?.family_tree.ascendance[0]}
                    </div>:
                    <strong>Root</strong>}
            </div>
            <div className='d-flex w-100 flex-row justify-content-between'>
                <div>{{'EN': 'Number of children', 'FR': 'Nombre d\'enfants'}[language]}</div>
                <div>{posterData?.data?.family_tree.descendance && Object.keys(posterData?.data?.family_tree.descendance).length}</div>
            </div>
            <div className='d-flex w-100 flex-row justify-content-between'>
                <div>{{'EN': 'Number of descendants', 'FR': 'Nombre de descendants'}[language]}</div>
                <div>{posterData?.data?.family_tree.descendant_count}</div>
            </div>
        </div>
    );
};

export default TreeStats;
// Importing Styles

import './styles/default/layout.css';
import './styles/mobile/layout.css';
import './styles/shared/layout.css';
import './styles/shared/sizechoice.css';
import './styles/shared/divers.css';
import './styles/shared/landing.css';

import './styles/shared/cart.css';

import './styles/shared/text.css';

import './styles/shared/checkout.css';
import './styles/default/checkout.css';
import './styles/mobile/checkout.css';

import './styles/shared/custom.css';
import './styles/shared/elements.css';
import './App.css';

import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

import {useState, useEffect} from "react";

import ErrorBoundary from './components/shared/utils/ErrorBoundary';
import FallbackUI from './components/shared/utils/FallbackUI';
import Laboratory from './components/shared/pages/Laboratory';
import About from './components/shared/pages/About';
import LandingPage from './components/shared/pages/LandingPage';
import Cart from './components/shared/pages/Cart';
import Library from './components/shared/pages/Library';
import Checkout from './components/shared/pages/Checkout';
import PaymentCompletion from './components/shared/pages/PaymentCompletion';

import AppProviders from './utils/AppProviders';
import LoggedOut from './components/shared/pages/LoggedOut';

function App() {

  // Device Context 
  const [theme, setTheme] = useState('dark');
  
  return (
  
    <AppProviders theme={theme} setTheme={setTheme}>
      <div className="App" data-theme={theme}>
        <Router>
          <ErrorBoundary FallbackComponent={FallbackUI}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/laboratory" element={<Laboratory/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/cart" element={<Cart/>} />
              <Route path="/checkout" element={<Checkout />}/>
              <Route path="/completion" element={<PaymentCompletion/>} />
              <Route path="/library/:id" element={<Library/>}/>
              <Route path="/logout" element={<LoggedOut/>}/>
              <Route path="/fallback" element={<FallbackUI/>}/>
              <Route path="*" element={<LandingPage />} />
            </Routes>
          </ErrorBoundary>
        </Router>
      </div>
    </AppProviders>
  );
}

export default App;

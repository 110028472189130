import { createContext, useState, useEffect, useContext, useRef } from 'react';
import useFetchData from '../hooks/useFetchData';
import { Button, Modal } from 'react-bootstrap';
import { LanguageContext } from './LanguageContext';
import { ChatRightFill } from 'react-bootstrap-icons';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    // Contexts
    const { language } = useContext(LanguageContext);

    // Authentication State
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [token, getToken] = useFetchData();
    const [refreshToken, getRefreshToken] = useFetchData();

    const [tokenExpirationWarning, setTokenExpirationWarning] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);

    // Timer references
    const sessionTimeout = useRef(null);
    const warningTimeout = useRef(null);

    // Login and Logout Functions
    const requestToken = () => getToken("api/auth");
    const requestRefreshToken = () => getRefreshToken("api/auth/refresh");

    const login = () => setIsAuthenticated(true);

    const logout = () => {
        localStorage.removeItem('session_token');

        clearTimeout(sessionTimeout.current);
        clearTimeout(warningTimeout.current);

        setIsAuthenticated(false);
        tokenExpirationWarning && setTokenExpirationWarning(false);
        !tokenExpired && setTokenExpired(true);
    };

    const warning = () => {
        !tokenExpirationWarning && setTokenExpirationWarning(true);
    };

    // Prevent token request if already authenticated 
    useEffect(() => { 
        clearTimeout(sessionTimeout.current);
        clearTimeout(warningTimeout.current);
        requestToken();
    }, []);

    // Function to start or restart timers + LOGOUT
    const startTimers = (sessionExpirationS, warningTriggerS) => {
        clearTimeout(sessionTimeout.current);
        clearTimeout(warningTimeout.current);
        sessionTimeout.current = setTimeout(logout, sessionExpirationS * 1000);
        warningTimeout.current = setTimeout(warning, warningTriggerS * 1000);
        
    };

    // Set token and initialize timers when a token is received
    useEffect(() => { 
        if (token?.data?.session_token) {

            localStorage.setItem('session_token', token.data.session_token);

            const tokenExpireS = token.data.time_left
            const warningTriggerS = tokenExpireS - (process.env.REACT_APP_EXPIRY_WARNING_M * 60);
        
            setIsAuthenticated(true);
            tokenExpired && setTokenExpired(false);
            startTimers(tokenExpireS, warningTriggerS);
        }
    }, [token?.data]);

    // Reset timers when a refresh token is received
    // + Close the warning modal 
    useEffect(() => {
        if (refreshToken?.data?.session_token) {

            localStorage.setItem('session_token', refreshToken.data.session_token);

            const tokenExpireS = refreshToken.data.time_left
            const warningTriggerS = tokenExpireS - (process.env.REACT_APP_EXPIRY_WARNING_M * 60);

            tokenExpirationWarning && setTokenExpirationWarning(false);
            startTimers(tokenExpireS, warningTriggerS);  
        }
    }, [refreshToken?.data]);

    const SessionExpirationWarning = () => {
        return (
            <Modal show={tokenExpirationWarning} onHide={() => {setTokenExpirationWarning(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <ChatRightFill size={24} className='me-2'/>
                        {{
                            'EN': 'Are you still there?',
                            'FR': 'Toujours là?'
                        }[language]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {{
                        'EN': `Your session will expire in ${process.env.REACT_APP_EXPIRY_WARNING_M} minutes.`,
                        'FR': `Votre session expire dans ${process.env.REACT_APP_EXPIRY_WARNING_M} minutes.`
                    }[language]}
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className='w-100' 
                        onClick={requestRefreshToken}
                        variant='dark'
                    >
                        {{'EN': 'Extend Session', 'FR': 'Poursuivre la session'}[language]}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const SessionExpired = () => {
        return (
            <Modal show={tokenExpired} onHide={() => {setTokenExpired(false)}}>
                <Modal.Header>
                    <Modal.Title>
                        <ChatRightFill size={24} className='me-2'/>
                        {{
                            'EN': `Your session is over !`,
                            'FR': `Votre session est terminée !`
                        }[language]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {{
                        'EN': `Click on the button below to re-authenticate.`,
                        'FR': `Clicker sur le bouton ci-dessous pour vous ré-authentifier.`
                    }[language]}
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        className='w-100' 
                        onClick={requestToken}
                        variant='dark'
                    >
                        {{'EN': 'New Session', 'FR': 'Nouvelle session'}[language]}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, requestToken, requestRefreshToken, login, logout }}>
            {children}
            {<SessionExpirationWarning />}
            {<SessionExpired/>}
        </AuthContext.Provider>
    );
};

import { useContext } from "react";
import { DeviceContext } from "../../../contexts/DeviceContext";

import CheckoutTemplateDefault from "./CheckoutTemplateDefault";
import CheckoutTemplateMobile from "./CheckoutTemplateMobile";

const CheckoutTemplate = ({
    shipping, 
    payment, 
    handleBackToCart,
}) => {

    const { mobile } = useContext(DeviceContext);

    return (
        <>
        {mobile ? 
            <CheckoutTemplateMobile
                shipping={shipping}
                payment={payment}
                handleBackToCart={handleBackToCart}
            />:
            <CheckoutTemplateDefault
                shipping={shipping}
                payment={payment}
                handleBackToCart={handleBackToCart}
            />
        }     
        </>
    );
};

export default CheckoutTemplate;
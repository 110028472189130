import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LabeledSelect from '../../ui/LabeledSelect';
import ColorSelect from '../../ui/ColorSelect';

import {useContext} from 'react';
import {LanguageContext} from '../../../../contexts/LanguageContext';
import tr from '../../../../assets/translations/general.json';


const Canvas = ({options, canvasData, setCanvasData, utils}) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
            <Row className='mb-2' >
                <Col className='w-50'>
                    <LabeledSelect
                        label={tr.canvas.placeholder[language] || "-"}
                        options={options && options.placeholder_type}
                        currentOption={canvasData.placeholder_type}
                        handleSelect={(value) => setCanvasData({...canvasData, 'placeholder_type': value})}
                    />
                    <LabeledSelect
                        label={tr.canvas.legend_placement[language] || "-"}
                        options={options && options.legend_layout}
                        currentOption={canvasData.legend_layout}
                        handleSelect={(value) => setCanvasData({...canvasData, 'legend_layout': value})}
                    />
                </Col>
                <Col className='d-flex justify-content-center'>
                    <div 
                        className='p-1 h-100 mt-2 d-flex flex-column'
                        style={{
                            border:"3px solid var(--color-4)", borderRadius: "0.5em",
                            width: "5em", height: "8em", position: "relative",
                        }}
                    >
                        {canvasData.legend_layout === 'Top Large' && <div className='w-100' style={{height: "1em",  borderRadius: "0.2em", backgroundColor: "var(--color-4)"}}/>}
                        {canvasData.legend_layout === 'Top Small' && <div className='w-100' style={{height: "0.5em", borderRadius: "0.2em",  backgroundColor: "var(--color-4)"}}/>}
                        <div className='p-0 py-1' style={{flexGrow: "1", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {canvasData.placeholder_type === 'Circle' && <div className='w-100' style={{borderRadius: "100px", backgroundColor: "var(--color-4)", aspectRatio: "1/1"}}/>}
                            {canvasData.placeholder_type === 'Full' && <div className='w-100 h-100' style={{borderRadius: "0.3em", backgroundColor: "var(--color-4)"}}/>}
                            {canvasData.placeholder_type === 'Square' && <div className='w-100' style={{borderRadius: "0.5em", backgroundColor: "var(--color-4)", aspectRatio: "1/1"}}/>}
                        </div>
                        {canvasData.legend_layout === 'Bottom Large' && <div className='w-100' style={{height: "1em", borderRadius: "0.2em", backgroundColor: "var(--color-4)"}}/>}
                        {canvasData.legend_layout === 'Bottom Small' && <div className='w-100' style={{height: "0.5em", borderRadius: "0.2em", backgroundColor: "var(--color-4)"}}/>}
                    </div>
                </Col>
            </Row>
            <Row className='mb-2'>
                <ColorSelect
                    label={tr.canvas.background_color[language] || "-"}
                    currentColor={canvasData.background_color}
                    handleSelect={(value) => setCanvasData({...canvasData, 'background_color': value})}
                    palettesColors={utils && utils.color_pigments} 
                    options={options?.background_color}   
                />
            </Row>
            <Row>
                <Col>
                    <LabeledSelect
                        label={tr.canvas.background_color_gradient[language] || "-"}
                        options={options && options.background_gradient}
                        currentOption={canvasData.background_gradient}
                        handleSelect={(value) => setCanvasData({...canvasData, 'background_gradient': value})}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Canvas;
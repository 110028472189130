import Button from "react-bootstrap/Button"
import BlockTemplateCollapse from "./BlockTemplateCollapse"
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { LanguageContext } from "../../../../contexts/LanguageContext";

import { useEffect, useState, useContext} from 'react';

const Blocks = ({
    mosaicData, handleDeleteMosaicBlock, handleAddMosaicBlock, handleEditMosaicBlock, utils, options}
) => {

    const [blocksArray, setBlocksArray] = useState([]);

    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);

    useEffect(() => { 
        const updatedBlocksArray = mosaicData.map((m) => {
            return {
                id: m.id,
                block: (
                    <BlockTemplateCollapse
                        key={m.id} blockId={m.id}
                        mosaicData={mosaicData}
                        handleSelect={handleEditMosaicBlock}
                        handleDeleteBlock={handleDeleteMosaicBlock}
                        options={options} utils={utils}
                    />
                )
            };
        });
        setBlocksArray(updatedBlocksArray);
    }, [mosaicData, handleDeleteMosaicBlock, handleEditMosaicBlock, options]) 

    return (
        <div>
            {blocksArray.map(blockObj => blockObj.block)}
            <div className="d-grid gap-2">
                {   
                    mosaicData.length <= 2 && (
                        <Button 
                            variant={theme === 'dark' ? "outline-light" : "outline-secondary"}
                            size="sm" style={{borderStyle: "dashed"}}
                            onClick={handleAddMosaicBlock}
                        >
                            {{'EN': '+ Add Block', 'FR': 'Ajouter un Bloc'}[language]}
                        </Button>)
                }
            </div>
        </div>

    )
}

export default Blocks
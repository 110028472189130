import Button from 'react-bootstrap/Button';
import { CartFill } from 'react-bootstrap-icons';


const ButtonToCart = ({lang, handleGoToCart}) => {

    return (
        <div className='p-2 footer__button d-flex' style={{postion: "relative"}}> 
            <div className="my-2" style={{position:"absolute", bottom: "0", height: "2.7em", flexGrow: "1"}}>
                <Button 
                    className={"px-3 py-3 w-100 h-100 d-flex justify-content-center align-items-center " + `gotocart__button` }
                    style={{border: "3px solid white", borderRadius: "100px", fontWeight: "bold"}}
                    onClick = {() => {handleGoToCart()}}
                >
                    {{'EN': 'Go to Cart', 'FR': 'Panier'}[lang]}<CartFill className='ms-2'/>
                </Button>
            </div>
        </div>
    );
};

export default ButtonToCart;
import {UtilsProvider} from '../contexts/UtilsProvider';
import {CheckoutProvider} from '../contexts/CheckoutContext';
import {DeviceProvider} from '../contexts/DeviceContext';
import {ThemeProvider} from '../contexts/ThemeContext';
import {DraftProvider} from '../contexts/DraftContext';
import {AuthProvider} from '../contexts/AuthContext';
import {LanguageProvider} from '../contexts/LanguageContext';
import {ABSContextProvider } from '../contexts/ABSContext';
import { MosaikUtilsProvider } from '../contexts/MosaikUtilsContext';

const AppProviders = ({ children, theme, setTheme }) => (
  <DeviceProvider>
    <ThemeProvider theme={theme} setTheme={setTheme}>
      <LanguageProvider>
        <AuthProvider>
          <ABSContextProvider>
            <UtilsProvider>
              <CheckoutProvider>
                <MosaikUtilsProvider>
                  <DraftProvider>
                    {children}
                  </DraftProvider>
                </MosaikUtilsProvider>
              </CheckoutProvider>
            </UtilsProvider>
          </ABSContextProvider>
        </AuthProvider>
      </LanguageProvider>
    </ThemeProvider>
  </DeviceProvider>
);

export default AppProviders;
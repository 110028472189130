import {useState, useContext, useRef} from 'react';
import useFadeOnScroll from '../../../hooks/useFadeOnScroll';

import AddressMap from "./shipping/AddressMap";
import ShippingMethodToggle from './shipping/ShippingMethodToggle';

import { CheckoutContext } from '../../../contexts/CheckoutContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { DeviceContext } from '../../../contexts/DeviceContext';
import { UtilsContext } from '../../../contexts/UtilsProvider';

import countryLookup from '../../../assets/countryLookup';

import {Shop, SendFill, Truck, House, PersonLinesFill} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as formik from 'formik';

const Shipping = ({formikRef}) => {

    // Context
    const {checkoutData, setCheckoutData} = useContext(CheckoutContext);
    const {language} = useContext(LanguageContext);
    const {mobile} = useContext(DeviceContext);
    const {orderUtils} = useContext(UtilsContext);

    const [shippingMethod, setShippingMethod] = useState("mondialRelay");

    // Required for mondial relay
    const [selectedSP, setSelectedSP] = useState(null);
    const [refAddress, setRefAddress] = useState(null);

    // Fade on scroll
    const contentRef = useRef(null);
    const { fadeOpacityT, fadeOpacityB } = useFadeOnScroll(contentRef);

    const handleSubmitShipping = async (values) => {

        await new Promise(r => setTimeout(r, 500));
        const {fullName, email, phoneNumber, addressLine1, addressLine2, city, zip, selectedCountry} = values;

        const contact = {
            name: fullName,
            email: email,
            phone_number: phoneNumber
        }

        const shipping = {
            method: shippingMethod,
            name: fullName,
            address: addressLine1,
            address_2: addressLine2,
            city: city,
            postal_code: zip,
            country: selectedCountry,
            to_service_point: shippingMethod === "homeDelivery" ? null : selectedSP.id,
            shipment_methods: shippingMethod === "homeDelivery" ? null : selectedSP.shipment_methods,                     
        }

        setCheckoutData(prev => ({...prev, contact, shipping, ready: true}));
    };
    
    const handleValidation = (values) => {

        const errors = {};

        // Email 
        const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!values.emailConfirm.trim()) {
            errors.emailConfirm = {'EN': 'Email confirmation is required', 'FR': 'Confirmation manquante'}[language];
        }
        if (!values.email.trim()) {errors.email = 'Email is required';}
        if (!email_regex.test(String(values.email).toLowerCase())) {
            errors.email = {'EN': 'Invalid email format', 'FR': 'Format de l\'email invalide'}[language];
        }
        if (values.email.trim() !== values.emailConfirm.trim()) {
            errors.emailConfirm = {'EN': 'Emails do not match', 'FR': 'Les emails ne correspondent pas'}[language];
        }

        // Contact
        if (!values.fullName.trim()) {
            errors.fullName = {'EN': 'Full Name is required', 'FR': 'Le nom complet est requis'}[language];
        }
        if (!values.phoneNumber.trim()) {
            errors.phoneNumber = {'EN': 'Phone Number is required', 'FR': 'Le numéro de téléphone est requis'}[language];
        }
        const phone_regex = /^\+?[1-9]\d{0,2}(\s?\d{1,4})+\s*$/;
        if (!phone_regex.test(values.phoneNumber)) {
            errors.phoneNumber = {'EN': 'Invalid phone number format', 'FR': 'Format de numéro de téléphone invalide'}[language];
        }

        // Shipping Address
        if (values.selectedCountry === 'default') {
            errors.selectedCountry = {'EN': 'Country is required', 'FR': 'Le pays est requis'}[language];
        }
        if (!values.addressLine1.trim()) {
            errors.addressLine1 = {'EN': 'Address is required', 'FR': 'L\'adresse est requise'}[language];
        }
        if (!values.city.trim()) {
            errors.city = {'EN': 'City is required', 'FR': 'La ville est requise'}[language];
        }  
        if (!values.zip.trim()) {
            errors.zip = {'EN': 'Postal Code is required', 'FR': 'Le code postal est requis'}[language];
        }

        if (shippingMethod === "mondialRelay") {
            /*
            if (!selectedSP?.to_service_point || !selectedSP?.shipment_methods?.length) {
                errors.selectedSP = {'EN': 'Service point is not available', 'FR': 'Point relais non disponible'}[language];
            }*/
            if (!selectedSP) {
                errors.selectedSP = {'EN': 'Select a service point', 'FR': 'Sélectionnez un point relais'}[language];
            }
        }

        return errors;
    }

    const formatZip = (event) => {

        const zip = event.target.value;
        const formattedEvent = {
            ...event,
            target: {
                name: event.target.name,
                value: zip.replace(/[^a-z0-9]/gi, '')
            },
        };
        return formattedEvent;
    };

    const TitleComponent = (Icon, description, mt) => (
        <div 
            className={`w-100 px-3 pb-2 mb-2 mt-${mt} d-flex align-items-center`}
            style={{fontSize: "1.1em", fontWeight: "550", borderBottom: "solid 1px var(--color-3)"}}
        >
            <Icon className='me-3' style={{fontSize: "1em"}}/> {description[language]}
        </div>
    )

    const {Formik} = formik;

    return (
        <>
            <div className='section-title'>
                <SendFill className='me-2' style={{fontSize: "1em"}}/>
                {{'EN': 'Shipping', 'FR': 'Livraison'}[language]}
            </div>
            {!mobile && <div style={{position: "relative"}}>
                <div 
                    style={{borderRadius: "10px 10px 0 0", height: fadeOpacityT * 50}} 
                    className='summary-section-scroll-fade-top'>
                {' '}</div>
            </div>}
            <div className={`h-100 w-100 section ${mobile? '':'section-shipping'}`} ref={contentRef}>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        fullName: '', email: '', emailConfirm: '', phoneNumber: '',
                        addressLine1: '', addressLine2: '', zip: '', city: '',
                        selectedCountry: 'default', selectedSP: null,
                    }}
                    onSubmit={(values, formikBag) => handleSubmitShipping(values, formikBag)}
                    validate={(values) => handleValidation(values)}
                    validateOnChange={true}
                >
                    {({handleChange, values, touched, errors, isValid}) => (
                        <Form
                            id="shipping-form" noValidate
                            onSubmit={(values, formikBag) => handleSubmitShipping(values, formikBag)}
                            className="ps-3 pe-4 pt-3"
                        >
                            <ShippingMethodToggle shippingMethod={shippingMethod} setShippingMethod={setShippingMethod}/>
                            {TitleComponent(PersonLinesFill , {'EN': 'Contact Details', 'FR': 'Coordonnées'}, 1)}
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'Email Address', 'FR': 'Adresse email'}[language]}    
                                        </Form.Label>
                                        <Form.Control
                                            name="email"
                                            placeholder="Email" 
                                            value={values.email} 
                                            onChange={handleChange}
                                            isInvalid={touched.email && !!errors.email}
                                            isValid={touched.email && !errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'Email Confirmation', 'FR': 'Confirmation Email'}[language]}
                                        </Form.Label>
                                        <Form.Control
                                            name='emailConfirm'
                                            placeholder="Email Confirmation" 
                                            value={values.emailConfirm} 
                                            onChange={handleChange}
                                            isInvalid={touched.emailConfirm && !!errors.emailConfirm}
                                            isValid={touched.emailConfirm && !errors.emailConfirm}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.emailConfirm}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'Full Name', 'FR': 'Nom Complet'}[language]}
                                        </Form.Label>
                                        <Form.Control 
                                            name='fullName'
                                            placeholder={{'EN': 'First & Last Name', 'FR': 'Prénom & Nom'}[language]}
                                            value={values.fullName} 
                                            onChange={handleChange}
                                            isInvalid={touched.fullName && !!errors.fullName}
                                            isValid={touched.fullName && !errors.fullName}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                                    </Form.Group>                 
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'Phone Number', 'FR': 'Numéro de Téléphone'}[language]}
                                        </Form.Label>
                                        <Form.Control
                                            name='phoneNumber'
                                            placeholder="+33 6 80 80 80 80" 
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                            isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                            isValid={touched.phoneNumber && !errors.phoneNumber}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                                    </Form.Group>                      
                                </Col>
                            </Row>
                            {shippingMethod === "homeDelivery" ?
                                TitleComponent(Truck , {'EN': 'Shipping Address', 'FR': 'Adresse de Livraison'}, 4):
                                TitleComponent(House , {'EN': 'Billing Address', 'FR': 'Adresse de Facturation'}, 4)
                            }   
                            <Form.Group className="mb-2">
                                <Form.Label className='section-formlabels'>
                                    {{'EN': 'Country', 'FR': 'Pays'}[language]}
                                </Form.Label>
                                <Form.Select 
                                    value={values.selectedCountry} 
                                    name='selectedCountry'
                                    onChange={handleChange}
                                    isInvalid={touched.selectedCountry && values.selectedCountry === 'default'}
                                    isValid={touched.selectedCountry && values.selectedCountry !== 'default'}
                                >
                                    <option value='default'>
                                        {{'EN': 'Select country', 'FR': 'Selectionner un pays'}[language]}
                                    </option>
                                    {
                                        orderUtils?.data?.delivery_countries && orderUtils.data.delivery_countries.map((country, index) =>
                                            <option key={index} value={country}>{countryLookup[country]}</option>
                                        )
                                    }   
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.selectedCountry}</Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'Address - Line 1', 'FR': 'Adresse - Ligne 1'}[language]}
                                        </Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="addressLine1"
                                            placeholder="Address" 
                                            value={values.addressLine1} 
                                            onChange={handleChange}
                                            isInvalid={touched.addressLine1 && !!errors.addressLine1}
                                            isValid={touched.addressLine1 && !errors.addressLine1}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.addressLine1}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'Address - Line 2', 'FR': 'Adresse - Ligne 2'}[language]}
                                        </Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="addressLine2"
                                            placeholder={{'EN': 'Appartment, Office, etc.. (facultative)', 'FR': 'Appartement, Bureau, etc.. (facultatif)'}[language]}
                                            value={values.addressLine2} 
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label className='section-formlabels'>
                                            {{'EN': 'City', 'FR': 'Ville'}[language]}
                                        </Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="city"
                                            placeholder={{'EN': 'City', 'FR': 'Ville'}[language]}
                                            value={values.city} 
                                            onChange={handleChange}
                                            isInvalid={touched.city && !!errors.city}
                                            isValid={touched.city && !errors.city}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label style={{fontSize: "small"}}>
                                            {{'EN': 'Postal Code', 'FR': 'Code Postal'}[language]}
                                        </Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="zip"
                                            placeholder="Zip" 
                                            value={values.zip} 
                                            onChange={(e) => handleChange(formatZip(e))}
                                            isInvalid={touched.zip && !!errors.zip}
                                            isValid={touched.zip && !errors.zip}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {shippingMethod === "mondialRelay" &&
                            <>
                            {TitleComponent(Shop, {'EN': 'Service Point Selection', 'FR': "Selection du point relais"}, 4)}
                            <div className="mb-3">
                                <AddressMap 
                                    selectedSP={selectedSP} setSelectedSP={setSelectedSP}
                                    warningSelectedSP={errors.selectedSP} orderUtils={orderUtils}
                                    setRefAddress={setRefAddress} weight={checkoutData?.order?.weight}
                                />
                            </div>
                            </>}
                        </Form>
                    )}
                </Formik>
            </div>
            {!mobile && <div style={{position: "relative"}}>
                <div 
                    style={{
                        borderRadius: "10px 10px 0 0", 
                        height: shippingMethod === "homeDelivery" ? fadeOpacityB * 15 : fadeOpacityB * 50}} 
                    className='summary-section-scroll-fade-bottom'>{' '}
                </div>
            </div>}
        </>
    );
};

export default Shipping;
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import img from '../../../assets/images/divers/spiral.png';

import { useContext } from 'react';
import { UtilsContext } from '../../../contexts/UtilsProvider';
import { Spinner } from 'react-bootstrap';

const TutoCaroussel = () => {

    const { imageBankUrlsKeys } = useContext(UtilsContext);

    return (
        <>
        {!imageBankUrlsKeys ?
            <Spinner variant={'light'} /> :
            <Carousel className='w-100' data-bs-theme="dark">
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={imageBankUrlsKeys['mobile_tuto_1.png']}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={imageBankUrlsKeys['mobile_tuto_2.png']}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={imageBankUrlsKeys['mobile_tuto_3.png']}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
        }   
        </>

    );
};

export default TutoCaroussel;
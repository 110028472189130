import React from 'react';

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import {CartDashFill} from 'react-bootstrap-icons';

const UniSizePoster = ({draft, handleCartRemove}) => {
    return (
        <div key={`unisize-${draft.draftId}`} style={{height: "90%", position: "relative"}}>
            {draft?.image_blob_url && <Image
                key={'image-' + draft.draftId}
                src={draft.image_blob_url}
                style={{height: "100%", width: "auto", objectFit: "cover"}}
                rounded
            />}
            <div key={'close-button-' + draft.draftId} className="image-card__close-button">
                <Button variant='light' style={{boxShadow: "0 10px 10px 0 rgba(0, 0, 0, 0.8)"}} 
                    onClick={() => handleCartRemove(draft.draftId)}>
                <CartDashFill/></Button>
            </div>
        </div>
    );
};

export default UniSizePoster;
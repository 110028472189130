import { useEffect, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";

const useAzureBlobClient = (containerName) => {
    
    const [containerClient, setContainerClient] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {

        const useDB = window.location.hostname === "localhost" && process.env.REACT_APP_ABS_TEST !== "ACTIVE";

        if (!useDB) {
            try {
                const accountName = process.env.REACT_APP_ABS_ACCOUNT_NAME;
                const sasToken = process.env.REACT_APP_ABS_SAS_TOKEN;
                const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net/?${sasToken}`);
                const containerClient = blobServiceClient.getContainerClient(containerName);
                setContainerClient(containerClient);
            } catch (error) {
                console.error('Error setting up Blob Service Client:', error);
                setError('Blob storage is currently unavailable.');
            }
        }
    }, []);

    return { containerClient, error };
};

export default useAzureBlobClient;
import { useEffect, useState } from "react";
import useFetchData from "./useFetchData";

const useImagesUrls = (blobDict, containerClient, setImagesUrls) => {

    /*
    blobDict: Dictionary with image ids as keys and blob names as values
    containerClient: Azure Blob Storage container client
    setImagesUrls: Function to set image urls in parent component

    Returns:
    loading: Boolean indicating if images are still loading

    Description:
    This hook fetches image blobs from Azure Blob Storage and sets the image urls in the parent component.
    The blobDict is a promise with image ids as keys and blob names as data.

    The ImagesUrls is a dictionary with image ids as keys and image urls as values.
    */

    const useDB = window.location.hostname === "localhost" && process.env.REACT_APP_ABS_TEST !== "ACTIVE";
    const [loading, setLoading] = useState(false);

    const [imagesIds, setImagesIds] = useState([]);
    const [imagesB64, getImagesB64] = useFetchData();


    useEffect(() => {
        if ( !blobDict?.data || (!useDB && !containerClient) || blobDict.error) return;
        blobDict?.data && setImagesIds(Object.values(blobDict?.data));
    }, [blobDict?.data, containerClient]);

    
    useEffect(() => {

        // Avoid running if there are no imageIds or containerClient is missing)
        if (imagesIds.length === 0 || (!useDB && !containerClient)) return;

        async function fetchBlobs(blobNames) {
            setLoading(true);

            let blobUrlsDict = {};
            for (const blobName of blobNames) {
                const blobClient = containerClient.getBlobClient(blobName);

                try {
                    //const response = await blobClient.download();
                    //const blobUrl = URL.createObjectURL(await response.blob());
                    blobUrlsDict[blobName] = blobClient.url;
                } catch (error) {
                    console.error('Error fetching blob:', error);
                }
            }

            setImagesUrls(prevUrls => ({ ...prevUrls, ...blobUrlsDict }));
            setLoading(false);
        }

        if (!useDB) {
            fetchBlobs(imagesIds);
        } else {
            setLoading(true);
            const queryString = imagesIds.map(id => `ids=${id}`).join('&');
            getImagesB64('api/image_blobs?' + queryString);
        }

    }, [imagesIds, containerClient]); 

    useEffect(() => {
        if (imagesB64?.data) {
            const base64Urls = imagesIds.map(id => 'data:image/png;base64,' + imagesB64.data[id]);
            const imagesUrls = Object.fromEntries(imagesIds.map((id, i) => [id, base64Urls[i]]));
            setImagesUrls(prevUrls => ({ ...prevUrls, ...imagesUrls }));
            setLoading(false);
        }
    }, [imagesB64?.data]);

    return { loading };
};

export default useImagesUrls;

import {useEffect, useState} from "react";

import ControlPanel from "../laboratory/ControlPanel";
import Tools from "../laboratory/Tools";

import ModalHttpError from '../utils/ModalHttpError';
import MobileModal from "../utils/ModalMobile";
import NavigationBar from "./NavigationBar";
import LaboratoryTemplate from "../../templates/laboratory/LaboratoryTemplate";

import { MosaikUtilsContext } from "../../../contexts/MosaikUtilsContext";
import { DraftContext } from "../../../contexts/DraftContext";
import { useContext } from "react";

const Laboratory = () => {

    const { currentDraft, setCurrentDraft } = useContext(DraftContext)
    const { buildResponse, handleRequestBuild } = useContext(MosaikUtilsContext);

    const [dataBundle, setDataBundle] = useState();

    const handleSubmitData = () => { 
        handleRequestBuild(dataBundle);
        setPanel__mobile('viewport')
    }

    // --- RECEIVING DATA FROM SERVER ---
    const _ = require('lodash');
    useEffect(() => {
        const newData = buildResponse.data?.frontend_data
        buildResponse.data && dataBundle && setCurrentDraft(
            {
                'draftId': buildResponse.data.build_id, 
                'editMode': newData ? newData.edit_mode : dataBundle.edit_mode,
                'image_blob_id': buildResponse.data.image_blob_id,
                'image_blob_url': currentDraft.image_blob_id === buildResponse.data.image_blob_id ? currentDraft.image_blob_url : null,
                'thumbnail_blob_id': buildResponse.data.thumbnail_blob_id,
                'thumbnail_blob_url': currentDraft.thumbnail_blob_id === buildResponse.data.thumbnail_blob_id ? currentDraft.thumbnail_blob_url : null,
                'data': newData ? newData : _.cloneDeep(dataBundle), 
                'name': 'Draft', 
                'inCart': false,
                'posterSize': 'B2',
            }
        );
    }, [buildResponse?.data]) 

    // --- ERROR HANDLING ---
    const [showHttpError, setShowHttpError] = useState(false);
    useEffect(() => {
        buildResponse.error && setShowHttpError(true);
    }, [buildResponse.error])

    // [MOBILE]
    const [panel__mobile, setPanel__mobile] = useState('control');

    const controlPanel = <ControlPanel
        currentDraft={currentDraft} 
        handleSubmitData={handleSubmitData} 
        setDataBundle={setDataBundle}
        setPanel__mobile={setPanel__mobile}
        responseLoading={buildResponse.isLoading}
    />

    const tools = <Tools 
        setPanel={setPanel__mobile}
        responseLoading={buildResponse.isLoading}
    />

    return (
        <>
            <NavigationBar/>
            <LaboratoryTemplate
                controlPanel={controlPanel}
                tools={tools}
                isLoading={buildResponse.isLoading}
                panel__mobile={panel__mobile}
                setPanel__mobile={setPanel__mobile}
            />
            <ModalHttpError show={showHttpError} setShow={setShowHttpError} error={buildResponse.error} onClose={() => {}}/>
            <MobileModal/>
        </>
    )
}

export default Laboratory;

import { useContext } from "react";
import { DeviceContext } from "../../../contexts/DeviceContext";

import CartTemplateDefault from "./CartTemplateDefault";
import CartTemplateMobile from "./CartTemplateMobile";

const CartTemplate = ({cartReview, savedDrafts, infoPanel, orderSummary}) => {

    const { mobile } = useContext(DeviceContext);

    return (
        <>
        {mobile ? 
            <CartTemplateMobile
                cartReview={cartReview}
                savedDrafts={savedDrafts}
                infoPanel={infoPanel}
                orderSummary={orderSummary}
            />:
            <CartTemplateDefault
                cartReview={cartReview}
                savedDrafts={savedDrafts}
                infoPanel={infoPanel}
                orderSummary={orderSummary}
            />
        }     
        </>
    );
};

export default CartTemplate;
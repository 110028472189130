import React from 'react';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, InputGroup, DropdownToggle, Form, Nav, Tab, Row, Col} from 'react-bootstrap';


const ImageLibrarySelect = ({
    label, handleSelect, imageLibrary, librarySections, currentImage, currentOpt, setCurrentOpt
}) => {

    useEffect(() => {
        imageLibrary && setCurrentOpt(Object.entries(imageLibrary).flatMap(
            ([key, value]) => value.find(item => item.id === currentImage)).find(item => item !== undefined));
    }, [currentImage, imageLibrary])

    const defaultTab = 'POP'

    return (
        <Dropdown>
            <Row>
                <InputGroup>
                    <DropdownToggle size="sm" variant="light" 
                    style={{width: "50%", borderRadius:"5px 0 0 5px"}}>
                        {label}
                    </DropdownToggle>
                    <Form.Control
                        type="text" name="image_file_name"
                        placeholder='Select Image in the Library'
                        value={currentOpt?.name}
                        readOnly
                        size="sm"
                    />
                </InputGroup>
            </Row>
            <Dropdown.Menu style={{width: "100%"}}>
                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultTab}>
                    <Row>
                        <Col style={{borderRight: "1px solid #AAA"}}>
                            <Nav className="flex-column px-2 align-items-center" variant="underline">
                            {
                                imageLibrary && Object.keys(imageLibrary).map((section, idx) => (
                                    <Nav.Item key={idx} style={{}}>
                                    <Nav.Link eventKey={section} style={{color:"#555", textAlign: 'center'}}>
                                        {librarySections?.[section] || section}
                                    </Nav.Link>
                                    </Nav.Item>
                                ))
                            }
                            </Nav>
                        </Col>
                        <Col>
                            <Tab.Content className='px-2'>
                            {
                                imageLibrary && Object.keys(imageLibrary).map((section, idx) => (
                                    <Tab.Pane key={idx} eventKey={section}>
                                        <div
                                            style={{
                                                display: "flex", width: "100%", margin: "0",
                                                flexFlow: "column wrap", gap: "0.2em"
                                            }}
                                        > 
                                            {
                                                imageLibrary && imageLibrary[section].map( (img) => (
                                                    <DropdownItem
                                                        key={img.id}
                                                        className="color-select__dropdown-item"
                                                        onClick={() => {handleSelect(img.id);}}
                                                        style={currentImage === img.id ? 
                                                            {border: "2px solid #AAA", borderRadius: "5px"} : 
                                                            {}
                                                        }
                                                    >
                                                        {img.name}
                                                    </DropdownItem>   
                                                ))
                                            }
                                        </div>
                                    </Tab.Pane>
                                ))
                            }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ImageLibrarySelect;
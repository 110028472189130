import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import NavigationBar from '../pages/NavigationBar';

import {ThemeContext} from '../../../contexts/ThemeContext';
import {LanguageContext} from '../../../contexts/LanguageContext';

import Button from 'react-bootstrap/Button';

const LoggedOut = () => {

    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <>
            <NavigationBar/>
            <div 
            className="d-flex flex-column justify-content-center align-items-center"
            style={{height: "92vh", maxHeight: "92vh", overflow: "hidden"}}
            >
                <div 
                    className='p-2 d-flex flex-column align-items-center justify-content-center' 
                    style={{
                        borderRadius: "10px", boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
                        backgroundColor: "var(--bg-color-1)", color: "var(--color-2)",
                        maxWidth: "50%", maxHeight: "95%",
                    }} 
                >
                    <h1 className='text-center' style={{fontSize: "100px"}}>😶‍🌫️</h1>
                    <h2 className='text-center'>Your session is over</h2>
                    <Button 
                        className="w-100" 
                        variant={theme} 
                        onClick={() => navigate('/')}
                    >
                        {{
                            'EN': 'Back to Home',
                            'FR': 'Retour à la Page d\'Accueil'
                        }[language]}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default LoggedOut;
import React from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const PosterSizeToggleChoice = ({posterSize, setPosterSize}) => {
    return (
        <ButtonGroup style={{width: '100%'}}>
            <ToggleButton key={"A2"}
                type="radio" size="sm" variant={'outline-light'} 
                value={'A2'} checked={posterSize === 'A2'}
                onClick={() => setPosterSize("A2")}
            >A2</ToggleButton>
            <ToggleButton key={"A1"}
                type="radio" size="sm" variant={'outline-light'} 
                value={'A1'} checked={posterSize === 'A1'}
                onClick={() => setPosterSize("A1")}
            >A1</ToggleButton>
        </ButtonGroup>
    );
};

export default PosterSizeToggleChoice;
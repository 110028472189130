import React from 'react';
import { Container } from 'react-bootstrap';

const CartTemplateDefault = ({cartReview, savedDrafts, infoPanel, orderSummary}) => {
    return (
        <Container className='summary-page'>
            <div className="summary-container">
                <div className="summary-container__left-panel px-3">
                    <div className='summary-container__left-panel__top'>
                        {cartReview}
                    </div>
                    <div className='summary-container__left-panel__bottom'>
                        {savedDrafts}
                    </div>
                </div>
                <div  className= "summary-container__right-panel px-3">
                    <div className='summary-container__right-panel__top'>              
                        {infoPanel}
                    </div>
                    <div className='summary-container__right-panel__bottom'>
                        {orderSummary}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CartTemplateDefault;
import { useState, createContext } from 'react';

import useFetchData from '../hooks/useFetchData';

export const CheckoutContext = createContext();

/* TODO: Simplify Checkout Logic */

export const CheckoutProvider = (props) => {

    const [checkoutData, setCheckoutData] = useState();

    // PROMO LOGIC
    const [promotion, getPromotion] = useFetchData();
    const handleRequestPromo = (promoTextInput) => {
        getPromotion(`api/order_promocode/${promoTextInput}`);
    };

    return (
      <CheckoutContext.Provider value={{
        checkoutData, setCheckoutData, 
        promotion, getPromotion, handleRequestPromo
      }}>
        {props.children}
      </CheckoutContext.Provider>
    );
  };
import { useEffect, useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import { useContext } from "react";
import { LanguageContext } from "../../../../contexts/LanguageContext";

const PlacesAutocomplete = ({ setSelected }) => {

    const { language } = useContext(LanguageContext);  
    const inputRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false); // To track when Google Maps script is available

    // Function to initialize autocomplete
    const initializeAutocomplete = () => {

        if (inputRef.current && window.google && window.google.maps && window.google.maps.places) {

            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {types: ['address']});
            autocomplete.addListener("place_changed", () => {
                
                const place = autocomplete.getPlace();
                
                if (place.geometry) {

                    const { lat, lng } = place.geometry.location;

                    const street_number = place.address_components.find((c) => c.types.includes("street_number"));
                    const country = place.address_components.find((c) => c.types.includes("country"));
                    const postal_code = place.address_components.find((c) => c.types.includes("postal_code"));
                    const locality = place.address_components.find((c) => c.types.includes("locality"));
                    const route = place.address_components.find((c) => c.types.includes("route"));

                    setSelected({
                        lat: lat(),
                        lng: lng(),
                        route: route?.long_name || "",
                        city: locality?.long_name || "",
                        postal_code: postal_code?.long_name || "",
                        country: country.long_name,
                        country_code: country.short_name,
                    });
                }
            });
        }
    };

    useEffect(() => { isLoaded && initializeAutocomplete()}, [isLoaded]);

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            setIsLoaded(true);
        } else {
            const intervalId = setInterval(() => {
                if (window.google && window.google.maps) {
                    setIsLoaded(true);
                    clearInterval(intervalId); 
                }
            }, 100);
        }
    }, []);

   
    return (
        <div style={{ width: '100%', display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: "1em" }}>
            <Form.Label className='section-formlabels'>
                {{ 'EN': 'Service points nearby:', 'FR': 'Point relais à proximité de:' }[language]}
            </Form.Label>
            <input
                ref={inputRef}
                type="text"
                className={"px-2"}
                style={{ height: "2.5em", borderRadius: "5px", border: "1px solid #DDD", fontSize: "1em" }}
                placeholder={{ 'EN': 'Enter an address', 'FR': 'Entrez une adresse' }[language]}
            />
        </div>
    );
};

export default PlacesAutocomplete;
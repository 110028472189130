import {useEffect, useState, useContext, useRef} from 'react';
import { PaymentElement} from "@stripe/react-stripe-js";

import { useStripe, useElements } from "@stripe/react-stripe-js";

import usePostData from '../../../../hooks/usePostData';

import { Button, Modal } from "react-bootstrap";
import { CreditCardFill, Stripe as StripeIcon} from "react-bootstrap-icons";
import TermsAndConditions from './TermsAndConditions';
import AcceptTermsAndConditions from './AcceptTermsAndConditions';

import PricingSummary from '../../utils/PricingSummary';

import { CheckoutContext } from '../../../../contexts/CheckoutContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { DeviceContext } from '../../../../contexts/DeviceContext';
import { DraftContext } from '../../../../contexts/DraftContext';

const PaymentForm = ({orderData, formikRef}) => {

    const {language} = useContext(LanguageContext);
    const {mobile} = useContext(DeviceContext);
    const {draftRecord} = useContext(DraftContext);

    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState('')
    const [errorModalShow, setErrorModalShow] = useState(false);

    const [paymentProcessReady, setPaymentProcessReady] = useState(false);
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsWarning, setTermsWarning] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => {setModalShow(true);}
    useEffect(() => {termsAccepted && setTermsWarning(null);}, [termsAccepted]);

    // Parcel Creation Uppon Payment Confirmation
    const [orderSuccess, placeOrder] = usePostData();

    // Listens to checkout data
    const {checkoutData, setCheckoutData} = useContext(CheckoutContext);

    const handleOrderPlacement = async () => {

        try {
            const order_items = checkoutData?.order.items.map((item, idx) => {
                const draft = draftRecord.find(draft => draft.draftId === item.id);
                return {
                    ...item,
                    image_blob_id: draft?.image_blob_id,
                    image_blob_url: draft?.image_blob_url,
                };
            });

            const checkoutSummary = {...checkoutData, order: {...checkoutData.order, items: order_items}}
            setCheckoutData(checkoutSummary);
            await placeOrder('api/order', checkoutSummary);

        } catch (error) {
            setErrorMessage(error);
            setErrorModalShow(true);   
        } 
    }

    useEffect(() => {

        if (checkoutData?.ready && paymentProcessReady) {

            try {

                // Place the order with 'pending' status
                handleOrderPlacement();

                const paymentConfirmation = async (transaction_id) => {
                    const { error } = await stripe.confirmPayment({
                        elements,
                        confirmParams: {
                            return_url: `${window.location.origin}/completion`,
                            receipt_email: checkoutData.contact.email,
                            shipping: {
                                name: checkoutData.contact.name,
                                address: {
                                    line1: checkoutData.shipping.address,
                                    city: checkoutData.shipping.city,
                                    postal_code: checkoutData.shipping.zip,
                                    country: checkoutData.shipping.country,
                                }
                            },
                        },
                    });

                    // Handle payment confirmation result
                    if(error) {
                        setErrorMessage(error.message);
                        setErrorModalShow(true);
                        setPaymentProcessing(false);
                        setPaymentProcessReady(false);
                    } 
                }

                paymentConfirmation(checkoutData.transaction_id);

            } catch (error) {
                setErrorMessage(error.message);
                setErrorModalShow(true);
            } finally {
                setPaymentProcessing(false);
                setPaymentProcessReady(false);
            }
        }
         
    }, [checkoutData?.ready, paymentProcessReady]);


    const handleSubmit = async (event) => {
        /*
        Submit triggers form validation. 
        Form Validation modifies the checkout context. 
        The checkout context triggers the useEffect above once ready to confirm payment. 
        */

        event.preventDefault();

        const handleShippingFormSubmit = async () => {
            await formikRef.current.submitForm();
            const isValid = formikRef.current.isValid;
            return isValid;
        }

        // SHIPPING FORM VALIDATION
        const shippingFormIsValid = await handleShippingFormSubmit();

        if (!stripe || !elements || !shippingFormIsValid) { return; }
        if (!termsAccepted) 
            {setTermsWarning('Accept terms and conditions.'); return;}

        setPaymentProcessReady(true);
        setPaymentProcessing(true);
    }

    // SCROLL 

    const contentRef = useRef(null);
    const [fadeOpacity, setFadeOpacity] = useState(1);


    useEffect(() => {
        const handleScroll = () => {
            const contentElement = contentRef.current;
            if (contentElement) {
                const contentHeight = contentElement.scrollHeight;
                const containerHeight = contentElement.clientHeight;
                const scrollTop = contentElement.scrollTop;

                const fadeOpacity = Math.min(Math.max((1 - (scrollTop + 10) / (contentHeight - containerHeight)), 0), 1);
                setFadeOpacity(fadeOpacity);
            }
        };

        const contentElement = contentRef.current;
        if (contentElement) {
            contentElement.addEventListener('scroll', handleScroll);
            handleScroll(); 
        }

        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <form id="payment-form" onSubmit={handleSubmit} style={{height: "100%"}}>
            <div className='d-flex flex-column h-100 w-100'>
                <div className='section-title'>
                    <StripeIcon className='me-2' style={{fontSize: "1em"}}/>
                    {{'EN': 'Payment', 'FR': 'Paiement'}[language]}
                </div>
                <div 
                    className="section section-with-button px-3 pt-3"
                    style={mobile ? {} : {maxHeight: "calc(100% - 4em)", overflowY: "auto"}}
                    ref={contentRef}
                >
                    <PaymentElement />
                    <div className='mt-0'>
                        <PricingSummary orderData={orderData} theme={'light'} lang={language}/>
                    </div>
                    <div className='section-bottom mb-3'>
                        <AcceptTermsAndConditions 
                            termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted}
                            termsWarning={termsWarning} handleShow={handleShow}
                        />
                    </div>
                    <TermsAndConditions modalShow={modalShow} setModalShow={setModalShow}/>
                </div>
                <div style={{position: "relative"}}>
                    <div className='scroll-fade-bottom' style={{height: fadeOpacity * 80}}>{' '}</div>
                </div>
                <Button
                    variant = 'success'
                    className='section-button'
                    onClick={handleSubmit}
                    disabled={paymentProcessing || formikRef.current.isSubmitting}
                >
                    {paymentProcessing ? 
                        {'EN': 'Processing...', 'FR': 'En cours...'}[language]: 
                        {'EN': 'Pay Now', 'FR': 'Paiement'}[language]
                    }
                </Button>
            </div> 
            <Modal show={errorModalShow} onHide={() => setErrorModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <CreditCardFill className='me-3' style={{fontSize: "1em"}}/>
                        {{'EN': 'Payment Error', 'FR': 'Erreur de Paiement'}[language]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setErrorModalShow(false)}>
                        {{'EN': 'Close', 'FR': 'Fermer'}[language]}
                    </Button>
                </Modal.Footer>
            </Modal>

        </form>
    );
};

export default PaymentForm;
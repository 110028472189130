import { useState, useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import useFetchData from '../../../hooks/useFetchData';
import PaymentForm from '../checkout/payment/PaymentForm';
import StripeUnavailable from '../checkout/payment/StripeUnavailable';
import { CheckoutContext } from '../../../contexts/CheckoutContext';

const Payment = ({ formikRef }) => {
    
    const { checkoutData, setCheckoutData } = useContext(CheckoutContext);

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [paymentIntent, getPaymentIntent] = useFetchData();

    // ONLY LOAD ONCE 
    useEffect(() => {
        if (checkoutData?.order) {
            const queryString = new URLSearchParams({
                promocode: checkoutData.order.promocode || '',
                posters: checkoutData.order.items.map(d => d.size).join('|'),
            }).toString();

            getPaymentIntent(`api/stripe/payment_intent/?${queryString}`);
            try {
                setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
            } 
            catch {
                
            }
        }
    }, []);

    // LOAD CLIENT SECRET
    useEffect(() => {  
        if (paymentIntent.data) {
            setClientSecret(paymentIntent.data.clientSecret);
            setCheckoutData(prev => ({ ...prev, transaction_id: paymentIntent.data.id}));
        }
    }, [paymentIntent.data]);


    return (
        <>
            {stripePromise && clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <PaymentForm
                        formikRef={formikRef}
                        orderData={checkoutData?.order}
                    />
                </Elements>
            ) : (
                <StripeUnavailable />
            )}
        </>
    );
};

export default Payment;
import { useContext } from 'react';

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav'
import { useTransition, animated } from 'react-spring'

import { GearFill, RocketTakeoffFill, QuestionSquareFill} from 'react-bootstrap-icons';

import { LanguageContext } from '../../../contexts/LanguageContext';
import tr from '../../../assets/translations/general.json';

const ControlTemplateDefault = (props) => {

    const {language} = useContext(LanguageContext);

    const transitionPortrait = useTransition(props.editMode === 'portrait', {   
        from: {opacity: 0.8, transform: 'translate3d(0%, 100%, 0)' },  
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: {opacity: 0.8, transform: 'translate3d(0, 0%, 0)' }, 
    })

    const transitionAbstract = useTransition(props.editMode === 'abstract', {   
        from: {opacity: 0.8, transform: 'translate3d(0%, 100%, 0)' },  
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: {opacity: 0.8, transform: 'translate3d(0, 0%, 0)' }, 
    })

    const disabledStyle = {pointerEvents: "none", opacity: "0.3"}

    return (
        <>
            <div className="settings-header"> 
                <div className="d-flex w-100 mb-3 justify-content-between">
                    {props.helpButton}
                    {props.randomGenSwitch}
                </div>
                <div className="d-flex w-100" style={{textTransform: 'uppercase'}}> 
                    {props.editModeSelect}
                </div>
            </div>
            {props.editMode === 'portrait' && 
                <>
                    <div style= {props.randomGenActive ? disabledStyle : {}}>
                        <Nav style={{position: "absolute", zIndex: "1"}}>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#model">{tr.sections.model[language]}</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#background">{tr.sections.background[language]}</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#canvas">{tr.sections.canvas[language]}</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#legend">{tr.sections.legend[language]}</Nav.Link></Nav.Item>
                        </Nav>
                    </div>
                    <div 
                        className="left-panel__control-settings"
                        style= {props.randomGenActive ? disabledStyle : {}}
                    >
                        {transitionPortrait((style, item) => item ? 
                        <animated.div style={style} className="control-settings__content"> 
                            {props.modePortrait}
                        </animated.div> : "")}; 
                    </div>
                </>
            }
            {props.editMode === 'abstract' && 
                <>
                    <div style= {props.randomGenActive ? disabledStyle : {}}>
                        <Nav style={{position: "absolute", zIndex: "1"}}>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#blocks">{tr.sections.blocks[language]}</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#layout">{tr.sections.layout[language]}</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#canvas">{tr.sections.canvas[language]}</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="control-settings__navlink" href="#legend">{tr.sections.legend[language]}</Nav.Link></Nav.Item>
                        </Nav>
                    </div>
                    <div 
                        className="left-panel__control-settings"
                        style= {props.randomGenActive ? disabledStyle : {}}
                    >
                        {transitionAbstract((style, item) => item ? 
                        <animated.div style={style} className="control-settings__content"> 
                            {props.modeAbstract}
                        </animated.div> : "")}; 
                    </div>
                </>
            }
            {props.randomGen}
            <div>
                <div className='p-2 footer__button'> 
                    <Button 
                        className={"px-2 py-3 w-100 h-100 d-flex justify-content-center align-items-center shadowed-text " + 
                                    `control-run__button--${props.runButtonClass}` }
                        style={{border: "3px solid white", borderRadius: "100px", fontWeight: "bold"}}
                        onClick={props.runButtonClick} 
                        disabled={props.runButtonDisabled} 
                    >
                        {{'EN': 'Generate', 'FR': 'Génerer'}[language]} <RocketTakeoffFill className='ms-2 shadowed-text'/>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ControlTemplateDefault;


import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CloudUploadFill } from "react-bootstrap-icons";

const UploadGuidelines = ({show, setShow, lang}) => {

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <CloudUploadFill className="me-2"/>
                    {{
                        'EN': 'Upload Guidelines',
                        'FR': 'Consignes de téléchargement'
                    }[lang]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    {
                        'EN': (
                            <>
                                <p>Please make sure to follow these guidelines when uploading files:</p>
                                <ul>
                                    <li>File size should not exceed 5MB</li>
                                    <li>File format should be either .jpg or .png</li>
                                </ul>
                                <p>The algorithm automatically detects the background, if you are not satisfied with the result 
                                    you can remove the background using another tool (like <a href="https://www.remove.bg/">remove.bg</a>). 
                                    The transparent areas of any png image will be used as background.  </p>
                            </>
                        ),
                        'FR': (
                            <>
                                <p>Veuillez vous assurer de suivre ces directives lors du téléchargement de fichiers:</p>
                                <ul>
                                    <li>La taille du fichier ne doit pas dépasser 5 Mo</li>
                                    <li>Le format du fichier doit être .jpg ou .png</li>
                                </ul>
                                <p>L'algorithme détecte automatiquement l'arrière-plan, si vous n'êtes pas satisfait du résultat, 
                                    vous pouvez supprimer l'arrière-plan en utilisant un autre outil (comme <a href="https://www.remove.bg/">remove.bg</a>). 
                                    Les zones transparentes de toute image png seront utilisées comme arrière-plan.</p>

                            </>
                        )  
                    }[lang]
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadGuidelines;

import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";

import Shipping from "./../checkout/Shipping";
import Payment from "./../checkout/Payment";

import CheckoutTemplate from "../../templates/checkout/CheckoutTemplate";

const Checkout = () => {

    const [shippingFormValid, setShippingFormValid] = useState(false);
    const formikRef = useRef(null);

    const navigate = useNavigate();
    const handleBackToCart = () => {
        navigate('/cart');
    }

  return (
    <>
      <CheckoutTemplate 
          shipping={<Shipping formikRef={formikRef} shippingFormValid={shippingFormValid} setShippingFormValid={setShippingFormValid}/>}
          payment={<Payment formikRef={formikRef} shippingFormValid={shippingFormValid}/>}
          handleBackToCart={handleBackToCart}
      />
    </>
  );
};

export default Checkout;

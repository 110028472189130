import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LabeledInput from '../../ui/LabeledInput';

import {useContext} from 'react';
import {LanguageContext} from '../../../../contexts/LanguageContext';
import tr from '../../../../assets/translations/general.json';


const Legend = ({legendData, setLegendData}) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
            <Row className='mb-2'>
                <Col>
                    <LabeledInput
                        label={tr.legend.title[language]} placeholder='Title'
                        currentInput={legendData.title}
                        handleChange={(value) => setLegendData({...legendData, 'title': value})}
                    />
                </Col>
                <Col>
                    <LabeledInput
                        label={tr.legend.author[language]} placeholder='Your Name'
                        currentInput={legendData.author}
                        handleChange={(value) => setLegendData({...legendData, 'author': value})}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Legend;
import React from 'react';
import { Button } from 'react-bootstrap';

import { useContext } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ArrowReturnLeft } from 'react-bootstrap-icons';

const CheckoutTemplateDefault = ({
    shipping, 
    payment, 
    handleBackToCart,
}) => {

    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);

    return (
        <div className="checkout-page">
            <Button 
                className="button-backtocart"
                onClick={handleBackToCart}
                variant={theme}
            >   
                <ArrowReturnLeft className="me-2"/>
                {{'EN': 'Back to Cart', 'FR': 'Retour au Panier'}[language]}
            </Button>
            <div className="checkout-container">
                <div className="shipping-container">
                    {shipping}
                </div>
                <div className="payment-container">
                    {payment}
                </div>
            </div>
        </div>
    );
};

export default CheckoutTemplateDefault;
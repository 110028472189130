import { useContext } from "react";
import { LanguageContext } from "../../../../contexts/LanguageContext";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { DeviceContext } from "../../../../contexts/DeviceContext";

import { Card } from "react-bootstrap";

const ServerUnavailable = () => {

    const { language } = useContext(LanguageContext);
    const { theme } = useContext(ThemeContext);
    const { mobile } = useContext(DeviceContext);

    return (
        <div className="m-3" style={mobile ? {height: "92vh", maxHeight: "92vh", overflow: "hidden"} : {}}>
            <Card bg={theme} style={{color: 'var(--color-1)'}}>
                <Card.Header>
                    {{
                        'EN': 'Server Not Responding',
                        'FR': 'Le serveur ne répond pas'
                    }[language]}
                </Card.Header>
                <Card.Body>
                    {{
                        'EN': 'The service is temporarily unavailable. Please try again later!',
                        'FR': 'Le service est temporairement indisponible. Veuillez réessayer plus tard!'
                    }[language]}
                </Card.Body>
            </Card>
        </div>
    );
};

export default ServerUnavailable;
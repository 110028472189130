import { useState, useEffect } from 'react';

const useFadeOnScroll = (contentRef) => {
    const [fadeOpacityB, setFadeOpacityB] = useState(1);
    const [fadeOpacityT, setFadeOpacityT] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            const contentElement = contentRef.current;
            if (contentElement) {
                const contentHeight = contentElement.scrollHeight;
                const containerHeight = contentElement.clientHeight;
                const scrollTop = contentElement.scrollTop;

                const fadeOpacityB = Math.min(Math.max(1 - scrollTop / (contentHeight - containerHeight + 0.1), 0), 1);
                const fadeOpacityT = Math.min(Math.max(scrollTop / (contentHeight - containerHeight + 0.1), 0), 1);
                setFadeOpacityB(fadeOpacityB);
                setFadeOpacityT(fadeOpacityT);
            }
        };

        const contentElement = contentRef.current;
        if (contentElement) {
            contentElement.addEventListener('scroll', handleScroll);
            handleScroll(); // Initial check on mount
        }

        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [contentRef]);

    return { fadeOpacityT, fadeOpacityB };
};

export default useFadeOnScroll;

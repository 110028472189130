import { useState, useEffect } from 'react';

const useAzureBlobList = (containerClient) => {
  const [blobList, setBlobList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBlobs = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const blobs = {};
      for await (const blob of containerClient.listBlobsFlat()) {
        const blobUrl = containerClient.getBlockBlobClient(blob.name).url;
        blobs[blob.name] = blobUrl;
      }

      setBlobList(blobs);
    } catch (err) {
      console.error('Error fetching blobs:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    containerClient && fetchBlobs();
  }, [containerClient]);

  return { blobList, isLoading, error };
};

export default useAzureBlobList;
import Form from 'react-bootstrap/Form';

import Dice from '../../../../assets/svg/Dice.svg'
import DiceDark from '../../../../assets/svg/DiceDark.svg'

import { useContext } from 'react';
import { ThemeContext } from '../../../../contexts/ThemeContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';

import { GearFill } from 'react-bootstrap-icons';

const RandomGenSwitch = ({randomGenActive, setRandomGenActive, handleSwitch}) => {    

    const { theme } = useContext(ThemeContext);
    const { language } = useContext(LanguageContext);
    const dice = theme === 'light' ? DiceDark : Dice;

    return (
        <div style={{display: "flex", alignItems: "center", alignSelf: "flex-start", marginRight: "0.5em"}}>
            
            <GearFill 
                className='button-icon'
                style={{fontSize: "1.2em", color: "var(--color-1)"}}
                onClick={() => setRandomGenActive(false)}
            />
            <Form.Check type="switch"
                id="custom-switch"
                style={{color: "var(--color-2)", fontSize: "1rem"}}
                checked={randomGenActive}
                onChange={handleSwitch}
                reverse
            />
            <img 
                className="ms-2 button-image" src={dice} alt="Switch" 
                style={{width: "1.2em", height: "1.2em"}}
                onClick={() => setRandomGenActive(true)}
            />
            
        </div>
    )
}

export default RandomGenSwitch;
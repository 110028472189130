import {useContext} from 'react';

import { Stripe,  BugFill } from 'react-bootstrap-icons';

import { Card } from 'react-bootstrap';

import { LanguageContext } from '../../../../contexts/LanguageContext';
import { ThemeContext } from '../../../../contexts/ThemeContext';
import RefreshButton from '../../utils/RefreshButton';

const StripeUnavailable = () => {

    const { language } = useContext(LanguageContext);
    const { theme } = useContext(ThemeContext);

    return (
        <>
            <div className='section-title'>
                <Stripe className='me-2' style={{fontSize: "1em"}}/>
                {{'EN': 'Payment', 'FR': 'Paiement'}[language]}
            </div>
            <Card   
                className='h-100 w-100' bg={theme} 
                style={{color: 'var(--color-1)', borderRadius: '10px', minHeight: '20em'}}
            >
                <Card.Header>
                    <BugFill className='me-2'/>
                    {{'EN': 'Stripe Not Responding', 'FR': 'Stripe ne répond pas'}[language]}
                </Card.Header>
                <Card.Body style={{position: "relative"}}>
                    {{
                        'EN': 'Stripe is temporarily unavailable. Please try again later!',
                        'FR': 'Stripe est temporairement indisponible. Veuillez réessayer plus tard!'
                    }[language]}
                    <div className='w-100 mb-3 px-3' style={{position: "absolute", bottom: "0", right: "0"}}>
                        <RefreshButton theme={theme} language={language}/>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default StripeUnavailable;
import LabeledSelect from "../../../ui/LabeledSelect";
import SeedSelect from "./SeedSelect";

import { useEffect, useContext } from 'react';

import {LanguageContext} from '../../../../../contexts/LanguageContext';
import tr from '../../../../../assets/translations/general.json';

const Voronoi = (props) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
            <SeedSelect
                currentInput={props.seed}
                handleChange={(value) => {props.handleUpdateParams('seed', value)}}
            />
            <LabeledSelect
                label = {tr.layout.granularity[language] || "Granularity"}
                options = {props.options && props.options.granularity}
                currentOption = {props.granularity}
                handleSelect = {(value) => {props.handleUpdateParams('granularity', value)}}
            />
        </>
    )
}

export default Voronoi;
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import Badge from "react-bootstrap/Badge";
import { FileEarmarkArrowUp, CartCheck } from "react-bootstrap-icons";

const LoadDraftDropdown = ({dropDirection, draftRecord, handleDraftSelect, disabled, theme, lang}) => {

    return (
        <Dropdown drop={dropDirection}>
            <DropdownToggle
                variant="secondary"
                style={{width: "100%"}}
                disabled={disabled}
                className="px-3 button-with-icon"
            >
                <div>
                    <Badge className='me-2' 
                        bg={{"dark": "dark", "light": "dark"}[theme]}
                    >{draftRecord.length}</Badge>
                        {{'EN': 'Saved Posters', 'FR': 'Sauvergardes'}[lang]}
                    <FileEarmarkArrowUp className="ms-2 mb-1"/>
                </div>
            </DropdownToggle>
            <DropdownMenu>
            {
                draftRecord.length > 0 && draftRecord.map( (r) => (
                    <DropdownItem
                        key={r.draftId}
                        onClick={() => {
                            handleDraftSelect(r.draftId);
                        }}
                    >   
                        <div className="text-with-icon">
                            {r.name}
                            {r.inCart ? <CartCheck/> : <></>}
                        </div>
                    </DropdownItem>
                    )
                )
            }
            </DropdownMenu>
        </Dropdown>
    );
};

export default LoadDraftDropdown;
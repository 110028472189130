import { useState, useEffect, useContext } from 'react';

import { DraftContext } from '../../../../contexts/DraftContext';
import { ABSContext } from '../../../../contexts/ABSContext';

import useFetchData from '../../../../hooks/useFetchData';
import useImagesUrls from '../../../../hooks/useImagesUrls';

import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

const ViewportMockups = ({view}) => {

    const {currentDraft, setCurrentDraft} = useContext(DraftContext);
    const {imagesContainerClient: containerClient} = useContext(ABSContext);

    // Make Mockups Request
    const [mockupsUrls, setMockupsUrls] = useState({});
    const [mockupsBlobIds, makeMockups] = useFetchData();

    // EDIT CURRENT DRAFT WHEN MOCKUPS URLS ARE SET
    useEffect(() => {
        if (Object.keys(mockupsUrls).length > 0) {
            setCurrentDraft({...currentDraft, mockups_urls: mockupsUrls});
            setMockupsUrls({});
        }
    }, [mockupsUrls]);;

    // GET MOCKUP BLOB IDS WHEN MAKING MOCKUPS
    useEffect(() => {
        // If mockups not set in the current draft -> make mockups
        if ( currentDraft?.image_blob_id && (!currentDraft?.mockups_urls || Object.keys(currentDraft.mockups_urls).length === 0)){ 
            makeMockups('api/mockups?id=' + currentDraft.image_blob_id); 
        }
    }, [view, currentDraft?.image_blob_id, currentDraft?.draftId]);

    // Retrieve Mockups
    const {loading: mockupsLoading} = useImagesUrls(mockupsBlobIds, containerClient, setMockupsUrls);

    // Selected Mockup
    const [selectedMockup, setSelectedMockup] = useState();
    useEffect(() => {   
        mockupsBlobIds?.data && setSelectedMockup(mockupsBlobIds.data[0]); 
    }, [mockupsBlobIds?.data])

    useEffect(() => {   
        currentDraft?.mockups_urls && setSelectedMockup(Object.keys(currentDraft?.mockups_urls)[0]);
    }, [currentDraft?.mockups_urls])

    return (
        (mockupsLoading || mockupsBlobIds.isLoading) ? 
        <Spinner style={{position: "absolute", zIndex: "1"}} size="lg" variant="light"/> :
        <div className='w-100 h-100 d-flex flex-column'>
            <div  style={{height: "80%", width: "100%"}}>
                <div className='py-4' style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Image
                        src={currentDraft?.mockups_urls? currentDraft.mockups_urls[selectedMockup]:""}
                        className = "viewport__image rounded"
                        style = {{
                            maxWidth: "100%", maxHeight: "100%",
                            borderRadius: "0.5em", boxShadow: "0 16px 16px rgba(0,0,0,0.25)"
                        }}
                    />
                </div>
            </div>
            <div 
                className='d-flex flex-row gap-1 align-items-center'
                style={{height:"20%", maxWidth: "100%", overflowX: "auto"}}>
                <div className='d-flex align-items-center gap-2' style={{height: '92%'}}>
                {currentDraft?.mockups_urls && Object.entries(currentDraft?.mockups_urls).map(([blob_id, url]) => (
                    <Image
                        src={url} key={'mockup-' + blob_id}
                        style={selectedMockup === blob_id ? {border: "0.2em solid var(--green-light)", opacity: 1.0} : {}}
                        className="button-mockup-selection rounded"
                        onClick={() => setSelectedMockup(blob_id)}
                    />
                ))}
                </div>
            </div>
        </div>
    );
};

export default ViewportMockups;
import {useState, useEffect, useContext} from "react";
import { Collection, ExclamationTriangle, Upload } from "react-bootstrap-icons";

import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import { ThemeContext } from "../../../../contexts/ThemeContext";
import { LanguageContext } from "../../../../contexts/LanguageContext";
import { ABSContext } from "../../../../contexts/ABSContext";

import ImageLibrarySelect from "../../ui/ImageLibrarySelect";
import UploadGuidelines from "./UploadGuidelines";

import useFileUpload from "../../../../hooks/useFileUpload";

import tr from "../../../../assets/translations/general.json";
import ModalGeneric from "../../utils/ModalGeneric";

const ForegroundChoice = ({layerData, setLayerData, utils, setLegendData}) => {

    // Context
    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);

    const {uploadContainerClient} = useContext(ABSContext);
    const { handleFileUpload, layerUpload, layerUploadError } = useFileUpload(uploadContainerClient);

    const [showUploadError, setShowUploadError] = useState(false);

    // Upload or choose from library
    const [layerType, setLayerType] = useState(layerData?.type? layerData.type : 'library');
    const [layerWarning, setLayerWarning] = useState("");
    const [layerSource, setLayerSource] = useState(layerData.library_source);
    const [currentOpt, setCurrentOpt] = useState(); 

    // Send Data to Layer Data 
    useEffect(() => {
        setLayerData({
            'type': layerType, 
            'upload_data': layerType === 'upload' ? layerUpload : null,
            'library_source': layerType === 'upload' ? null : layerSource,
        });
        setLayerWarning(
            layerType === 'upload' ?
                ( !layerUpload ? "Please upload an image" : null ) :
                ( !layerSource ? "Please select an image" : null ) 
        )

    }, [layerType, layerSource, layerUpload, setLayerData]);

    useEffect(() => {
        layerType === 'upload' ? 
            setLegendData(prev => ({...prev, 'title': 'Portrait Mosaik'})) :
            setLegendData(prev => ({...prev, 'title': currentOpt?.name ? currentOpt.name : 'Portrait Mosaik'}));
    }, [layerType, currentOpt]);
        
    // Upload Guidelines 
    const [showGuidelines, setShowGuidelines] = useState(false);

    // UX
    const [inputFile, setInputFile] = useState(null);
    useEffect(() => {
        setInputFile(document.getElementById("file-upload"));
    }, [layerType]);

    return (
        <>
            <Row>
                <ButtonGroup className="mb-2" style={{ width: '100%' }}>
                    <ToggleButton
                        key={2} id={'2'} type="radio" 
                        variant={theme === 'dark' ? 'outline-light' : 'outline-secondary'} style={{fontSize: "0.8rem"}}
                        value={'library'} checked={layerType === 'library'}
                        onChange={(e) => setLayerType(e.currentTarget.value)}
                    > {tr.model.collection[language]} <Collection className="ms-2"/> </ToggleButton>
                    <ToggleButton
                        key={1} id={'1'} type="radio"
                        variant={theme === 'dark' ? 'outline-light' : 'outline-secondary'} 
                        style={{fontSize: "0.8rem"}}
                        value={'upload'} checked={layerType === 'upload'}
                        onChange={(e) => setLayerType(e.currentTarget.value)}
                    > {tr.model.upload[language]} <Upload className="ms-2 mb-1"/>
                    </ToggleButton>
                </ButtonGroup>
            </Row>
            {layerWarning && 
                <div className="w-100 mb-1 d-flex align-items-center justify-content-center" style={{color: 'var(--color-3)', textAlign: "center", fontSize:"small"}}>
                    <ExclamationTriangle className="me-2"/>{layerWarning}
                </div>}
            <Row>
                <div className="mb-3">
                
                    { layerType === 'library' &&
                        <ImageLibrarySelect
                            label={tr.model.choose_image[language]}
                            currentImage={layerSource}
                            handleSelect={(v) => {setLayerSource(v)}}
                            imageLibrary={utils?.image_library_dict}
                            librarySections={utils?.image_library_sections_dict}
                            currentOpt={currentOpt} setCurrentOpt={setCurrentOpt}
                        />
                    }
                    {
                        layerType === 'upload' &&
                        <InputGroup>
                            <Button
                                className="button-color-green" variant="light"
                                size="sm" onClick={() => {inputFile?.click();}}
                            >{tr.model.upload_image[language]}</Button>
                            <input
                                id="file-upload" className="d-none" type="file" accept='image/*'
                                onChange={(e) => {handleFileUpload(e)}}
                            />
                            <Form.Control
                                size="sm" name="file_name" readOnly
                                value={layerUpload ? layerUpload.file_name : 
                                    {'EN': 'No file selected', 'FR': 'Aucun fichier sélectionné'}[language]
                                }
                                style={{fontStyle:"italic", color:"#888"}}
                            />
                        </InputGroup>
                    }

                {layerType === 'upload' && 
                    <div 
                        style={{fontSize: "0.9rem"}} 
                        className="d-flex justify-content-end button-text"
                        onClick={() => {setShowGuidelines(true);}}
                    >
                        {tr.model.upload_guidelines[language]}
                    </div>
                }
                </div>
            </Row>
            <UploadGuidelines show={showGuidelines} setShow={setShowGuidelines} lang={language}/>
            <ModalGeneric 
                show={!!showUploadError} setShow={setShowUploadError}
                title={{'EN': 'Upload Issue', 'FR': 'Problème lors du téléchargement'}[language]}
                content={layerUploadError}
            />
        </>
    );
}

export default ForegroundChoice;
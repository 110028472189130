import Button from 'react-bootstrap/Button';
import { Pen } from 'react-bootstrap-icons';

const ButtonRenaming = ({outsideClickException, setRenaming, disabled, theme, lang}) => {

    return (
        <Button
            className="px-3 button-with-icon"
            variant={theme}
            onClick={() => setRenaming(true)}
            ref={outsideClickException}
            disabled={disabled}
        >{{'EN': 'Rename', 'FR': 'Renommer'}[lang]}<Pen/></Button>
    );
};

export default ButtonRenaming;
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import InfiniteSlider from '../landing/InfiniteSlider';
import { Image, Spinner, Button } from 'react-bootstrap';

import landing from '../../../assets/images/backgrounds/landing.jpg';

import { DeviceContext } from '../../../contexts/DeviceContext';
import { UtilsContext } from '../../../contexts/UtilsProvider';
import { AuthContext } from '../../../contexts/AuthContext';

const LandingPage = () => {

    // Navigate to Laboratory
    const navigate = useNavigate();
    const handleClick = () => {navigate('/laboratory');};

    // Device Context
    const { mobile } = useContext(DeviceContext);
    const { imageBankUrlsKeys } = useContext(UtilsContext);

    const [content, setContent] = useState();
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(() => {

        if (!imageBankUrlsKeys) return;

        const landingKeys = Object.keys(imageBankUrlsKeys).filter(key => key.startsWith('landing_'));
        const landingArray = landingKeys.map(key => imageBankUrlsKeys[key]);

        const content = landingArray.map((url, i) => {
            return (
                <Image key={i} src={url} className='py-2 h-100' 
                style={{ 
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    borderRadius: '12px',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
                alt={`poster-${i}`}
                />
            )
        });

        setContent(content);
        setTimeout(() => { setContentLoading(false) }, 500);

    }, [imageBankUrlsKeys])


    return (
        <div 
            style={mobile ? 
                {
                    height: "100vh", overflow: "hidden",
                    backgroundImage: `url(${landing})`, backgroundSize: "cover", backgroundPosition: "center"
                }:
                {
                    height: "100vh", overflow: "hidden",
                    backgroundImage: `url(${landing})`, backgroundSize: "cover", backgroundPosition: "center"
                }}
        >
            <div 
                className="d-flex flex-column justify-content-center align-items-center" 
                style={mobile ? 
                    {height: '30%', width: '100%', position: "absolute", bottom: "0px", zIndex: "3"}:
                    {height: '30%', width: '40%', position: "absolute", left: "0", bottom: "20px"}}
            >
                <h1 className="text-light mb-2"  style={{textAlign:"center", letterSpacing:"2px", }}> 
                    Poster Generator 
                </h1>
                <h5  className="text-light mb-3"> </h5>
                <Button 
                    className="px-5" onClick={handleClick}
                    variant="light" style={{fontWeight: "bold"}}
                    size="lg"
                >Get Started</Button>
            </div>
            {contentLoading ? 
            <div style={{position: "absolute", top: "50%", left: "50%"}}>
                <Spinner size='lg' variant="light" />
            </div> : <>
            <div 
                className={"my-5 d-flex justify-content-start slider" + (mobile ? " slider-mobile" : "")}
                style={{height: "30vh"}}>
                <InfiniteSlider direction="right" position="top" content={content}/>
            </div>
            <div className={"my-5 d-flex justify-content-end slider" + (mobile ? " slider-mobile" : "")} 
                style={{height: "30vh"}}>
                <InfiniteSlider direction="left" position="bottom" content={content}/>
            </div>
            </>}   
                
        </div>
    );
}

export default LandingPage;


import { ArrowClockwise } from "react-bootstrap-icons";

import Button from 'react-bootstrap/Button';

const RefreshButton = ({theme, language}) => {

    const handleRefresh = () => {
      window.location.reload(); // This refreshes the current page
    };
  
    return (
      <Button 
        variant={{'dark': "light", "light": "secondary"}[theme]}
        className="w-100" onClick={handleRefresh}>
            <ArrowClockwise className="me-2"/>
            {{'EN': 'Refresh', 'FR': 'Rafraichir'}[language]}
      </Button>
    );

  };
  
  export default RefreshButton;
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { ArrowClockwise, Check, Pencil } from "react-bootstrap-icons";
import { useState } from "react";


const SeedSelect = ({currentInput, handleChange}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [seedInput, setSeedInput] = useState('');

    const handleValidate = () => {
        handleChange(parseInt(seedInput) || 0);
        setIsEditing(false);
        setSeedInput('');
    }

    const handleEdit = () => {
        setIsEditing(true);
    }

    const handleSeedInput = (value) => {
        setSeedInput(value.replace(/\D/g, '').slice(0, 4));
    }

    return (
        <>

         <Form.Label className="control-settings__form-label">Seed</Form.Label>
         <InputGroup>
            <Form.Control
                value={isEditing ? seedInput : currentInput}
                size="sm" variant="outline-light" 
                placeholder={'Enter a seed here'}
                aria-label="Default select example"
                readOnly={!isEditing}
                onChange={(e) => {handleSeedInput(e.target.value)}}
                name="seed"
            />
            <Button
                size="sm"
                variant="outline-light"
                onClick={isEditing ? handleValidate : handleEdit}
            >
                {isEditing ? <Check/> : <Pencil/>}
            </Button>
            <Button
                size="sm"
                variant="outline-light"
                onClick={() => {handleChange(Math.floor(Math.random() * 10));}}
            >
                <ArrowClockwise/>
            </Button>
        </InputGroup>
        </>
    );
}

export default SeedSelect;
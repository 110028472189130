import { createContext } from 'react';

import useAzureBlobClient from '../hooks/useAzureBlobClient';

export const ABSContext = createContext();

export const ABSContextProvider = (props) => {

    const { 
      containerClient: staticContainerClient,
      error: staticCCError
    } = useAzureBlobClient(process.env.REACT_APP_ABS_STATIC_CONTAINER);

    const { 
      containerClient: imagesContainerClient, 
      error: imagesCCError
    } = useAzureBlobClient(process.env.REACT_APP_ABS_IMAGES_CONTAINER);

    const { 
      containerClient: printsContainerClient, 
      error: printCCError
    } = useAzureBlobClient(process.env.REACT_APP_ABS_PRINTS_CONTAINER);

    const { 
      containerClient: uploadContainerClient, 
      error: uploadCCError
    } = useAzureBlobClient(process.env.REACT_APP_ABS_UPLOAD_CONTAINER);

    return (
      <ABSContext.Provider value={{
        staticContainerClient, 
        imagesContainerClient, 
        printsContainerClient,
        uploadContainerClient,
      }}>
        {props.children}
      </ABSContext.Provider>
    );
  };
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LabeledSelect from '../../ui/LabeledSelect';
import ColorSelect from '../../ui/ColorSelect';

import Perlin from './Maps/Perlin';
import Voronoi from './Maps/Voronoi';
import Packing from './Maps/Packing';
import Grid from './Maps/Grid';
import Threads from './Maps/Threads';

import { useEffect, useState, useContext } from 'react';
import {useTransition, animated} from 'react-spring'
import MapThumbnail from './Maps/MapThumbnail';

import {LanguageContext} from '../../../../contexts/LanguageContext';
import tr from '../../../../assets/translations/general.json';

const Maps = ({
    options,
    defaultOption,
    palettesColors,
    colormapData, 
    setColormapData,
    nbColors, legendLayout
}) => {

    // ---- LANGUAGE ----
    const {language} = useContext(LanguageContext);

    // ---- MAP TYPE ----
    const handleSwitchType = (value) => {
        setColormapData(prevData => (
            {
                'type': value, 
                'params': {
                    'Voronoi': voronoiParams,
                    'Perlin': perlinParams, 
                    'Grid': gridParams, 
                    'Packing': packingParams,
                    'Threads': threadsParams,
                }[value],
                'outlineThickness': prevData.outlineThickness, /*{
                    'Voronoi': defaultOption.voronoi.outline.thickness,
                    'Perlin': defaultOption.perlin.outline.thickness,
                    'Grid': defaultOption.grid.outline.thickness,
                    'Packing': defaultOption.packing.outline.thickness,
                    'Threads': defaultOption.threads.outline.thickness,
                }[value],*/
                'outlineColor': prevData.outlineColor,/*{
                    'Voronoi': defaultOption.voronoi.outline.color,
                    'Perlin': defaultOption.perlin.outline.color,
                    'Grid': defaultOption.grid.outline.color,
                    'Packing': defaultOption.packing.outline.color,
                    'Threads': defaultOption.threads.outline.color,
                }[value],*/
            }
        ));
    }

    const handleSelectOutline = (value) => {
        setColormapData(prevData => ({...prevData, 'outlineThickness': value}));
    }

    const handleSelectOutlineColor = (value) => {
        setColormapData(prevData => ({...prevData, 'outlineColor': value}));
    }

    // ---- MAP PARAMS -----
    const [voronoiParams, setVoronoiParams] = useState(null);
    const [perlinParams, setPerlinParams] = useState(null);
    const [packingParams, setPackingParams] = useState(null);
    const [gridParams, setGridParams] = useState(null);
    const [threadsParams, setThreadsParams] = useState(null);

    // For default initialization 
    useEffect(() => {
        defaultOption && !Boolean(voronoiParams) && setVoronoiParams(defaultOption.voronoi);
        defaultOption && !Boolean(perlinParams) && setPerlinParams(defaultOption.perlin);
        defaultOption && !Boolean(packingParams) && setPackingParams(defaultOption.packing);
        defaultOption && !Boolean(gridParams) && setGridParams(defaultOption.grid);
        defaultOption && !Boolean(threadsParams) && setThreadsParams(defaultOption.threads);
    }, [defaultOption]);

    // For loading intialization
    useEffect(() => {
        switch (colormapData.type) {
            case 'Perlin':
                setPerlinParams(colormapData.params);
                break;
            case 'Voronoi':
                setVoronoiParams(colormapData.params); 
                break;
            case 'Packing':
                setPackingParams(colormapData.params); 
                break;
            case 'Grid':
                setGridParams(colormapData.params); 
                break;
            case 'Threads':
                setThreadsParams(colormapData.params);
                break;
            default: break;
        }
    }, [colormapData.type, colormapData.params]); 

    const handleUpdateParams = (field, value) => {
        setColormapData(prevData => (
            {
                ...prevData, 
                'params': {...prevData.params, [field]: value}
            }
        ));
    }

    return(

        <Card>
            <Card.Header className="control-settings__card-header">
                <LabeledSelect
                    label={tr.layout.map_type[language]}
                    options={options && options.types}
                    currentOption={colormapData.type} 
                    handleSelect={handleSwitchType}
                />
            </Card.Header>
            <Card.Body className="control-settings__card-body">
                <Row>
                    <Col>
                        {colormapData.type === 'Voronoi' &&
                        <Voronoi 
                            options = {options && options.voronoi}
                            handleUpdateParams={handleUpdateParams} 
                            {...voronoiParams}
                        />}
                        {colormapData.type === 'Perlin' &&
                        <Perlin 
                            options = {options && options.perlin}
                            handleUpdateParams={handleUpdateParams} 
                            {...perlinParams}
                        />}
                        {colormapData.type === 'Packing' &&
                        <Packing
                            options = {options && options.packing}
                            handleUpdateParams={handleUpdateParams} 
                            {...packingParams}
                        />}
                        {colormapData.type === 'Grid' &&
                        <Grid
                            options = {options && options.grid}
                            handleUpdateParams={handleUpdateParams} 
                            {...gridParams}
                        />}
                        {colormapData.type === 'Threads' &&
                        <Threads
                            options = {options && options.threads}
                            handleUpdateParams={handleUpdateParams} 
                            nbColors={nbColors}
                            {...threadsParams}
                        />}

                    </Col>
                    <Col> 
                        <MapThumbnail
                            nbColors={nbColors}
                            legendLayout={legendLayout}
                            colormapData={colormapData}
                        />
                    </Col>
                </Row>
                <Row>
                    {colormapData?.type &&
                    <Col>
                        <LabeledSelect
                            label={tr.layout.outline_thickness[language]}
                            options={options && options[colormapData.type.toLowerCase()].outline.thickness}
                            currentOption={colormapData.outlineThickness}
                            handleSelect={handleSelectOutline}
                        />
                        {colormapData.outlineThickness !== "None" && (
                        <ColorSelect
                            label={tr.layout.outline_color[language]}
                            handleSelect={handleSelectOutlineColor}
                            palettesColors={palettesColors}
                            options={options && options[colormapData.type.toLowerCase()].outline.color}
                            currentColor={colormapData.outlineColor} 
                        />)}
                    </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default Maps;
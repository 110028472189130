import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { LifePreserver } from 'react-bootstrap-icons';

import { CartPlusFill } from 'react-bootstrap-icons';

const SavedDrafts = ({handleCartAdd, draftRecord}) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
        <div className='summary-section-title'>
            <LifePreserver className='me-2' style={{fontSize: "1em"}}/> {{'EN': 'Saved Posters', 'FR': 'Sauvegardes'}[language]}
        </div>
        <div className="summary-section summary-section--drafts px-3">
        {draftRecord.filter(draft => !draft.inCart && draft.draftId!==-1).map( (draft, index) => {
            return(
                <div key={'sd'+draft.draftId} style={{height: "90%", position: "relative"}}>
                    <Button 
                        variant="light"
                        style={{position: "absolute", top: "2px", right: "2px", fontWeight: "800", 
                            boxShadow: "0 10px 10px 0 rgba(0, 0, 0, 0.8)"}}
                        onClick={() => handleCartAdd(draft.draftId)}
                    ><CartPlusFill/></Button>
                    {draft?.image_blob_url && 
                    <Image
                        src={draft?.image_blob_url}
                        style={{height: "100%", width: "auto", objectFit: "cover"}}
                        rounded
                    />}
                </div>
            )
        })}
        </div>
        </>
    );
};

export default SavedDrafts;
import {useState, useEffect, useRef, useContext} from 'react'

import BlockTemplateCollapse from "./BlockTemplateCollapse"
import BlockTemplatePortrait from "./BlockTemplatePortrait"
import ForegroundChoice from './ForegroundChoice'

import { Back, PersonSquare, FileBreak, CardText} from 'react-bootstrap-icons';

import { LanguageContext } from '../../../../contexts/LanguageContext';
import tr from '../../../../assets/translations/general.json';

import Legend from './Legend';
import Canvas from './Canvas';

const ModePortrait = ({
    currentDraft, setData,
    options, utils, defaultOption,
}) => {

    // -- CONTEXT --
    const {language} = useContext(LanguageContext);

    // -- DATA -- 
    const [mosaicData, setMosaicData] = useState([])
    const [layerData, setLayerData] = useState({})
    const [legendData, setLegendData] = useState({})
    const [canvasData, setCanvasData] = useState({})

    const [mosaicDataForeground, setMosaicDataForeground] = useState(currentDraft?.data?.mosaic[0])
    const [mosaicDataBackground, setMosaicDataBackground] = useState(currentDraft?.data?.mosaic[1])
    useEffect(() => {
        mosaicDataBackground && mosaicDataForeground && setMosaicData(
            [mosaicDataForeground, mosaicDataBackground]);
    }, [mosaicDataForeground, mosaicDataBackground])

    // Handle Init 

    const handleInit = (data) => {

        // Mosaic
        setMosaicDataForeground({...data.portrait.mosaic[0], 'id': 0})
        setMosaicDataBackground({...data.portrait.mosaic[1], 'id': 1})

        // Legend 
        const {title, author} = data.portrait.legend
        setLegendData({title, author})

        // Canvas 
        const {placeholder_type, background_color, background_gradient, legend_layout} = data.portrait.canvas
        setCanvasData({placeholder_type, background_color, background_gradient, legend_layout})

        setLayerData({   
            'type': 'library', 
            'upload_data': null, 
            'library_source': data.portrait.layer
        });
    }

    const handleEditMosaicBlock = (blockId, field, value) => {      
        blockId === 0 ? 
            setMosaicDataForeground({...mosaicDataForeground, [field]: value}) : 
            setMosaicDataBackground({...mosaicDataBackground, [field]: value})
    }

    const handleMosaicReload = (data) => {
        let mosaicDataList = [];
        for (let i = 0; i < data.mosaic.length; i++) {
            const item = _.cloneDeep(data.mosaic[i]);
            mosaicDataList.push(item);
        }
        setMosaicData(mosaicDataList)
    }
    
    // ----- DRAFT RELOAD ------ 
    const _ = require('lodash');
    useEffect(() => {

        if (currentDraft.editMode === 'portrait') {
            if (currentDraft.draftId !== -1) {
                currentDraft.data && handleMosaicReload(currentDraft.data);
                currentDraft.data.layer && setLayerData(currentDraft.data.layer);
                currentDraft.data.legend && setLegendData(currentDraft.data.legend)
                currentDraft.data.canvas && setCanvasData(currentDraft.data.canvas)
            }
        }
        else {
            defaultOption && Object.keys(defaultOption).length !== 0 && handleInit(defaultOption);
        }

    }, [currentDraft.draftId])

    // ----- DATA UPDATE ----- 
    useEffect(() => {
        setData({
            'mosaic': mosaicData,
            'layer': layerData, 
            'legend': legendData,
            'canvas': canvasData,
            'colormap': null, 
        })
    }, [mosaicData, layerData, legendData, canvasData])

    // -- COMPONENT LOAD -- 
    useEffect(() => {
        if (currentDraft.draftId === -1) {
            defaultOption && handleInit(defaultOption);
        }

    }, [defaultOption]);

    // ----- UX -----

    const navOffsetStyle = {position: "relative",  top: "-35px"}

    return(
    <>
        <div className='mb-5'>
            <a name="model" href="#model" aria-label={'model-section'} style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <PersonSquare className='me-2' style={{fontSize: "1.4rem"}}/>{tr.sections.model[language]} 
            </div>
            <ForegroundChoice 
                utils={utils} layerData={layerData} 
                setLayerData={setLayerData} setLegendData={setLegendData}
            />
            {mosaicData?.length === 2 &&
            <BlockTemplatePortrait
                blockId={0} mosaicData={mosaicData}
                handleSelect={handleEditMosaicBlock}
                options={options && options.portrait.mosaic[0]} 
                utils={utils}
            />
            }
        </div>
        <div className='mb-5'>
            <a name="background" href="#background" aria-label={'background-section'} style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <Back className='me-2' style={{fontSize: "1.4rem"}}/> {tr.sections.background[language]} 
            </div>
            {mosaicData?.length === 2 &&
            <BlockTemplateCollapse
                blockId={1} mosaicData={mosaicData}
                handleSelect={handleEditMosaicBlock}
                options={options && options.portrait.mosaic[1]}
                utils={utils} 
            />
            }
        </div>
        <div className='mb-5'>
            <a name="canvas" href="#canvas" aria-label='canvas-section' style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <FileBreak className='me-2' style={{fontSize: "1.3rem"}}/>{tr.sections.canvas[language]} 
            </div>
            <Canvas 
                options={options?.abstract?.canvas}
                defaultOption={defaultOption?.abstract?.canvas}
                canvasData={canvasData} setCanvasData={setCanvasData} utils={utils}
            />
        </div>
        <div style={{marginBottom: "100px"}}>
            <a name="legend" href="#legend" aria-label='legend-section' style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <CardText className='me-2' style={{fontSize: "1.4rem"}}/>{tr.sections.legend[language]} 
            </div>
            <Legend 
                options={options?.abstract?.legend}
                legendData={legendData} setLegendData={setLegendData} utils={utils} 
            />
        </div>
    </>
    )     
}

export default ModePortrait;

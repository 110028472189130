import { useContext } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import {LinkContainer} from 'react-router-bootstrap'
import Badge from "react-bootstrap/Badge";
import { CartFill, PaletteFill, Person, PersonFill } from "react-bootstrap-icons";

import {DraftContext} from '../../../contexts/DraftContext';
import {ThemeContext} from "../../../contexts/ThemeContext";
import {LanguageContext} from "../../../contexts/LanguageContext";
import { DeviceContext } from "../../../contexts/DeviceContext";

import DarkModeToggle from "../navbar/DarkModeToggle";
import LanguageToggle from "../navbar/LanguageToggle";

import icon from "../../../assets/images/divers/spiral.png";

const NavigationBar = () => {

    const {inCartCount} = useContext(DraftContext);
    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);
    const {mobile} = useContext(DeviceContext);
    
    return ( 
        <Navbar className="navbar" collapseOnSelect expand="lg" data-bs-theme={theme}>  
            <LinkContainer to="/">
                <Navbar.Brand href="#home" className="ps-2 pe-4">
                    <div className="d-flex flex-row align-center">
                        <img 
                            src={icon} alt="Kams.io" width="35" height="35" className="me-2"
                            style={{ alignSelf: 'center' }}
                        />
                        <div className="anagram-div">
                            <div className="child">K</div>
                            <div className="child">A</div>
                            <div className="child">M</div>
                            <div className="child">S</div>
                            <div className="child">.</div>
                            <div className="child">I</div>
                            <div className="child">O</div>
                        </div>
                    </div>
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse>
                <div className="d-flex flex-row flex-wrap justify-content-between w-100 gap-2">
                    <div className="d-flex gap-3 ms-3">
                        <Nav>
                            <div className="px-2 navbar-pill" >
                            <LinkContainer to="/laboratory">
                                <Nav.Link>
                                    <PaletteFill className="me-2 mb-1"/>
                                    {{'EN': 'Editor', 'FR': 'Éditeur'}[language]}
                                </Nav.Link>
                            </LinkContainer>
                            </div>
                        </Nav>
                        <Nav>
                            <div className="px-2 navbar-pill">
                            <LinkContainer to="/about">
                                <Nav.Link>
                                    <PersonFill className="me-2 mb-1"/>
                                    {{'EN': 'About', 'FR': 'A Propos'}[language]}
                                </Nav.Link>
                            </LinkContainer>
                            </div>
                        </Nav>
                        <Nav>
                            <div className="px-2 navbar-pill">
                            <LinkContainer to="/cart">
                                <Nav.Link>
                                    <CartFill className="me-2 mb-1"/>
                                    {{'EN': 'Cart', 'FR': 'Panier'}[language]}
                                </Nav.Link>
                            </LinkContainer>
                            </div>
                        </Nav>

                    </div>
                    <div className="d-flex gap-5 ms-2">
                        <LanguageToggle/>
                        <DarkModeToggle/>
                        <Nav>
                            <LinkContainer to="/cart">
                                <Nav.Link>
                                    <div className="d-flex flex-row align-items-center">
                                        <CartFill className="ms-0" style={{fontSize: "1.3rem", color: 'var(--color-2)'}}/>
                                        <Badge 
                                            className="ms-1" 
                                            bg={theme} style={{color: 'var(--color-1'}}
                                            pill>
                                        {inCartCount}</Badge>
                                    </div>
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </div>
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavigationBar;
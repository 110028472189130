import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { Button } from 'react-bootstrap';
import { Eyeglasses } from 'react-bootstrap-icons';

const AcceptTermsAndConditions = ({termsAccepted, setTermsAccepted, termsWarning, handleShow}) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
            <div className='pb-3'>
                <Button
                    className={'me-2 terms-checkbox' + (termsAccepted ? 
                    ' terms-checkbox--accepted' : ' terms-checkbox--not-accepted')}
                    onClick={() => setTermsAccepted(!termsAccepted)}
                >{termsAccepted ? '✔' : ''}
                </Button>
                <span>  {{'EN': 'Accept the ', 'FR': 'Accepter les '}[language]}
                    <a href="#" className='terms-link' onClick={handleShow}> 
                        {{'EN': "terms and conditions", 'FR': 'CGV'}[language]}
                    </a>
                </span>
                <Eyeglasses className='ms-2' size={20}/>
            </div>
            <div>
                {termsWarning && <span className='terms-warning'>{termsWarning}</span>}
            </div>
        </>
    );
};

export default AcceptTermsAndConditions;
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import RandomGenToggle from './RandomGenToggle';

import {useState, useRef, useEffect, useContext} from "react";
import { Check, Pencil, Shuffle } from 'react-bootstrap-icons';
import Dice from '../../../../assets/svg/Dice.svg'
import DiceDark from '../../../../assets/svg/DiceDark.svg'

import {LanguageContext} from '../../../../contexts/LanguageContext';
import { ThemeContext } from '../../../../contexts/ThemeContext';
import { MosaikUtilsContext } from '../../../../contexts/MosaikUtilsContext';

import tr from '../../../../assets/translations/general.json';

const RandomGen = ({setSeed, randomGen, handleRandomGenToggle}) => {

    const {language} = useContext(LanguageContext);
    const {theme} = useContext(ThemeContext);
    const {computedSeeds} = useContext(MosaikUtilsContext);

    const [digits, setDigits] = useState('000000');
    const [isEditing, setIsEditing] = useState(false);

    const ocExcept = useRef();
    const digitsInputField = useRef();

    useEffect(() => {   
        setSeed(parseInt(digits));
    }, [digits]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        setDigits(digits.padStart(6, '0'));
        setIsEditing(false);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {
            setDigits(value);
        }
    };

    const handleShuffle = () => { 
        handleRandomDigitsGeneration();
    };

    const handleRandomDigitsGeneration = () => {

        if (computedSeeds.data) {
            const randomIndex = Math.floor(Math.random() * computedSeeds.data.length);
            const randomSeed = computedSeeds.data[randomIndex];
            setDigits(randomSeed.toString().padStart(6, '0'));
        }
        else 
            setDigits(Math.floor(Math.random() * 1000000).toString().padStart(6, '0'));
    }

    useEffect(() => {

        const handleOutsideClick = (event) => {
            if (
                isEditing && !digitsInputField.current.contains(event.target) && 
                !ocExcept.current.contains(event.target)
            )
                {setIsEditing(false);}
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isEditing]);


    const digitStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "2rem",
        height: "2rem",
        border: "1px solid #000",
    }

    const style_button_editing = {
        backgroundColor: "#888",
        width: "100%",
    }

    const style_subtext = {
        fontSize: "0.8rem",
        fontStyle: "italic",
        marginTop: "1.5em",
        marginBottom: "3em",
    }

    return (
        <div>
            <Accordion flush 
                style={{position: "relative", width: "100%"}}
                activeKey={randomGen ? "0" : ""}
            >
                <Card style={{borderRadius: "0.5rem 0.5rem 0 0", border: "1px solid rgb(80, 80, 80)", 
                    backgroundColor: "var(--bg-color-3)", color: "var(--color-1"}}>
                    <Card.Header 
                        className="w-100 d-flex justify-content-end align-items-center p-0 pb-1"
                        style={{position: "absolute", zIndex: "2", bottom: "0"}}
                    >
                        <RandomGenToggle eventKey="0" onToggle={handleRandomGenToggle} randomGen={randomGen}/>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0" style={{position: "relative", zIndex: "1", width: "100%"}}>
                        <Card.Body>
                            <Row>
                                <div style={{borderBottom: "2px solid var(--color-2)", display: "flex"}}>
                                    <img className="pe-2" src={theme === 'light' ? DiceDark : Dice} alt="Size Reference" style={{width: "2em", height: "2em"}}/>
                                    <div className="mb-2" style={{fontSize: "1.3em", color: "var(--color-1)"}}>{tr.random_gen.title[language]}</div>
                                </div>
                            </Row>
                            <Row className="mb-3 mt-3 w-100 d-flex align-items-center">
                                <Col className='d-flex justify-content-center'>
                                {isEditing ? (
                                    <div>
                                        <Form.Control 
                                            type="text" name="digit"
                                            style={{width: "12rem", boderRadius: "0.5rem"}}
                                            placeholder={'Enter seed here'}
                                            value={digits} 
                                            onChange={handleChange}
                                            autoFocus
                                            ref={digitsInputField}
                                        />
                                    </div>
                                ) : (
                                    <div style={{display: "flex", flexFlow: "row no-wrap", justifyContent: "flex-end"}}>
                                        {digits.split('').map((digit, index) => (
                                            <div 
                                                key={index}
                                                style={{
                                                    ...digitStyle, 
                                                    ...(index===0 ? {borderRadius: "0.5rem 0 0 0.5rem"} : {}),
                                                    ...(index===5 ? {borderRadius: "0 0.5rem 0.5rem 0"} : {})
                                                }}
                                            >{digit}</div>
                                        ))}
                                    </div>
                                )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <Button
                                        size = "sm"
                                        variant={theme}
                                        onClick={handleShuffle}
                                        className='button-with-icon w-100'
                                    >{tr.random_gen.shuffle[language]} <Shuffle className='ms-2'/></Button>
                                </Col>
                                <Col className='d-flex justify-content-center'>
                                    <Button
                                        size="sm"
                                        onClick={isEditing ? handleSave : handleEdit}
                                        variant={theme}
                                        style={isEditing ? style_button_editing : {}}
                                        className='button-with-icon w-100'
                                        ref={ocExcept}x
                                    >
                                        {isEditing ? tr.random_gen.confirm[language] : tr.random_gen.edit[language]}
                                        {isEditing ? <Check className='ms-2'/> : <Pencil className='ms-2'/>}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <p style={style_subtext}>
                                    {tr.random_gen.content[language]}
                                </p>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default RandomGen;
import useAzureUploadBlob from './useAzureUploadBlob';
import { useState } from 'react';

const useFileUpload = (containerClient) => {

  const [layerUpload, setLayerUpload] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  const { uploadBlob } = useAzureUploadBlob(containerClient);

  const handleFileUpload = async (e) => {

    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const FIXED_WIDTH = 300;
    const SUPPORTED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'];

    const file = e.target.files[0];

    if (!file) {
      setUploadError('No file selected.');
      return;
    }

    const fileType = file.type;

    if (!SUPPORTED_FILE_TYPES.includes(fileType)) {
      setUploadError('File type not supported. Please upload a jpeg, png, or heic file.');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setUploadError('File size exceeds maximum limit of 5MB.');
      return;
    }

    // Special case for HEIC images - upload as-is
    if (fileType === 'image/heic') {
      try {
        const randomString = Math.random().toString(36).substring(2, 10);
        const blobName = `${randomString}_${file.name}`;
        const uploadResponse = await uploadBlob(file, blobName); // Upload the original file

        if (uploadResponse) {
          setLayerUpload({
            'type': 'upload',
            'blob_url': uploadResponse._response.request.url,
            'blob_name': blobName,
            'file_name': file.name,
            'file_type': fileType
          });
        }
      } catch (uploadError) {
        setUploadError(`Error uploading HEIC file: ${uploadError.message}`);
      }
      return;
    }

    const reader = new FileReader();
    reader.onload = async function(e) {

      const fileType = file.type;
      const dataUrl = e.target.result;
      const image = new Image();

      image.onload = async function() {
        const aspectRatio = image.height / image.width;
        const newHeight = FIXED_WIDTH * aspectRatio;

        const canvas = document.createElement('canvas');
        canvas.width = FIXED_WIDTH;
        canvas.height = newHeight;

        // Ensure the canvas context supports alpha transparency when applicable (i.e., for PNG files)
        const ctx = canvas.getContext('2d', { alpha: fileType === 'image/png' });

        ctx.drawImage(image, 0, 0, FIXED_WIDTH, newHeight);

        // Use correct mime type when calling toBlob
        canvas.toBlob(async function(blob) {
          if (!blob) {
            setUploadError('Error creating image blob.');
            return;
          }

          try {
            const randomString = Math.random().toString(36).substring(2, 10);
            const blobName = `${randomString}_${file.name}`;
            const uploadResponse = await uploadBlob(blob, blobName);

            if (uploadResponse) {
              setLayerUpload({
                'type': 'upload',
                'blob_url': uploadResponse._response.request.url,
                'blob_name': blobName,
                'file_name': file.name,
                'file_type': fileType
              });
            }
          } catch (uploadError) {
            setUploadError(`Error uploading file: ${uploadError.message}`);
          }
        }, fileType === 'image/png' ? 'image/png' : 'image/jpeg');  // Ensure PNG format is used when applicable
      };

      image.onerror = function() {
        setUploadError('Error loading image. Please try again.');
      };

      image.src = dataUrl;
    };

    reader.readAsDataURL(file);
  };

  return { handleFileUpload, layerUpload, uploadError };
};

export default useFileUpload;
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ToolsTemplateMobile = ({
    fileName,
    viewPort,
    loadDraftDropdown,
    posterSizeToggleChoice,
    buttonDelete, 
    buttonRenaming,
    buttonSave,
    buttonToggleCart,
    buttonToSettings,
    buttonToCart, 
}) => {
    return (
        <div className='px-3 d-flex flex-column' style={{height: "92vh", maxHeight: "92vh", overflow: "auto"}}> 
            <Row className="d-flex align-items-center mb-2">
                <Col className="d-grid gap-2">{loadDraftDropdown}</Col>
                <Col className="d-grid gap-2">{fileName}</Col>
            </Row>
            <div className='mb-4 w-100 d-flex flex-column' style={{flexGrow: "1", maxHeight: "90vh", overflow: "auto"}}>
                {viewPort}
            </div>
            <div>
                <Row className="mb-2">
                    <Col className="d-grid gap-2">{buttonToggleCart}</Col>
                </Row>
                <Row className="mb-4">
                    <Col className="d-grid gap-2">{buttonSave}</Col>
                    <Col className="d-grid gap-2">{buttonDelete}</Col>
                </Row>
                <Row className="mb-5">
                    {buttonToSettings}
                </Row>
            </div>
        </div>
    );
};

export default ToolsTemplateMobile;
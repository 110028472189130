import ColorPill from "./ColorPill";

const ColorTemplate = (props) => {

    return (
        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <div>{props.paletteName}</div>
            <ColorPill palette={props.colors} height={"1.2rem"} width={"6rem"}/>
        </div>
    
    )
}

export default ColorTemplate; 
import { createContext } from 'react';

export const DeviceContext = createContext();

export const DeviceProvider = (props) => {

    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <DeviceContext.Provider value={{mobile}}>
        {props.children}
      </DeviceContext.Provider>
    );
  };
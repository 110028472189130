import { useState, useRef, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import { XSquare, CheckSquare, FileEarmark} from 'react-bootstrap-icons';

const FileName = ({
    currentName, isRenaming, 
    setRenaming, isSaved, 
    handleChangeName,
    ocExcept, 

}) => {

    const [newDraftName, setNewDraftName] = useState('');
    const buttonGroup = useRef();
    const inputField = useRef();

    const handleInputChange = (event) => {
        setNewDraftName(event.target.value);
    }

    const handleSaveRename = () => {
        if (newDraftName.trim() !== '') {
            handleChangeName(newDraftName);
            setRenaming(false);
        }
    }

    const handleCancelRename = () => {
        setRenaming(false);
    }

    useEffect(() => {

        // Outside click handler
        const handleOutsideClick = (event) => {
            if (isRenaming && !buttonGroup.current.contains(event.target) && 
            !ocExcept.current.contains(event.target) && !inputField.current.contains(event.target))
                {setRenaming(false);}
          };

        document.addEventListener('click', handleOutsideClick);
        setNewDraftName("");
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isRenaming, ocExcept, setRenaming]);

    const unsavedStyle = {color:"var(--color-2)", fontStyle:"italic", fontSize:"1rem"}
    const savedStyle = {color:"var(--color-1)", fontSize:"1rem"}

    return (
        <div className='mt-2 mb-2' style={{minHeight: '2.5em'}}>
            {
                !isRenaming && 
                <div 
                    className="file-panel__filename"
                    style={!isSaved ? unsavedStyle : savedStyle}
                ><FileEarmark style={{fontSize: "1.4em"}} className='pe-2'/>{currentName ? currentName : 'No draft'}{isSaved ? '': (currentName === 'No draft' ? '' : ' (*)')}
                </div>
            }
            {
                isRenaming && 
                <>
                    <div className='mb-1' style={{display: "flex", flexDirection: "row", height: "2em"}}>
                        <Form.Control 
                            type="text" name="draft_name"
                            placeholder={currentName} 
                            value={newDraftName} 
                            onChange={handleInputChange}
                            autoFocus
                            ref={inputField}
                        />
                        <CheckSquare ref={buttonGroup} color="white" className="button-icon-val ms-1" onClick={handleSaveRename}/>
                        <XSquare ref={buttonGroup} color="white" className="button-icon-del ms-1" onClick={handleCancelRename}/>
                    </div>
                </>
            }
        </div>
    )
}

export default FileName;
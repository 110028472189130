import Form from "react-bootstrap/Form";

const LabeledInput = ({label, placeholder, currentInput, handleChange}) => {

    return (
        <>
         <Form.Label className="control-settings__form-label">{label}</Form.Label>
         <Form.Control
            value={currentInput ? currentInput : ''}
            size="sm" variant="outline-light" 
            placeholder={placeholder}
            aria-label="Default select example"
            name={`${label}_input`}
            onChange={(e) => {handleChange(e.target.value)}}
        />
        </>
    );
}

export default LabeledInput;
import { useState, useCallback } from 'react';

const usePostData = () => {

    const [res, setRes] = useState({ data: null, error: null, isLoading: false });

    const callApi = useCallback(async (endpoint, payload, source = 'API') => {

        setRes(prevState => ({ ...prevState, isLoading: true }));

        const API_URL = source === 'API' ? 
            process.env.REACT_APP_API_URL : 
            process.env.REACT_APP_AF_URL;

        try {
            const response = await fetch(API_URL + '/' + endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('session_token')}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(payload),
                mode: "cors",
                credentials: "include",
            });

            if (!response.ok) {
                const errorText = await response.text();
                const error = errorText ? JSON.parse(errorText) : { message: 'Unknown error' };
                throw { ...error, status: response.status };
            }

            const text = await response.text();
            const data = text ? JSON.parse(text) : null;

            setRes({ data, isLoading: false, error: null });

        } catch (error) {
            setRes({ data: null, isLoading: false, error });
        }
    }, []);

    return [res, callApi];
}

export default usePostData;

import { useContext, useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";

import {ArrowCounterclockwise, EnvelopeFill, LayoutWtf, Linkedin, PersonCircle} from "react-bootstrap-icons";
import NavigationBar from "./NavigationBar";

import { LanguageContext } from "../../../contexts/LanguageContext";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { DeviceContext } from "../../../contexts/DeviceContext";
import { MosaikUtilsContext } from "../../../contexts/MosaikUtilsContext";

import useFadeOnScroll from "../../../hooks/useFadeOnScroll";
import { Button, Col, Image } from "react-bootstrap";

import AboutContent from "../about/AboutContent";
import ModalContact from "../utils/ModalContact";

const About = () => {

    const {language} = useContext(LanguageContext);
    const {theme} = useContext(ThemeContext);
    const {mobile} = useContext(DeviceContext);

    const {seedBank, seedBankUrls, handleRequestBuildAPI} = useContext(MosaikUtilsContext);
    const [seedUrlsSelection, setSeedUrlsSelection] = useState();

    const navigate = useNavigate();

    const contentRef = useRef(null);
    const {fadeOpacityT, fadeOpacityB} = useFadeOnScroll(contentRef);
    
    const [contactShow, setContactShow] = useState(false);

    useEffect(() => { 
        seedBankUrls && getRandom() 
    }, [seedBankUrls]);

    const handleShuffle = () => {
        seedBankUrls && getRandom();
    }

    const getRandom = () => {

        const randomSeeds = {};
        const keys = Object.keys(seedBankUrls);
        for (let i = 0; i < 8 && keys.length > 0; i++) {
            const randomIndex = Math.floor(Math.random() * keys.length);
            const key = keys.splice(randomIndex, 1)[0];
            randomSeeds[key] = seedBankUrls[key];
        }
        setSeedUrlsSelection(randomSeeds);
    }

    const runSeedBuild = (seedBlobId) => {
        const seedEntry = Object.entries(seedBank.data).find(([key, value]) => value === seedBlobId);
        const seed = seedEntry ? parseInt(seedEntry[0], 10) : -1;
        const dataBundle = {
            'edit_mode': 'abstract',
            'random_gen': true,
            'random_seed': seed,
        };
        handleRequestBuildAPI(dataBundle);
        navigate('/laboratory');
    };

    const window_style = {
        height: "92vh", 
        maxHeight: "92vh", 
        overflow: "hidden",
        width: "80%",
        margin: "auto",
        padding: "5em 0em"
    }

    const Socials = <div 
        className="d-flex flex-column justify-content-center align-items-end"
        style={{position: "absolute", right: "30px", top: "10px"}}>
        <a href="https://www.linkedin.com/in/nathan-kammoun-2a51231b2/" target="_blank" rel="noopener noreferrer">
            <Linkedin className="button-icon-socials"></Linkedin>
        </a>
        <EnvelopeFill 
            className="mt-2 button-icon-socials" 
            onClick={() => setContactShow(true)}
        />
    </div>

    return (
        <>
            <NavigationBar/>
            {mobile ?
            <div className="px-3 my-4" style={{position: "relative", color: "var(--color-1)"}}>
                {Socials}
                <AboutContent/>
            </div>:
            <div 
                className='d-flex flex-row justify-content-between' 
                style={window_style}
            >
                <div style={{height: "90%", width: "40%"}}>
                    <div className='summary-section-title'>
                        <PersonCircle className='me-2' style={{fontSize: "1em"}}/> 
                        {{'EN': 'About', 'FR': 'A Propos'}[language]}
                    </div>
                    <div style={{position: "relative"}}>
                        <div 
                            style={{borderRadius: "10px 10px 0 0", height: fadeOpacityT * 80}} 
                            className='summary-section-scroll-fade-top'>{' '}
                        </div>
                        {Socials}
                    </div>
                    <div 
                        ref={contentRef} className='summary-section summary-section--info ps-3 pe-4 pt-2'
                        style={{maxHeight: "100%", overflowY: "auto"}}
                    >
                        <AboutContent/>
                    </div>
                    <div style={{position: "relative"}}>
                        <div 
                            style={{borderRadius: "10px 10px 0 0", height: fadeOpacityB * 80}} 
                            className='summary-section-scroll-fade-bottom'>{' '}
                        </div>
                    </div>
                </div>
                <div style={{height: "90%", width: "55%"}}>
                    <div className='summary-section-title'>
                        <LayoutWtf className='me-2' style={{fontSize: "1em"}}/> 
                        {{'EN': 'Randomly Generated', 'FR': 'Génération Aléatoires'}[language]}
                        <Button size="sm" className={"ms-2"} style={{borderRadius: "50%"}}
                            variant={theme === 'dark' ? 'outline-light' : 'outline-dark'}
                            onClick={() => handleShuffle()}
                        >
                            <ArrowCounterclockwise />
                        </Button>
                    </div>
                    <div className='summary-section h-100 px-2' style={{overflow: "hidden"}}>
                        {seedUrlsSelection && <div 
                            className="w-100 d-flex flex-wrap h-100"
                            style={{justifyContent: "around", alignItems: "center"}}>
                            {
                                Object.values(seedUrlsSelection).map((url, index) => (
                                    <Col key={index} className="d-flex h-50 py-2 justify-content-center align-center">
                                        <Image 
                                            src={url} 
                                            className='seed-image py-2 h-100' 
                                            style={{ 
                                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                                borderRadius: '12px', border: '1px solid rgba(0, 0, 0, 0.1)',
                                            }}
                                            alt={`poster-${index}`}
                                            onClick={() => runSeedBuild(Object.keys(seedUrlsSelection)[index])}
                                        />
                                    </Col>
                                ))
                            }
                        </div>}
                    </div>
                </div>
            </div>
        }

        <ModalContact show={contactShow} setShow={setContactShow} showSource={'About'}/>
        </>
    );
}   

export default About;
import {useState} from 'react';

import MondialRelayPin from "../../../../assets/svg/MondialRelayPin.svg";
import MondialRelayPinConfirmed from "../../../../assets/svg/MondialRelayPinConfirmed.svg";

import { Map, AdvancedMarker, InfoWindow, Pin } from "@vis.gl/react-google-maps";

import { PlusCircleFill, DashCircleFill } from 'react-bootstrap-icons';

const GoogleMap = ({
    selected, setSelectedSP, 
    addressList, setAddressList, 
    defaultPos, zoom, 
    language
}) => {

    const [open, setOpen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(zoom);

    return (
        <>
        <Map
            mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
            zoom={zoomLevel}
            center={selected ? selected : defaultPos}
            options={{
                mapTypeControl: false, 
                streetViewControl: false, 
                rotateControl: false,
                fullscreenControl: false,
                zoomControl: false, 
                scaleControl: false,
                draggable: false,
            }}
            clickableIcons = {false}
            gestureHandling = 'none'
            colorScheme={'LIGHT'}
        >
            {addressList && addressList.map((address, index) => 
                <div key={index}>
                    <AdvancedMarker
                        position={{lat: parseFloat(address.latitude), lng: parseFloat(address.longitude)}}
                        onClick={() => {
                            setAddressList(addressList.map((item, i) => i === index ? {...item, pinopen: true} : {...item, pinopen: false}));
                            setSelectedSP(address);
                        }}
                        >
                        <img key={'pin'+index} src={address.pinopen ? MondialRelayPinConfirmed : MondialRelayPin} alt="my pin" />
                    </AdvancedMarker>
                </div>
            )}
            <AdvancedMarker 
                position={selected ? selected : defaultPos}
                onClick={() => setOpen(true)}
            >
                <Pin background={"grey"} borderColor={"black"} glyphColor={"white"}></Pin>
            </AdvancedMarker>
            {open && 
            <InfoWindow 
                position={selected ? selected : defaultPos}
                onCloseClick={() => setOpen(false)}
            >{{'EN': 'Reference address', 'FR': 'Adresse de référence'}[language]}
            </InfoWindow>}
        </Map>
        <div 
            className='w-100 d-flex justify-content-end p-2 gap-2'
            style={{position: "absolute", bottom: "0", right: "0"}}>
            <PlusCircleFill 
                className="button-with-icon" variant={"light"} size={30}
                onClick={() => setZoomLevel(Math.min(zoomLevel + 1, 20))}
            />
            <DashCircleFill 
                className="button-with-icon" size={30}
                onClick={() => setZoomLevel(Math.max(zoomLevel - 1, 14))}
            />
        </div>
        </>
    );
};

export default GoogleMap;

import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ThemeContext } from '../../../contexts/ThemeContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { DraftContext } from '../../../contexts/DraftContext';

import FileName from './tools/FileName';
import LoadDraftDropdown from './tools/LoadDraftDropdown';
import Viewport from './tools/Viewport';
import ButtonDelete from './tools/ButtonDelete';
import ButtonRenaming from './tools/ButtonRenaming';
import ButtonSave from './tools/ButtonSave';
import ButtonToggleCart from './tools/ButtonToggleCart';
import ButtonToCart from './tools/ButtonToCart';
import ButtonToSettings from './tools/ButtonToSettings';
import PosterSizeToggleChoice from './tools/PosterSizeToggleChoice';    
import PosterSizeChoice from './tools/PosterSizeChoice';

import ToolsTemplate from './../../templates/tools/ToolsTemplate';


const Tools = ({responseLoading, setPanel}) => {

    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);
    const {
        currentDraft, setCurrentDraft,
        draftRecord, setDraftRecord,
        handleEditSavedDraft,
        handleEditCurrentDraft,
        handleDraftSelect,
        handleDraftSave,
        handleDraftDelete,
        currentSaved,
    } = useContext(DraftContext);

    // ADD/REMOVE CART
    const handleAddToCart = () => {
        currentDraft.draftId !== -1 && setCurrentDraft({...currentDraft, inCart: !currentDraft.inCart});
    }

    // GO TO CART
    const navigate = useNavigate();
    const handleGoToCart = () => {
        currentDraft.draftId !== -1 && handleDraftSave();
        draftRecord.length === 0 && currentDraft.draftId !== -1 && setCurrentDraft({...currentDraft, inCart: true});
        navigate('/cart');
    }

    // RENAMING
    const [isRenaming, setRenaming] = useState(false);
    const outsideClickException = useRef();

    // DISABLE BUTTONS
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        setDisabled(currentDraft.draftId === -1 || responseLoading);
    }, [currentDraft.draftId, responseLoading]);

    // _-_-_-_-_-_-_-_-_- COMPONENTS _-_-_-_-_-_-_-_-_-_-_-
    // _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_--_-_-_

    const loadDraftDropdownMobile = <LoadDraftDropdown
        dropDirection="down"
        draftRecord={draftRecord}
        handleDraftSelect={handleDraftSelect}
        disabled={disabled}
        theme={theme} lang={language}
    />

    const loadDraftDropdownDefault = <LoadDraftDropdown
        dropDirection="left"
        draftRecord={draftRecord}
        handleDraftSelect={handleDraftSelect}
        disabled={disabled}
        theme={theme} lang={language}
    />

    const viewPort = <Viewport responseLoading={responseLoading}/>

    const fileName = <FileName
        currentName={currentDraft.name} 
        isRenaming={isRenaming} setRenaming={setRenaming}
        isSaved={currentSaved} handleChangeName={(name) => handleEditCurrentDraft('name', name)}
        ocExcept={outsideClickException} handleDraftSave={handleDraftSave}
    />

    const buttonDelete = <ButtonDelete 
        handleDraftDelete={handleDraftDelete}
        disabled={disabled}
        theme={theme} lang={language}
    />

    const buttonRenaming = <ButtonRenaming
        outsideClickException={outsideClickException}
        setRenaming={setRenaming}
        disabled={disabled}
        theme={theme} lang={language}
    />

    const buttonSave = <ButtonSave
        handleDraftSave={handleDraftSave}
        disabled={disabled}
        theme={theme} lang={language}
    />

    const buttonToggleCart = <ButtonToggleCart
        handleAddToCart={handleAddToCart}
        inCart={currentDraft.inCart}
        disabled={disabled}
        theme={theme} lang={language}
    />

    const buttonToCart = <ButtonToCart
        handleGoToCart={handleGoToCart}
        lang={language}
    />

    const buttonToSettings = <ButtonToSettings 
        handleGoToCart={handleGoToCart}
        setPanel={setPanel} 
        lang={language} theme={theme}
    />

    const posterSizeToggleChoice = <PosterSizeToggleChoice
        posterSize={currentDraft && currentDraft.posterSize}
        setPosterSize={(value) => handleEditSavedDraft(currentDraft.draftId, 'posterSize', value)}
        theme={theme} lang={language}
    />

    const posterSizeChoice = <PosterSizeChoice
        posterSize={currentDraft && currentDraft.posterSize}
        setPosterSize={(value) => handleEditSavedDraft(currentDraft.draftId, 'posterSize', value)}
        theme={theme} lang={language}
    />
    
    return (
        <ToolsTemplate
            fileName={fileName}
            viewPort={viewPort}
            loadDraftDropdownDefault={loadDraftDropdownDefault}
            loadDraftDropdownMobile={loadDraftDropdownMobile}
            buttonDelete={buttonDelete}
            buttonRenaming={buttonRenaming}
            buttonSave={buttonSave}
            buttonToggleCart={buttonToggleCart}
            buttonToCart={buttonToCart}
            buttonToSettings={buttonToSettings}
            posterSizeToggleChoice={posterSizeToggleChoice}
            posterSizeChoice={posterSizeChoice}
        />
    );
};

export default Tools;
import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import NavigationBar from '../pages/NavigationBar';

import {ThemeContext} from '../../../contexts/ThemeContext';

import Button from 'react-bootstrap/Button';

const FallbackUI = () => {

    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();

    return (
        <>
            <NavigationBar/>
            <div 
            className="d-flex flex-column justify-content-center align-items-center"
            style={{height: "92vh", maxHeight: "92vh", overflow: "hidden"}}
            >
                <div className='p-2 d-flex flex-column align-items-center justify-content-center' style={{
                    borderRadius: "10px", boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
                    backgroundColor: "var(--bg-color-1)", color: "var(--color-2)",
                    maxWidth: "50%", maxHeight: "95%",
                }} >
                    <h1 className='text-center' style={{fontSize: "100px"}}>😶‍🌫️</h1>
                    <h2 className='text-center'>An error occured.</h2>
                    <h3 className='text-center mb-4'>Please try again later.</h3>
                    <Button 
                        className="w-100" 
                        variant={theme} 
                        onClick={() => navigate('/laboratory')}
                    >
                        Back to Laboratory
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FallbackUI;
import React from 'react';
import { Button } from 'react-bootstrap';

import { useContext } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ArrowLeftSquareFill } from 'react-bootstrap-icons';

const CheckoutTemplateMobile = ({
    shipping, 
    payment, 
    handleBackToCart,
}) => {

    const {theme} = useContext(ThemeContext);
    const {language} = useContext(LanguageContext);

    return (
        <div className='px-3 mt-2 mb-5'>
            <Button 
                className='w-100 my-3'
                onClick={handleBackToCart}
                variant="dark"
            >
                <ArrowLeftSquareFill className='me-3 mb-1'/>
                {{'EN': 'Back to Cart', 'FR': 'Retour au Panier'}[language]}
            </Button>
            <div
                className='mb-4'
                style={{
                    display: "flex", flexDirection: "column",
                }}
            >
                {shipping}
            </div>
            <div
                className='mb-5'
                style={{
                    display: "flex", flexDirection: "column",
                }}
            >
                {payment}
            </div>
        </div>
    );
};

export default CheckoutTemplateMobile;
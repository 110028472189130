import {useState, useEffect, useRef, useContext} from 'react'

import Blocks from './Blocks'
import Maps from './Maps'
import Legend from './Legend'
import Canvas from './Canvas'

import {Card} from 'react-bootstrap';

import {Box, CardText, FileBreak, Puzzle} from 'react-bootstrap-icons';

import { LanguageContext } from '../../../../contexts/LanguageContext';
import tr from '../../../../assets/translations/general.json';

//import { LayoutWtf,} from 'react-bootstrap-icons';

const ModeAbstract = ({
    currentDraft, setData,
    options, utils, defaultOption,
}) => {  

    // -- CONTEXT --
    const {language} = useContext(LanguageContext);

    // -- DATA --
    const [mosaicData, setMosaicData] = useState([])
    const [legendData, setLegendData] = useState({})
    const [canvasData, setCanvasData] = useState({})
    const [colormapData, setColormapData] = useState(
        {'type': null, 'params': null, 'outlineThickness': null, 'outlineColor': null})

    // -- HANDLE INIT --

    const handleInit = (data) => {

        // Mosaic
        if (mosaicData.length===0) {
            const blockData1 = defaultOption?.abstract.mosaic[0];
            const blockData2 = defaultOption?.abstract.mosaic[1];
            const blockId1 = Math.floor(Math.random() * 1000) + 2;
            const blockId2 = Math.floor(Math.random() * 1000) + 3;
            const defaultBlock1 = blockData1 && {...blockData1, 'id': blockId1}
            const defaultBlock2 = blockData2 && {...blockData2, 'id': blockId2}
            setMosaicData([...mosaicData, defaultBlock1, defaultBlock2]);
        } 

        // Colormap Initialization
        const {types, voronoi, perlin, grid, packing, threads} = data.abstract.maps.color;
        const params = {
            'Voronoi': voronoi, 
            'Perlin': perlin, 
            'Grid': grid, 
            'Packing': packing, 
            'Threads': threads,
        }[types]

        setColormapData({
            'type': types, 
            'params': params,
            'outlineThickness': params.outline.thickness, 
            'outlineColor': params.outline.color
        })

        // Legend
        const {title, author} = data.abstract.legend
        setLegendData({title, author})

        // Canvas 
        const {placeholder_type, background_color, background_gradient, legend_layout} = data.abstract.canvas
        setCanvasData({placeholder_type, background_color, background_gradient, legend_layout})

    }

    // Block Handlers

    const handleAddMosaicBlock = () => {
        const blockData = defaultOption?.abstract.new_block;
        const blockId = Math.floor(Math.random() * 1000) + 2;
        const defaultBlock = blockData && {
            'id': blockId, 'size': blockData.size, 
            'layout': blockData.layout,
            'collection': blockData.collection,
            'elements': blockData.elements,
            'color' : blockData.color,
        };
        setMosaicData([...mosaicData, defaultBlock]);
    }

    const handleDeleteMosaicBlock = (blockId) => {        
        const updatedMosaicData = mosaicData.filter(block => block.id !== blockId);
        setMosaicData(updatedMosaicData);
    }

    const handleEditMosaicBlock = (blockId, field, value) => {      
        const updatedMosaicData = mosaicData.map(block => {
            if (block.id === blockId) {block[field] = value;}   
            return block;
        });
        setMosaicData(updatedMosaicData);
    }

    const handleMosaicReload = (data) => {
        let mosaicDataList = [];
        for (let i = 0; i < data.mosaic.length; i++) {
            const item = _.cloneDeep(data.mosaic[i]);
            mosaicDataList.push(item);
        }
        setMosaicData(mosaicDataList)
    }


    // -- DRAFT RELOAD --
    const _ = require('lodash');
    useEffect(() => {
        if (currentDraft.editMode === 'abstract') {
            if (currentDraft.draftId !== -1) {
                currentDraft.data && handleMosaicReload(currentDraft.data);
                currentDraft.data.colormap && setColormapData(currentDraft.data.colormap)
                currentDraft.data.legend && setLegendData(currentDraft.data.legend) 
                currentDraft.data.canvas && setCanvasData(currentDraft.data.canvas)  
            }
        }
        else {
            defaultOption && Object.keys(defaultOption).length !== 0 && handleInit(defaultOption);
        }
    }, [currentDraft.draftId])

    // ----- DATA UPDATE ----- 
    useEffect(() => {
        setData({
            'mosaic': mosaicData,
            'layer': {'type': 'empty'}, 
            'colormap': colormapData,
            'legend': legendData, 
            'canvas': canvasData, 
        })
    }, [mosaicData, colormapData, legendData, canvasData])

    // -- COMPONENT LOAD -- 
    useEffect(() => {
        if(currentDraft.draftId === -1) {
            defaultOption && Object.keys(defaultOption).length !== 0 && handleInit(defaultOption);
        }
    }, [defaultOption]);

    // ----- UX -----

    const navOffsetStyle = {position: "relative",  top: "-35px" }   

    return(
    <>
        <div className='mb-5'>
            <a name="blocks" href="#blocks" aria-label='blocks-section' style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <Box className='me-2' style={{fontSize: "1.2rem"}}/>{tr.sections.blocks[language]} 
            </div>
            {mosaicData && 
            <Blocks
                options={options?.abstract?.mosaic} 
                mosaicData={mosaicData} utils={utils}
                handleAddMosaicBlock={handleAddMosaicBlock}
                handleEditMosaicBlock={handleEditMosaicBlock}
                handleDeleteMosaicBlock={handleDeleteMosaicBlock}
            />
            }
        </div>
        <div className='mb-5'>
            <a name="layout" href="layout" aria-label='layout-section'>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <Puzzle className='me-2' style={{fontSize: "1.4rem"}}/> {tr.sections.layout[language]}
            </div>
            <Maps 
                options={options?.abstract?.maps.color}
                defaultOption={defaultOption?.abstract?.maps.color}
                palettesColors={utils?.colors} 
                colormapData={colormapData} setColormapData={setColormapData} 
                nbColors={mosaicData.length} legendLayout={canvasData.legend_layout}
            />
            {false && 
            <Card className="pb-3 d-flex text-center control-settings__card-header">
                <div className="mt-2" style={{color: 'var(--color-2)', fontStyle: "italic"}}>
                    Add more blocks to enable layout options
                </div>
            </Card>}
        </div>
        <div className='mb-5'>
            <a name="canvas" href="#canvas" aria-label='canvas-section' style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
            <FileBreak className='me-2' style={{fontSize: "1.3rem"}}/>{tr.sections.canvas[language]} 
            </div>
            <Canvas 
                options={options?.abstract?.canvas}
                defaultOption={defaultOption?.abstract?.canvas}
                canvasData={canvasData} setCanvasData={setCanvasData} utils={utils}
            />
        </div>
        { canvasData?.legend_layout !== 'No Legend' &&
        <div style={{marginBottom: "100px"}}>
            <a name="legend" href="#legend" aria-label='legend-section' style={navOffsetStyle}>{' '}</a>
            <div className="control-settings__subtitle mb-2">
                <CardText className='me-2' style={{fontSize: "1.3rem"}}/>{tr.sections.legend[language]}
            </div>
            <Legend 
                options={options?.abstract?.legend}
                legendData={legendData} setLegendData={setLegendData} utils={utils} 
            />
        </div>
        }
    </>
    )     
}
    
export default ModeAbstract;


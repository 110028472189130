import {useState, createContext, useEffect} from 'react';
import useFetchData from '../hooks/useFetchData';

export const DraftContext = createContext();

export const DraftProvider = ({ children }) => {

    const EMPTYDRAFT = {
      draftId: -1, 
      data: null, 
      editMode: 'abstract',
      name: 'No draft',
      mockups_urls: {},
      inCart: false,
    };
  
    // Draft Record
    const [draftRecord, setDraftRecord] = useState([]);
    const [inCartCount, setInCartCount] = useState(0);
    const [currentSaved, setCurrentSaved] = useState(false);

    // Current Draft
    const [currentDraft, setCurrentDraft] = useState(
        draftRecord.length > 0 ? draftRecord[0] : EMPTYDRAFT
    );

    // UPDATE COUNTS
    useEffect(() => {
        setInCartCount(draftRecord.filter(draft => draft.inCart).length);
    }, [draftRecord]);

    // UPDATE CURRENT SAVED (WHEN DRAFT NAME CHANGES)
    useEffect(() => {
      const existingDraft = draftRecord.findIndex(draft => draft.name === currentDraft.name);
      setCurrentSaved(existingDraft !== -1 ? draftRecord[existingDraft].draftId === currentDraft.draftId : false);
    }, [currentDraft.name, currentDraft.draftId, draftRecord]);

    // SAVE CD IN DR WHEN CD ADDED/REMOVED FROM CART
    useEffect(() => {
      currentDraft.inCart && handleDraftSave();
    }, [currentDraft.inCart]);

    // DRAFT EDITING
    const handleEditSavedDraft = (draftId, field, value) => {
      const existingDraftIndex = draftRecord.findIndex(draft => draft.draftId === draftId);
      if (existingDraftIndex !== -1) {
          const updatedDraftRecord = [...draftRecord];
          updatedDraftRecord[existingDraftIndex][field] = value;
          setDraftRecord(updatedDraftRecord);
      }
    }

    // DRAFT RENAMING 
    const handleEditCurrentDraft = (field, value) => {
      const existingDraftIndex = draftRecord.findIndex(draft => draft.name === currentDraft.name);
      if (existingDraftIndex !== -1) {
          const updatedDraftRecord = [...draftRecord];
          updatedDraftRecord[existingDraftIndex][field] = value;
          setDraftRecord(updatedDraftRecord);
      }
      setCurrentDraft({...currentDraft, [field]: value});
    }

    // DRAFT SAVING
    const handleDraftSave = () => {

      const existingDraftIndex = draftRecord.findIndex(draft => draft.draftId === currentDraft.draftId);

      // There is a draft with the same ID 
      if (existingDraftIndex !== -1) {
          const updatedDraftRecord = [...draftRecord];
          // Name is changed but Id is the same (not rerendered) -> Replacing 
          if (updatedDraftRecord[existingDraftIndex].name !== currentDraft.name) {
              updatedDraftRecord[existingDraftIndex] = currentDraft;
              setDraftRecord(updatedDraftRecord);
          }
          // Name is the same and Id is the same (not rerendered) -> Replacing
          else {
              updatedDraftRecord[existingDraftIndex] = currentDraft;
              setDraftRecord(updatedDraftRecord);
          }
      }
      // There is not draft with the same ID 
      else {
          const existingWithSameName = draftRecord.findIndex(draft => draft.name === currentDraft.name);
          // Draft Exists somewhere with same name 
          if (existingWithSameName !== -1) {
              const regex = /\((\d+)\)/;

              let highestNumber = 0;
              draftRecord.forEach((draft) => {
                  if (regex.test(draft.name)) {const number = Number(draft.name.match(regex)[1]);
                  if (number > highestNumber) {highestNumber = number;}
              }});
              
              const suffix = highestNumber===0 ?  ' (1)' : ` (${highestNumber+1})`
              const prefix = currentDraft.name.split(regex)[0].trim(); 
              setDraftRecord([...draftRecord, {...currentDraft, name :  prefix+suffix}]);
              setCurrentDraft({...currentDraft, name :  prefix+suffix});
          }
          // No draft with any name or id 
          else {
              setDraftRecord([...draftRecord, currentDraft])
          }
      }
    }

    // DRAFT DELETION
    const handleDraftDelete = () => {
      if (currentDraft.draftId !== -1) {
        const updatedDraftRecord = draftRecord.filter((dr) => dr.draftId !== currentDraft.draftId);
        setDraftRecord(updatedDraftRecord);
        if (updatedDraftRecord.length > 0) {
            setCurrentDraft(updatedDraftRecord[0]);}
        else {
            setCurrentDraft(EMPTYDRAFT);
        }
      }
    }

    // DRAFT SELECTION 
    const handleDraftSelect = (draftId) => {
      const selectedDraft = draftRecord.find((dr) => dr.draftId === draftId);
      if (selectedDraft) {setCurrentDraft(selectedDraft);}
      else {console.log('Draft not found');}
    }


    // DRAFT TEMPLATE
    const handleDraftTemplate = (buildData) => {

      const newDraft = {
        draftId: buildData.build_id,
        data: buildData.frontend_data,
        image_blob_id: buildData.image_blob_id,
        posterSize: 'B2',
        editMode: buildData.frontend_data.edit_mode,
        name: `Draft ${draftRecord.length + 1}`,
        mockups_urls: {},
        inCart: false,
      }

      currentDraft.draftId !== -1 && handleDraftSave();
      setCurrentDraft(newDraft);
    }


    // DRAFT RECOVERY
    const [draftData, getDraftData] = useFetchData();
    const [recoveredDraftRecord, setRecoveredDraftRecord] = useState([]);
    const handleDraftRecovery = async (items) => {

      let recoveredDraftRecord = [];
      if (items && items.length > 0) {
        items.map( (item, index) => {
          recoveredDraftRecord.push({
            draftId: item.build_id,
            edit_mode: 'abstract',
            data: null,
            posterSize: item.size,
            image_blob_url: item.build_image_blob_url,
            image_blob_id: item.build_image_blob_id,
            name: `Draft ${index+1}`,
            mockups_urls: [],
            inCart: true,
          });
        });

        setRecoveredDraftRecord(recoveredDraftRecord);

        const queryString = items.map(item => `ids=${item.build_id}`).join('&');
        getDraftData('api/builds?' + queryString);
      }
    }

    useEffect(() => {
      if (draftData.data) {
        const updatedDraftRecord = [...recoveredDraftRecord];
        recoveredDraftRecord.forEach((recoveredDraft, index) => {
          const recoveredData = draftData.data.find(data => data.build_id === recoveredDraft.draftId);
          if (recoveredData) {
            updatedDraftRecord[index] = {
              ...recoveredDraft,
              data: recoveredData.frontend_data,
              edit_mode: recoveredData.frontend_data.edit_mode,
            }
          }
        });
        setDraftRecord(updatedDraftRecord);
      }
    }, [draftData?.data, recoveredDraftRecord]);


    return (
      <DraftContext.Provider value={{
          draftRecord, setDraftRecord, 
          currentDraft, setCurrentDraft,
          handleEditSavedDraft, 
          handleEditCurrentDraft,
          handleDraftSave,
          handleDraftDelete,
          handleDraftSelect,
          handleDraftRecovery,
          handleDraftTemplate,
          currentSaved,
          inCartCount
        }}
      >
          {children}
      </DraftContext.Provider>
    );
  };
  
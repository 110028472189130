import { useEffect, useState, useContext} from "react";

import { ABSContext } from "../../../../../contexts/ABSContext";

import usePostData from "../../../../../hooks/usePostData";
import useImageUrl from "../../../../../hooks/useImageUrl";

import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

const MapThumbnail = ({colormapData, legendLayout, nbColors}) => {

    const [thumbnailImageUrl, setThumbnailImageUrl] = useState(null);
    const [mapThumbnailId, getMapThumbnailId] = usePostData();

    useEffect(() => {

        colormapData && legendLayout && nbColors && getMapThumbnailId('api/map_thumbnail', {
            'map_params': colormapData, 
            'legend_layout': legendLayout || 'Bottom Small',
            'nb_colors': nbColors,
        });
    }, [colormapData, nbColors, legendLayout])

    const { imagesContainerClient } = useContext(ABSContext);
    const {imageLoading} = useImageUrl(mapThumbnailId?.data?.thumbnail_id, imagesContainerClient, setThumbnailImageUrl);

    return (
        <div style={{position: "relative"}}>
            { imageLoading ?
                <div style={{position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <Spinner size="md" variant="light"/>
                </div>:
                <Image src={thumbnailImageUrl} thumbnail/> 
            }
                
        </div>
    )
}

export default MapThumbnail;
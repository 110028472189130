import { useState, useEffect, useContext } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import useFetchData from '../../../hooks/useFetchData';
import { AuthContext } from '../../../contexts/AuthContext'; 

import { Cloud } from 'react-bootstrap-icons';
import { Spinner } from 'react-bootstrap';

const ModalHttpError = ({show, setShow, error, onClose}) => {

    const[title, setTitle] = useState('');
    const[message, setMessage] = useState('');

    const { requestToken } = useContext(AuthContext);

    useEffect(() => {

        const communications = {
            '401': {
                'title': 'Unauthorized Session',
                'message': 'Your session timed out.'
            },
            '429': {
                'title': 'Too many requests',
                'message': 'Please wait a few seconds before trying again.'
            }, 
            '500': {
                'title': 'Server Error',
                'message': 'Please try again later.'
            },
            '503': {
                'title': 'Service Unavailable',
                'message': 'Please try again later.'
            },
            'default': {
                'title': 'Oops... Something went wrong',
                'message': 'Please try again later!'
            }
        }

        if (error) {
            const status_code = error.status && error.status.toString();
            if (Object.keys(communications).includes(status_code)) {
                setTitle(communications[status_code].title);
                setMessage(error.detail ? error.detail : communications[status_code].message);
            }
            else {
                setTitle(communications.default.title);
                setMessage(error.detail ? error.detail : communications.default.message);
            }
        }
    }, [error]);

    const handleClose = () => {
        setShow(false);
        if (onClose) {onClose(); }
    }; 

    const [token, getToken] = useFetchData();
    const handleAuth = () => {
        localStorage.removeItem('session_token');
        requestToken();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Cloud className='me-2'/>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {token?.isLoading && <div className="w-100 h-100" style={{position: "relative"}}>
                    <Spinner animation="border" role="status"
                        className='d-flex justify-content-center align-items-center' 
                        style={{position: "absolute", left: "50%", right: "50%"}}/>
                </div>}
                <p>{message}</p>
                {error && <p>
                    <strong className='me-2'>[{error.status}]</strong>
                    {error.message}
                </p>}
            </Modal.Body>
            <Modal.Footer>
                <div className="w-100 d-flex flex-row row-wrap gap-3">
                    {error && error.status === 401 && 
                    <Button className="w-100" variant="secondary" onClick={handleAuth}>Reconnect</Button>}
                    <Button className="w-100" variant="secondary" onClick={handleClose}>Close</Button>
                </div>
            </Modal.Footer>
      </Modal>
    )
};

export default ModalHttpError;
import { useState, useCallback } from 'react';

const useFetchData = () => {

    const [res, setRes] = useState({data: null, error: null, isLoading: false});
    
    const callApi = useCallback(async (endpoint, source = 'API') => {

        setRes(prevState => ({...prevState, isLoading: true}));

        const API_URL = source === 'API' ? 
            process.env.REACT_APP_API_URL : 
            process.env.REACT_APP_AF_URL;

        try {
            const response = await fetch(API_URL + '/' + endpoint, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('session_token')}`,
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                mode: "cors",
            });

            if (!response.ok) {
                const error = await response.json();
                throw error;
            }

            const text = await response.text();
            const data = text ? JSON.parse(text) : null;

            setRes({ data, isLoading: false, error: null });

        } catch (error) {
            
            setRes({ data: null, isLoading: false, error });
        }
    }, []);

    return [res, callApi];
}

export default useFetchData;

import { useContext } from 'react';
import {DraftContext} from '../../../contexts/DraftContext';

import NavigationBar from './NavigationBar';

import CartReview from '../cart/CartReview';
import InfoPanel from '../cart/InfoPanel';
import SavedDrafts from '../cart/SavedDrafts';
import OrderSummary from '../cart/OrderSummary';

import CartTemplate from '../../templates/cart/CartTemplate';

const Cart = () => {

    // [PRODUCTION] Azure Blob Storage
    const { draftRecord, setDraftRecord } = useContext(DraftContext);

    const handlePosterSizeChange = (value, id) => {
        setDraftRecord(draftRecord.map(draft => draft.draftId === id ? {...draft, posterSize: value} : draft));
    }

    const handleCartRemove = (id) => {
        setDraftRecord(draftRecord.map(draft => draft.draftId === id ? {...draft, inCart: false} : draft));
    }

    const handleCartAdd = (id) => {
        setDraftRecord(draftRecord.map(draft => draft.draftId === id ? {...draft, inCart: true} : draft));
    }

    const cartReview = <CartReview 
        handlePosterSizeChange={handlePosterSizeChange}
        handleCartRemove={handleCartRemove}
        draftRecord={draftRecord}
    />

    const savedDrafts = <SavedDrafts
        handleCartAdd={handleCartAdd}
        draftRecord={draftRecord}
    />

    const infoPanel = <InfoPanel/>

    const orderSummary = <OrderSummary
        draftRecord={draftRecord}
    />

    return (
        <>
            <NavigationBar/>
            <CartTemplate
                cartReview={cartReview}
                savedDrafts={savedDrafts}
                infoPanel={infoPanel}
                orderSummary={orderSummary}
            />
        </>
    );
};

export default Cart;
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Button from 'react-bootstrap/Button';

import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';


const AccordionToggle = ({eventKey, callback}) => {

  const { language } = useContext(LanguageContext);

  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button
      style={{ 
        background: "none", 
        border: "none", borderRadius: "0", 
        padding:"0",
        color: isCurrentEventKey ? "var(--color-1)": "var(--color-3)",
        margin: "0 0 0 0",
        cursor: "pointer", 
    }}
        className="control-settings__card-toggle"
        onClick={decoratedOnClick}
        size = "sm"
    >
      {isCurrentEventKey ? 
        {'EN': 'Hide', 'FR': 'Masquer'}[language] : 
        {'EN': 'Edit', 'FR': 'Éditer'}[language]
      }
    </Button>
  );
}

export default AccordionToggle; 
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import useFetchData from '../../../hooks/useFetchData';

import {CheckoutContext} from '../../../contexts/CheckoutContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';

import { Row, Col, Badge, Button, Form} from 'react-bootstrap';

import PricingSummary from '../utils/PricingSummary';

import { Calculator } from 'react-bootstrap-icons';

const OrderSummary = ({draftRecord}) => {

    // -- Contexts --
    const {checkoutData, setCheckoutData, promotion, handleRequestPromo} = useContext(CheckoutContext);
    const {language} = useContext(LanguageContext);
    const {theme} = useContext(ThemeContext);

    // -- Navigation Logic -- 
    const navigate = useNavigate();
    const handleCheckout = () => {
        setCheckoutData({order: orderSummary.data, ready: false})
        navigate('/checkout');
    };

    const handleRedeemPromo = () => {
        handleRequestPromo(promoCodeTextInput);
        setPromoCode(promoCodeTextInput);
        setPromocodeEdit(false);
    };

    // PROMO LOGIC 
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeTextInput, setPromoCodeTextInput] = useState('');
    const [promocodeEdit, setPromocodeEdit] = useState(false);

    // PRICING LOGIC - Fetch Order Price from API
    const [orderSummary, getOrderSummary] = useFetchData();
    useEffect(() => {
        const queryString = new URLSearchParams({
            promocode: promotion?.data?.code ? promotion.data.code : '',
            A1: draftRecord.filter(d => d.inCart && d.posterSize === 'A1').map(d => d.draftId).join('|'), 
            A2: draftRecord.filter(d => d.inCart && d.posterSize === 'A2').map(d => d.draftId).join('|'), 
            B2: draftRecord.filter(d => d.inCart && d.posterSize === 'B2').map(d => d.draftId).join('|'), 
        }).toString();
        getOrderSummary(`api/order_summary?${queryString}`);

    }, [draftRecord, promotion.data])

    return (
        <>
        <div className='summary-section-title'>
            <Calculator className='me-2' style={{fontSize: "1em"}}/>
            {{'EN': 'Order Summary', 'FR': 'Récapitulatif'}[language]}
        </div>
        <div className='px-2 summary-section summary-section--order'>
            <Row className="mt-2">
                <Col className='secondary-text'>
                    {draftRecord.filter(draft => draft.inCart).filter(draft => draft.posterSize === 'A2').length > 0 &&
                    <div className="mb-1" style={{
                        display: "flex", 
                        justifyContent: "space-between"
                    }}>
                        <div className="px-2 mt-2">
                            <Badge bg='secondary'>
                                {draftRecord.filter(draft => draft.inCart).filter(draft => draft.posterSize === 'A2').length}
                            </Badge>
                            <>{' '}x{' '}</>
                            <>A2 Poster</>
                        </div>
                    </div>}
                    {draftRecord.filter(draft => draft.inCart).filter(draft => draft.posterSize === 'A1').length > 0 &&
                    <div className="mb-1" style={{
                        display: "flex", 
                        justifyContent: "space-between"
                    }}>
                        <div className="px-2 mt-2"> 
                            <Badge bg="secondary">
                                {draftRecord.filter(draft => draft.inCart).filter(draft => draft.posterSize === 'A1').length}
                            </Badge>                  
                            <>{' '}x{' '}</>
                            <>A1 Poster</>
                        </div>
                    </div>}
                </Col>
                <Col className="w-100"> 
                    {promocodeEdit ? <>
                    <Form.Control onChange={e => setPromoCodeTextInput(e.target.value)} 
                        value={promoCodeTextInput} size="sm" placeholder="PROMO CODE" name="promocode"/>
                    <Button 
                        className='w-100'
                        variant="secondary"
                        size="sm"
                        onClick={handleRedeemPromo}
                    >{{'EN': 'Redeem', 'FR': 'Valider'}[language]}</Button></>:<>
                    <Button
                        className='w-100'
                        variant="secondary"
                        size="sm"
                        onClick={() => setPromocodeEdit(true)}
                    >{{'EN': 'Add promocode', 'FR': 'Ajouter code promo'}[language]}</Button>
                    {promotion.data &&<div 
                        className='secondary-text'
                        style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "3px", fontStyle: "italic", fontSize: "0.8em"}}
                    >{promotion.data.message}</div>}
                    </>
                    }
                </Col>
            </Row>
            {promotion.data && <div style={{display: "flex", justifyContent: "center", gap: "1em", color: "var(--green-light)"}}>
                <Badge bg="success">{promotion.data.code}</Badge>
                {promotion.data.text}
            </div>}
            {orderSummary && <PricingSummary orderData={orderSummary.data} theme={null} lang={language}/>}
        </div>
        <Button
            className='button-checkout'
            style={{
                boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
                borderRadius: "0px 0px 10px 10px",
                backgroundColor: "var(--green-light)",
                borderColor: "var(--green-light)"
            }}
            onClick = {handleCheckout}
            disabled = {draftRecord.filter(draft => draft.inCart).length === 0}
        >
            {{'EN': 'Checkout', 'FR': 'Paiement'}[language]}</Button>
        </>
    );
};

export default OrderSummary;
import Reference from '../../../../assets/svg/BasketballBallOutline.svg'

const PosterSizeChoice = ({posterSize, setPosterSize, handleAddToCart}) => {

    const multisize = false;

    return (
        <>
        {multisize &&
        <div className="psc-container">
            <div className="psc-inner-container">
                <div 
                    className={"psc-box" + (posterSize === 'A1' ? " psc-box--selected" : "")} 
                    onClick={() => setPosterSize('A1')}
                >
                    <div className="psc-box-label">
                        <div className="psc-box-label__dimensions">594 x 841</div>
                        <div className="psc-box-label__name">A1</div>
                    </div>
                </div>
                <div 
                    className={"psc-small-box" + (posterSize === 'A2' ? " psc-small-box--selected" : "")} 
                    onClick={() => setPosterSize('A2')}
                >
                    <div className="psc-box-label">
                        <div className="psc-box-label__dimensions">420 x 594</div>
                        <div className="psc-box-label__name">A2</div>
                    </div>
                </div>
                {false && <div className="psc-reference">
                    <img className="w-100" src={Reference} alt="Size Reference"/>
                </div>}           
            </div>
        </div>}
        </>
    )
};

export default PosterSizeChoice
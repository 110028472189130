import { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ChatRightTextFill, Envelope } from 'react-bootstrap-icons';
import usePostData from '../../../hooks/usePostData';

const ModalContact = ({ subject, email, show, setShow, showSource}) => {

    const { language } = useContext(LanguageContext);

    const [contact, postContact] = usePostData();
    const [subjectField, setSubjectField] = useState(subject);
    const [emailField, setEmailField] = useState(email);
    const [contentField, setContentField] = useState('');

    const [errors, setErrors] = useState({}); 

    const handleClose = () => setShow(false);

    const handleSend = () => {
        const newErrors = {};

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailField || !emailRegex.test(emailField)) {
            newErrors.email = { EN: 'Please enter a valid email', FR: 'Veuillez entrer un email valide' }[language];
        }
        else if (emailField.length >= 100) {
            newErrors.email = { EN: 'Email is too long', FR: 'Email trop long' }[language];
        }

        // Validate subject
        if (!subjectField) {
            newErrors.subject = { EN: 'Subject is required', FR: 'Le sujet est requis' }[language];
        }
        else if (subjectField.length >= 100) {
            newErrors.subject = { EN: 'Subject is too long', FR: 'Sujet trop long' }[language];
        }

        // Validate content/message
        if (!contentField) {
            newErrors.content = { EN: 'Message is required', FR: 'Le message est requis' }[language];
        }
        else if (contentField.length >= 500) {
            newErrors.content = { EN: 'Message is too long', FR: 'Message trop long' }[language];
        }

        // If there are errors, stop form submission
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // If no errors, send the contact data
        postContact('api/contact', {
            source: showSource,
            subject: subjectField,
            email: emailField,
            content: contentField,
            session_token: localStorage.getItem('session_token'),
        });

        // Clear the fields after submission
        setContentField('');
        setEmailField('');
        setSubjectField('');
        setErrors({}); // Clear any previous errors
        handleClose();
    };

    const handleChangeSubjectField = (value) => {
        value.length <= 100 && setSubjectField(value);
    };

    const handleChangeEmailField = (value) => {
        value.length <= 100 && setEmailField(value);
    };

    const handleChangeContentField = (value) => {
        value.length <= 500 && setContentField(value);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <ChatRightTextFill className='me-2' />
                    {{ EN: 'Contact', FR: 'Contacter' }[language]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <Form.Label className='section-formlabels'>
                        {{ EN: 'Email', FR: 'Email' }[language]}
                    </Form.Label>
                    <Form.Control
                        value={emailField}
                        size="sm"
                        placeholder={{ EN: 'Your email address', FR: 'Votre adresse email' }[language]}
                        aria-label="Contact Email"
                        name="Contact Email"
                        onChange={(e) => handleChangeEmailField(e.target.value)}
                        isInvalid={!!errors.email} // Highlight field if invalid
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                </>
                <>
                    <Form.Label className='section-formlabels'>
                        {{ EN: 'Subject', FR: 'Sujet' }[language]}
                    </Form.Label>
                    <Form.Control
                        value={subjectField}
                        size="sm"
                        placeholder={{ EN: "What's up?", FR: 'Intitulé' }[language]}
                        aria-label="Contact Subject"
                        name="Contact Subject"
                        onChange={(e) => handleChangeSubjectField(e.target.value)}
                        isInvalid={!!errors.subject} // Highlight field if invalid
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.subject}
                    </Form.Control.Feedback>
                </>
                <>
                    <Form.Label className='section-formlabels'>
                        {{ EN: 'Message', FR: 'Message' }[language]}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="5"
                        value={contentField}
                        size="sm"
                        aria-label="Contact Message"
                        name="Contact Message"
                        onChange={(e) => handleChangeContentField(e.target.value)}
                        isInvalid={!!errors.content} // Highlight field if invalid
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.content}
                    </Form.Control.Feedback>
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={handleSend}>
                    {{ EN: 'Send', FR: 'Envoyer' }[language]}
                    <Envelope className='ms-2 mb-1' />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalContact;

import Button from 'react-bootstrap/Button';

import { useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';

import { Image, Badge } from 'react-bootstrap';
import heart from '../../../assets/images/divers/heart.png';

import ModalContact from '../utils/ModalContact';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { DraftContext } from '../../../contexts/DraftContext';
import { DeviceContext } from '../../../contexts/DeviceContext';

import { Spinner } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import { ArrowReturnRight, CartFill, ChatRightTextFill, ExclamationCircleFill, PiggyBankFill } from 'react-bootstrap-icons';

const PaymentCompletion = () => {

  const {language} = useContext(LanguageContext);
  const {theme} = useContext(ThemeContext);
  const {mobile} = useContext(DeviceContext);
  const { handleDraftRecovery } = useContext(DraftContext);

  const [order, loadOrder] = useFetchData();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const paymentIntent = queryParams.get('payment_intent');
  const order_status = queryParams.get('redirect_status');

  const [contactShow, setContactShow] = useState(false);
  const [email, setEmail] = useState('');
  const subject = `Order Inquiry: ${paymentIntent?.split('_')[1]}`;

  useEffect(() => {
    paymentIntent && loadOrder(`api/order_items/${paymentIntent}`);
  }, [paymentIntent]);

  useEffect(() => {
    if (order_status !== 'succeeded' && order.data?.items) {
      handleDraftRecovery(order.data.items);
    }
    order.data?.contact_email && setEmail(order.data.contact_email);
  
  }, [order_status, order.data]);

  return (
    <>
    <NavigationBar/>
    <div 
      className="d-flex flex-column justify-content-center align-items-center"
      style={{height: "92vh", maxHeight: "92vh", overflow: "hidden"}}
    >
      {order ? 
        <div 
          className='p-2 d-flex flex-column justify-content-between align-items-center'
          style={mobile ? 
            {
              height: "90%", maxHeight: "90%", height: "90%", width: "95%",
              borderRadius: "10px", boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "var(--bg-color-3)",
            }:
            {
              height: "80%", minWidth: "40%", maxWidth: "40%",
              borderRadius: "10px", boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "var(--bg-color-3)", 
          }}
        >
          {!order.data?.items ? 
            <Image  src={heart} className='p-5'style={{width: "20em", height: "20em", marginBottom: "1em"}}/>:
            <div 
              className={`d-flex flex-row gap-2 justify-content-${mobile ? 'start' : 'center'}`} 
              style={mobile ? 
                {height: "60%", maxWidth: "95vw", overflowX: "scroll", padding: "10%"} : 
                {height: "60%", width: "100%"}
              }
            >
              {order.data.items.map( (item, index) => {
              return (
                <div className='d-flex flex-column align-items-center' key={`pc-${index}`}>
                  <Image 
                    className='m-2 dynamic-image'
                    key={'cart-review' + item.id}
                    rounded
                    src={item.build_image_blob_url}
                    style={{height: "80%"}}
                  />
                  <div 
                    style={{color: 'var(--color-2)', textAlign: "center"}}>
                    <Badge bg={theme==='light'?'secondary':'dark'}>{item.size} Poster</Badge>
                  </div>
                </div>
              )})}
            </div>
          }
          <div className='p-2 mb-3'>
            <div style={{color: "var(--color-1)", fontSize: "1.5em", fontWeight: "500", textAlign: "center"}}>
              {{
                'EN': order_status === 'succeeded' ? 
                  <>Payment Complete<PiggyBankFill className='ms-2 mb-1'/></>: 
                  <>Payment Failed<ExclamationCircleFill className='ms-2 mb-1'/></>,
                'FR': order_status === 'succeeded' ?
                  <>Paiement Terminé<PiggyBankFill className='ms-2 mb-1'/></>:
                  <>Paiement Echoué<ExclamationCircleFill className='ms-2 mb-1'/></>
              }[language]}
            </div>
            <div style={{color: "var(--color-2)" }}>
              {{
                'EN': <>
                  <li>Contact email: {email}</li>
                  <li>Order id: <strong>{paymentIntent?.split('_')[1]}</strong></li>
                </>,
                'FR': <>
                  <li>Email de contact: {email}</li>
                  <li>Numéro de commande: <strong>{paymentIntent?.split('_')[1]}</strong></li>
                </>
              }[language]}
            </div>
          </div>
          <div className={`w-100 d-flex flex-${mobile ? 'column':'row'} gap-2`}>
            {order_status === 'succeeded' ? <Button 
                variant={theme === 'light' ? 'outline-secondary' : 'dark'}
                className='w-100'
                onClick={() => navigate('/laboratory')}
            > 
              <ArrowReturnRight className='me-2'/>
              {{'EN': 'Back to the Editor', 'FR': 'Retour à l\'éditeur'}[language]}
            </Button>:
            <Button 
                variant={theme === 'light' ? 'outline-secondary' : 'dark'}
                className='w-100'
                onClick={() => navigate('/cart')}
            > 
              <CartFill className='me-2'/>
              {{'EN': 'Back to Cart', 'FR': 'Retour au panier'}[language]}
            </Button>}
            <Button 
                variant={theme === 'light' ? 'outline-secondary' : 'dark'}
                className='w-100'
                onClick={() => setContactShow(true)}
            >
              <ChatRightTextFill className='me-2'/>
              {{'EN': 'Contact', 'FR': 'Contact',}[language]}
            </Button>
          </div>
        </div>
        :
        <div 
          className='p-5 d-flex flex-column justify-content-center align-items-center'
          style={{
            borderRadius: "10px", boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
            backgroundColor: "var(--bg-color-3)", 
          }}
        >
          <div style={{
            color: "var(--color-1)",
            fontSize: "1.5em",
            fontWeight: "500",
            marginBottom: "1em", 
          }}>
            {{
              'EN': 'Please wait while we process your order...',
              'FR': 'Veuillez patienter ...',
            }[language]}
          </div>
          <Spinner animation="border" role="status"/>
      </div>
      }
    </div>
    <ModalContact subject={subject} email={email} showSource={'Order'} show={contactShow} setShow={setContactShow}/>
    </>
  );
};

export default PaymentCompletion;

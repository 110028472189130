import { useContext } from "react";
import { DeviceContext } from "../../../contexts/DeviceContext";

import ToolsTemplateDefault from "./ToolsTemplateDefault";
/*import ToolsTemplateMobile from "./ToolsTemplateMobile";*/
import ToolsTemplateMobile from "./ToolsTemplateMobile_v2";

const ToolsTemplate = ({
    fileName,
    viewPort,
    loadDraftDropdownDefault,
    loadDraftDropdownMobile,
    buttonDelete,
    buttonRenaming,
    buttonSave,
    buttonToggleCart,
    buttonToCart,
    buttonToSettings,
    posterSizeToggleChoice,
    posterSizeChoice
}) => {

    const { mobile } = useContext(DeviceContext);

    return (
        <>
        {mobile ? 
            <ToolsTemplateMobile
                fileName={fileName}
                viewPort={viewPort}
                loadDraftDropdown={loadDraftDropdownMobile}
                buttonDelete={buttonDelete}
                buttonRenaming={buttonRenaming}
                buttonSave={buttonSave}
                buttonToggleCart={buttonToggleCart}
                buttonToCart={buttonToCart}
                buttonToSettings={buttonToSettings}
                posterSizeToggleChoice={posterSizeToggleChoice}

            />:
            <ToolsTemplateDefault
                fileName={fileName}
                viewPort={viewPort}
                loadDraftDropdown={loadDraftDropdownDefault}
                buttonDelete={buttonDelete}
                buttonRenaming={buttonRenaming}
                buttonSave={buttonSave}
                buttonToggleCart={buttonToggleCart}
                buttonToCart={buttonToCart}
                posterSizeChoice={posterSizeChoice}
            />
        }     
        </>
    );
};

export default ToolsTemplate;
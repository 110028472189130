import {useContext} from 'react';
import { useNavigate } from 'react-router-dom';

import me from "../../../assets/images/divers/itsme.png";

import { LanguageContext } from "../../../contexts/LanguageContext";
import { ThemeContext } from "../../../contexts/ThemeContext";

import { Button } from "react-bootstrap";

import { RocketTakeoffFill } from "react-bootstrap-icons";

const AboutContent = () => {

    const {language} = useContext(LanguageContext);
    const {theme} = useContext(ThemeContext);

    const navigate = useNavigate();

    return (
        <>
            <img 
                src={me} alt="Me" 
                style={{
                    width: "200px", height: "200px", display: "block", 
                    borderRadius: "50%", objectFit: "cover", 
                    borderColor: "var(--color-3)", border: "2px solid",
                    marginBottom: "1em", backgroundColor: "var(--bg-color-1)",
                    marginLeft: "auto",  marginRight: "auto", marginBottom: "1.5em"
                }} 
            />
            {{
                'EN':<>
                    <p>
                        Hi ! My name is Nathan, I am an engineer with a master's degree in data-science and robotics, 
                        deeply interested in software engineering and creative coding. 
                        I started playing around with generative code a few years ago. 
                        Over the past few months, I have been working on this project
                        with the objective of making my mosaik algorithm accessible through a web interface.  
                    </p><hr style={{ width: "50%", margin: "0.5em auto 1em auto" }}/>
                    <p>
                        This mosaik project began with a simple 2D mosaic algorithm using a few parameters, 
                        then gradually expanded to offer more options and transitioned into 3D elements. 
                        There is no AI involved in the process, the algorithm simply uses functions to interact with the pixels values.
                    </p><hr style={{ width: "50%", margin:  "0.5em auto 1em auto" }}/>
                    <p>
                        What I find interesting about generative code is designing a set of tools and a framework without controling the final outcome.
                        Anyone can use the interface, play around with the parameters and take the algorithm to a different direction depending on their own taste.
                        I did design a tool and a general creative direction, but anyone can use it in a different way and create their own interpretation.  
                    </p><hr style={{ width: "50%", margin:  "0.5em auto 1em auto" }}/>
                    <p>
                        Without a user friendly interface, the algorithm was only accessible through python code. 
                        I developed this online webapp for people to interact with the code in a more intuitive way.
                        <strong>I hope you have fun using it!</strong>
                    </p><hr style={{ width: "50%", margin:  "0.5em auto 1em auto" }}/>
                </>,
                'FR':<>
                    <p>
                        Bonjour et bienvenue ! Je m'appelle Nathan, je suis ingénieur en data-science et robotique et passioné par 
                        le code génératif et le développement d'applications. 
                        J'ai commencé à utiliser du code pour créer des images il y a quelques années.  
                        Depuis quelques mois, je travaille sur cette application dans le but de rendre mon algorithme 
                        de mosaiques accessible via une interface.
                    </p><hr style={{ width: "50%", margin: "0.5em auto 1em auto" }}/>
                    <p>
                        Ce projet a commencé avec un algorithme basique pour construire des mosaïques 2D à partir d'un petit ensemble de paramètres 
                        et s'est progressivement étendu pour offrir plus d'options et des éléments en 3D.
                        Il n'y a pas d'IA impliquée dans le processus, l'algorithme utilise simplement des fonctions pour interagir avec les valeurs des pixels.
                    </p><hr style={{ width: "50%", margin:  "0.5em auto 1em auto" }}/>
                    <p>
                        Ce que je trouve intéressant dans le code génératif, c'est de concevoir un ensemble d'outils et un cadre sans contrôler le résultat final. 
                        Tout le monde peut utiliser l'interface, jouer avec les paramètres et amener l'algorithme dans une direction différente.
                        J'ai conçu un outil et une "direction artistique", mais l'idée c'est que chacun puisse en faire sa propore interpretation.
                    </p><hr style={{ width: "50%", margin:  "0.5em auto 1em auto" }}/>
                    <p>
                        Sans interface utilisateur, l'algorithme n'était accessible que via du code python. 
                        J'ai développé cette webapp pour offrir une manière plus intuitive d'interagir avec le code. <strong>Amusez vous bien!</strong>
                    </p><hr style={{ width: "50%", margin:  "0.5em auto 1em auto" }}/>
                </>
            }[language]}
            <Button
                className="w-100 px-5 mb-3"
                onClick={() => navigate('/laboratory')}
                variant={theme === 'dark' ? 'outline-light' : 'outline-dark'}
            >
                <RocketTakeoffFill className='me-2'/>
                {{'EN': 'Start Making', 'FR': 'Commencer à créer'}[language]}
            </Button>
        </>
    );
};

export default AboutContent;
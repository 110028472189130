import { useEffect, useState, useContext } from "react";

import {APIProvider} from "@vis.gl/react-google-maps";

import { DeviceContext } from "../../../../contexts/DeviceContext";
import { LanguageContext } from "../../../../contexts/LanguageContext";

import GoogleMap from "./GoogleMap";
import ServicePointsInfo from "./ServicePointsInfo";
import PlacesAutocomplete from "./GooglePlacesAutocomplete";

import useFetchData from "../../../../hooks/useFetchData";


const AddressMap = ({
    selectedSP, setSelectedSP, 
    setRefAddress, warningSelectedSP,
    weight, orderUtils
}) => {
    
    const {language} = useContext(LanguageContext);

    const defaultPos = { lat: 47.2378, lng: 6.0241};
    const [validCountry, setValidCountry] = useState(true);
    
    // Selected Address
    const [selected, setSelected] = useState(null); // Reference Address
    const [servicePoints, getServicePoints] = useFetchData(); // Corresponding Service Points
    useEffect(() => {

        // Set Reference address 
        setRefAddress({
            'address': selected ? selected.route : '1 rue de la paix',
            'postal_code': selected ? selected.postal_code : '75001',
            'city': selected ? selected.city : 'Paris',
            'country_code': selected ? selected.country_code : 'FR',
        })

        setSelectedSP(null); // Reset Selected Service Point

        const deliveryCountries = orderUtils?.data ? orderUtils?.data.delivery_countries:['FR'];
        const valid = selected ? deliveryCountries.includes(selected.country_code) : true
        setValidCountry(valid);

        // Query String 
        const params = new URLSearchParams({
            country: selected ? selected.country_code : 'FR',
            latitude: selected ? selected.lat : defaultPos.lat,
            longitude: selected ? selected.lng : defaultPos.lng,
            radius: selected ? selected.lng : defaultPos.lng
        });
        const queryString = params.toString();

        valid && getServicePoints(`api/sendcloud/service-points/?${queryString}`);

    }, [selected]);

    // Address Lists 
    const [addressList, setAddressList] = useState([]);
    const [zoom, setZoom] = useState(15);
    useEffect(() => {
        servicePoints.data && setAddressList(servicePoints.data.service_points);
        servicePoints.data && setZoom(servicePoints.data.zoom);
    }, [servicePoints.data, setAddressList]);

    // Selected SP shipment methods
    const [shipmentMethods, getShipmentMethods] = useFetchData();
    useEffect(() => {
        if (!selectedSP) {return;}
        const totalWeight = weight ? weight : 490;
        const queryString = `service_point_id=${selectedSP.id}&country_code=${selectedSP.country}&weight=${totalWeight}`;
        selectedSP && selectedSP.id && getShipmentMethods(`api/sendcloud/service-points/shipment-methods/?${queryString}`);
    }, [selectedSP?.id]);

    useEffect(() => {
        shipmentMethods?.data && setSelectedSP(prev => ({...prev, shipment_methods: shipmentMethods.data}))
    }, [shipmentMethods?.data]);

    // Mobile context
    const { mobile } = useContext(DeviceContext);

    return (
        <div className="copo-container">
            <APIProvider 
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={["places"]}
            >
                <PlacesAutocomplete setSelected={setSelected}/>
                <div className={"px-1 py-1 copo-selection" + (mobile ? "--mobile" : "")}>
                    <div className={"copo-info-container" + (mobile ? "--mobile" : "")}>
                        <ServicePointsInfo
                            selectedSP={selectedSP}
                            warningSelectedSP={warningSelectedSP}
                            addressList={addressList}
                            validCountry={validCountry} 
                            orderUtils={orderUtils}
                            language={language}
                        />
                    </div>
                    <div style={{position: "relative"}}
                        className={"copo-map-container" + (mobile ? "--mobile" : "")}>  
                        <GoogleMap 
                            setSelectedSP={setSelectedSP} 
                            addressList={addressList} 
                            setAddressList={setAddressList}
                            selected={selected} 
                            defaultPos={defaultPos}
                            setSelected={setSelected}
                            zoom={zoom}
                            language={language}
                        />
                    </div >
                </div>
            </APIProvider>
        </div>
    );
};

export default AddressMap;


import { useContext, useEffect} from 'react';
import LabeledSelect from '../../../ui/LabeledSelect';

import {LanguageContext} from '../../../../../contexts/LanguageContext';
import tr from '../../../../../assets/translations/general.json';

import SeedSelect from './SeedSelect';

const Packing = (props) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
            <LabeledSelect
                label = {"Collection"}
                options = {props.options && props.options.collection}
                currentOption = {props.collection}
                handleSelect = {(value) => {props.handleUpdateParams('collection', value)}}
            />
            <LabeledSelect
                label = {"Size"}
                options = {props.options && props.options.size}
                currentOption = {props.size}
                handleSelect = {(value) => {props.handleUpdateParams('size', value)}}
            />
            <LabeledSelect
                label = {"Variance"}
                options = {props.options && props.options.variability}
                currentOption = {props.variability}
                handleSelect = {(value) => {props.handleUpdateParams('variability', value)}}
            />
            <SeedSelect
                currentInput={props.seed}
                handleChange={(value) => {props.handleUpdateParams('seed', value)}}
            ></SeedSelect>
        </>
    )
};

export default Packing;
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

import {CartFill} from 'react-bootstrap-icons';
import UniSizePoster from '../ui/SizeUniPoster';

const CartReview = ({handleCartRemove, handlePosterSizeChange, draftRecord}) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
            <div className='summary-section-title'>
                <CartFill className='me-2' style={{fontSize: "1em"}}/> {{'EN': 'Cart', 'FR': 'Panier'}[language]}
            </div>
            <div className='summary-section summary-section--cart px-3'>
            {draftRecord.filter(draft => draft.inCart).map((draft, index) => {
                return (
                    <UniSizePoster key={`unisizep-${index}`} draft={draft} handleCartRemove={handleCartRemove}/>
                    //<MultiSizePoster draft={draft} handlePosterSizeChange={handlePosterSizeChange} handleCartRemove={handleCartRemove}/>
                )
            })}
            </div>
        </>
    );
};

export default CartReview;
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownItem from "react-bootstrap/DropdownItem";
import Form from "react-bootstrap/Form";

import ColorTemplate from "../utils/ColorTemplate";
import ColorPill from "../utils/ColorPill";

//TEST
import {useEffect, useState, useContext} from "react";
import {LanguageContext} from "../../../contexts/LanguageContext";

const ColorSelect = ({label, handleSelect, palettesColors, options, currentColor}) => {

    const {language} = useContext(LanguageContext);

    const [currentOpt, setCurrentOpt] = useState();
    const [colorSubset, setColorSubset] = useState();

    useEffect(() => {
        palettesColors && setCurrentOpt(palettesColors.filter(
            item => item.palette === currentColor)[0])
    }, [currentColor, palettesColors])
    
    useEffect(() => {
        palettesColors && options && setColorSubset(
            palettesColors.filter(item => options.includes(item.palette)));
    }, [palettesColors, options])

    const empty_opt = {'palette': 'EMPTY', 'colors': ['#EAEAEA']}

    return (
        <>  
            {options && options.length > 1 && <>
            <Form.Label className="control-settings__form-label">{label}</Form.Label>
            <Dropdown>
                <Row>
                    <Col>
                        <DropdownToggle size="sm" variant="light" style={{width: "100%", alignContent: "left"}}>
                            {{'EN': 'Select', 'FR': 'Sélectionner'}[language]}
                        </DropdownToggle>
                        <Dropdown.Menu style={{width: "100%"}}>
                            <div className="w-100 m-0 d-flex flex-column flex-wrap justify-content-between"> 
                                {
                                    colorSubset && colorSubset.map( (opt) => (
                                        <DropdownItem
                                            key={opt.palette}
                                            className="color-select__dropdown-item"
                                            onClick={() => {handleSelect(opt.palette);}}
                                        >
                                            <ColorTemplate colors={opt} paletteName={opt.palette}/>
                                        </DropdownItem>   
                                    ))
                                }
                                {   options && options.includes('EMPTY') &&
                                    <DropdownItem
                                        key={empty_opt.palette}
                                        className="color-select__dropdown-item"
                                        onClick={() => {handleSelect(empty_opt.palette);}}
                                    >
                                        <ColorTemplate colors={empty_opt} paletteName={empty_opt.palette}/>
                                    </DropdownItem>  
                                }
                            </div>
                        </Dropdown.Menu>
                    </Col>
                    <Col>
                        <ColorPill palette={currentOpt} height={"2rem"} width={"100%"}/>
                    </Col>
                </Row>
            </Dropdown>
            </>}
        </>
    );
}

export default ColorSelect;
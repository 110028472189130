import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';

import { ArrowLeft, CartFill } from 'react-bootstrap-icons';

const ButtonToSettings = ({setPanel, handleGoToCart, lang, theme}) => {

    return (
        <div style={{backgroundColor: "var(--bg-color-2)"}}>
            <Row 
                className='w-100 py-2' 
                style={{
                    ...{position: "fixed", bottom: "0px",  height: "3.5em", borderTop: "2px solid var(--bg-color-4)"},
                    ...{backgroundColor: theme === 'dark' ? "rgba(40, 40, 40, 0.85)": "rgba(193, 200, 204, 0.85)"}
                }}
            >
                <Col className='d-flex justify-content-center'>
                    <Button 
                        className='w-100'
                        onClick={() => setPanel('control')} 
                        style={{
                            backgroundColor: "var(--color-4)",
                            border: '2px solid white', borderRadius:'100px', fontWeight: 'bold'
                        }}
                    >
                        <ArrowLeft className='me-2' style={{color: "white", fontWeight: "bold"}}/>{{'EN': 'Settings', 'FR': 'Paramètres'}[lang]}
                    </Button>
                </Col>
                <Col className='d-flex justify-content-center'>
                    <Button 
                        className='w-100'
                        onClick={() => handleGoToCart()} 
                        style={{
                            border: '2px solid white', borderRadius:'100px', fontWeight: 'bold',
                            backgroundColor: "var(--green-light)",
                        }}
                    >
                        {{'EN': 'Cart', 'FR': 'Panier'}[lang]}<CartFill className='ms-2'/> 
                    </Button>
                </Col>
            </Row>
        </div>

    );
};

export default ButtonToSettings;
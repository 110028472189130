import Button from 'react-bootstrap/Button';
import { BoxArrowDown } from 'react-bootstrap-icons';

const ButtonSave = ({handleDraftSave, disabled, theme, lang}) => {
    return (
        <Button
            className="px-3 button-with-icon"
            variant={theme}
            onClick={() => handleDraftSave()}
            disabled={disabled}
        >{{'EN': 'Save', 'FR': 'Sauvegarder'}[lang]}<BoxArrowDown/></Button>
    );
};

export default ButtonSave;
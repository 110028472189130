import { useEffect, useState, useContext } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import LabeledSelectInBlock from '../../ui/LabeledSelectInBlock';
import ElementSelect from '../../ui/ElementSelect';

import ColorPill from '../../utils/ColorPill';
import ColorSelect from '../../ui/ColorSelect';

import { LanguageContext } from "../../../../contexts/LanguageContext";

const BlockTemplatePortrait = ({
    blockId, mosaicData, handleSelect, options, utils
}) => {

    // Language
    const {language} = useContext(LanguageContext);

    const blockData = mosaicData.find(b => b.id === blockId);

    // Special Collections
    const [elementsSelection, setElementsSelection] = useState(false);
    const [elementsOptions, setElementsOptions] = useState();


    useEffect(() => {
        if (blockData?.collection) {
            utils && setElementsOptions(utils.collections_elements_dict[blockData.collection]);
            utils && setElementsSelection(utils.collections_special.includes(blockData.collection));
            handleSelect(blockId, 'elements', [0]);
        }
    }, [blockData?.collection]);

    // Current Color to put in header with added colors associated to it  
    const currentColorOpt = blockData?.color && utils?.color_portrait.filter(
        item => item.palette === blockData.color)[0];

    return (
        <Accordion defaultActiveKey="0" className="mb-2">
            <Card>
            <Card.Header className="pb-1 d-flex justify-content-between align-items-center control-settings__card-header">
                <div style={{display: "flex", alignItems: "center"}}>
                    <Card.Title size="sm" style={{marginBottom: "0", marginRight:"1rem", fontSize: "1rem", flexGrow: "1", color: "var(--color-2)"}}>
                        {blockData?.collection}
                    </Card.Title>
                    <ColorPill palette={currentColorOpt} height={"1rem"} width={"3rem"}/>
                </div>
            </Card.Header>
                <Card.Body className="control-settings__card-body">
                    <Row className='mb-1'>
                        <Col>
                            <LabeledSelectInBlock
                                parentBlockId = {blockId}
                                property = "size"
                                label={{EN: 'Elements Size', FR: 'Taille des élements'}[language]} 
                                handleSelect={handleSelect}
                                options={options && options.size}
                                currentOption={blockData?.size}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LabeledSelectInBlock
                                parentBlockId = {blockId}
                                property = "collection"
                                label={{EN: 'Collection', FR: 'Collection'}[language]} 
                                handleSelect={handleSelect}
                                options={options && options.collection}
                                currentOption={blockData?.collection}
                            />
                        </Col>
                    </Row>
                    {
                        elementsSelection && <ElementSelect
                            parentBlockId = {blockId}
                            label={{EN: 'Elements', FR: 'Eléments'}[language]}
                            handleSelect={handleSelect}
                            collection={blockData?.collection}
                            options={elementsOptions}
                            currentElements={blockData?.elements}
                        />
                    }
                    {
                        <ColorSelect
                            parentBlockId = {blockId} 
                            label={{EN: 'Color', FR: 'Couleur'}[language]} 
                            handleSelect={(e) => handleSelect(blockId, 'color', e)}
                            palettesColors={utils?.color_portrait}
                            options={options && options.color}
                            currentColor={blockData?.color} 
                        />
                    }
                </Card.Body>
            </Card>
        </Accordion>
    )
}

export default BlockTemplatePortrait;   
import { useContext} from 'react';
import LabeledSelect from '../../../ui/LabeledSelect';

import SeedSelect from './SeedSelect';

import {LanguageContext} from '../../../../../contexts/LanguageContext';
import tr from '../../../../../assets/translations/general.json';

const Grid = (props) => {

    // ---- LANGUAGE ----
    const {language} = useContext(LanguageContext);

    return (
        <>
            <LabeledSelect
                label = {tr.layout.depth[language] || "Depth"}
                options = {props.options && props.options.depth}
                currentOption = {props.depth}
                handleSelect = {(value) => {props.handleUpdateParams('depth', value)}}
            />
            <LabeledSelect
                label = {tr.layout.grid_cells[language] || "Grid cells"}
                options = {props.options && props.options.size}
                currentOption = {props.size}
                handleSelect = {(value) => {props.handleUpdateParams('size', value)}}
            />
            <SeedSelect
                currentInput={props.seed}
                handleChange={(value) => {props.handleUpdateParams('seed', value)}}
            />
        </>
    )
};

export default Grid;
import { useContext } from 'react'; 

import { DraftContext } from '../../../../contexts/DraftContext';
import { UtilsContext } from '../../../../contexts/UtilsProvider';
import { DeviceContext } from '../../../../contexts/DeviceContext';

import { CartDash, CartPlus } from 'react-bootstrap-icons';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import { Star, ArrowsAngleExpand } from 'react-bootstrap-icons';

const ButtonToggleCart = ({inCart, handleAddToCart, disabled, theme, lang}) => {

    const {currentDraft} = useContext(DraftContext);
    const {mobile} = useContext(DeviceContext);

    const { imageBankUrlsKeys } = useContext(UtilsContext);

    return (
        <>{mobile? 
        <Button
            className={`px-3 button-with-icon w-100 ${!inCart ? 'button-in-cart' : 'button-not-in-cart'}`}
            onClick={handleAddToCart} disabled={disabled}
        >   
            {inCart ? 
                {'EN': 'Remove from Cart', 'FR': 'Retirer du Panier'}[lang] : 
                {'EN': 'Add to Cart', 'FR': 'Ajouter au Panier'}[lang]}
            {inCart ? <CartDash/> : <CartPlus/>}
        </Button> :
        <Card className='w-100' bg={theme} style={{color: "var(--color-1)", border: "2px solid var(--bg-color-3)"}}>
            {currentDraft.thumbnail_blob_url ? <Card.Img
                src={currentDraft?.thumbnail_blob_url} 
                alt="Poster" style={{height: "auto", width: "100%"}}>
            </Card.Img>: imageBankUrlsKeys && <Card.Img
                src={imageBankUrlsKeys['general_base']}
                alt="Poster" style={{height: "auto", width: "100%"}}>
            </Card.Img>}
            <ListGroup variant="flush" bg="dark" style={{color: "var(--color-3)"}}>
                <div style={{color: 'var(--color-2)', borderBottom: "solid 1px var(--color-3)"}} className="d-flex justify-content-between align-items-center py-1 px-2">
                    <Star/><div style={{fontSize: "small"}}>170g/m² Paper</div>
                </div>
                <div style={{color: 'var(--color-2)', borderBottom: "solid 0px var(--color-3)"}} className="d-flex justify-content-between py-1 px-2">
                    <ArrowsAngleExpand className='me-2'/><div style={{fontSize: "small"}}>B2 (50cm/70cm)</div>
                </div>
            </ListGroup>
            <Button
                className={`px-3 button-with-icon w-100 ${!inCart ? 'button-in-cart' : 'button-not-in-cart'}`}
                onClick={handleAddToCart}
                disabled={disabled}
                style = {{borderRadius: "0px 0px 5px 5px"}}
            >   
                {inCart ? 
                    {'EN': 'Remove from Cart', 'FR': 'Retirer du Panier'}[lang] : 
                    {'EN': 'Add to Cart', 'FR': 'Ajouter au Panier'}[lang]}
                {inCart ? <CartDash/> : <CartPlus/>}
            </Button>
        </Card>
        }</>
    );
};

export default ButtonToggleCart;
import React from 'react';

import ControlTemplateDefault from "./ControlTemplateDefault";
import ControlTemplateMobile from "./ControlTemplateMobile";

import { useContext } from "react";
import { DeviceContext } from "../../../contexts/DeviceContext";

const ControlTemplate = (props) => {

    const { mobile } = useContext(DeviceContext);

    return (
    <>
        <>
        {mobile ? 
        <ControlTemplateMobile 
            randomGenSwitch={props.randomGenSwitch}
            editModeSelect={props.editModeSelect}
            randomGen={props.randomGen}
            randomGenActive={props.randomGenActive}
            modeAbstract={props.modeAbstract}
            modePortrait={props.modePortrait}
            editMode={props.editMode}
            helpButton={props.helpButton}
            runButtonClick={props.runButtonClick}
            runButtonDisabled={props.runButtonDisabled}
            runButtonClass={props.runButtonClass}
            setPanel={props.setPanel__mobile}
        />
        :      
        <ControlTemplateDefault
            randomGenSwitch={props.randomGenSwitch}
            editModeSelect={props.editModeSelect}
            randomGen={props.randomGen}
            randomGenActive={props.randomGenActive}
            modeAbstract={props.modeAbstract}
            modePortrait={props.modePortrait}
            editMode={props.editMode}
            helpButton={props.helpButton}
            runButtonClick={props.runButtonClick}
            runButtonDisabled={props.runButtonDisabled}
            runButtonClass={props.runButtonClass}
        />
        }
        </>
    </>
  );
};

export default ControlTemplate;

const PricingSummary = ({orderData, theme, lang}) => {

    const color1 = theme ?  (theme === 'light' ? '#555':'#EEE') : 'var(--color-1)' 
    const color2 = theme ?  (theme === 'light' ? '#777':'#CCC') : 'var(--color-2)'
    const color3 = theme ?  (theme === 'light' ? '#999':'#AAA') : 'var(--color-2)'
    
    const toDec = (num) => {
        return (num/100).toFixed(2)
    }

    return (
        <div className="px-0 mt-2" style={{color: color2, width: "100%"}}>
            <div className="px-1" style={{display: "flex", justifyContent: "space-between"}}>
                <div>{{'EN': 'Subtotal :', 'FR': 'Sous-total :'}[lang]}</div>
                {orderData && 
                <div style={{display: "flex", flexDirection: "row"}}>
                    {orderData.subtotal_after_discount !== orderData.subtotal_before_discount && <div style={{fontWeight: "700", marginRight: "1em"}}>{toDec(orderData.subtotal_after_discount)} € </div>}
                    <div style={orderData.subtotal_after_discount !== orderData.subtotal_before_discount ? {textDecoration: 'line-through'}:{}}>{toDec(orderData.subtotal_before_discount)} €</div>
                </div>}
            </div>
            <div className="px-1" style={ orderData && orderData.freeshipping ?  {display: "flex", justifyContent: "space-between", textDecoration: 'line-through'} : {display: "flex", justifyContent: "space-between"}}>
                <div>{{'EN': 'Shipping :', 'FR': 'Livraison :'}[lang]}</div>
                {orderData && <div>{toDec(orderData.shipping)} €</div>}
            </div>
            <div className="my-2" style={{border: '1px solid ' + color3}}>{" "}</div>
            <div className="px-2 mb-2" style={{
                display: "flex", justifyContent: "space-between",
                fontWeight: "500", fontSize: "1.2em",
                color: color1,
            }}>
                <div>{{'EN': 'Total :', 'FR': 'Total :'}[lang]}</div>
                {orderData && <div>{toDec(orderData.total)} €</div>}
            </div>
        </div>
    );
};

export default PricingSummary;

import LaboratoryTemplateDefault from "./LaboratoryTemplateDefault";
import LaboratoryTemplateMobile from "./LaboratoryTemplateMobile";

import { useContext } from "react";
import { DeviceContext } from "../../../contexts/DeviceContext";

const LaboratoryTemplate = (props) => {

    const { mobile } = useContext(DeviceContext);

    return (
        <>
        {mobile ? 
        <LaboratoryTemplateMobile 
            controlPanel={props.controlPanel}
            tools={props.tools}
            panel={props.panel__mobile}
        />
        :      
        <LaboratoryTemplateDefault
            controlPanel={props.controlPanel}
            tools={props.tools}
            isLoading={props.isLoading}
        />
        }
        </>
    );
};

export default LaboratoryTemplate;
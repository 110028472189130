const LaboratoryTemplateMobile = (
    {controlPanel, tools, panel}
    ) => {

    return(
        <>
            {   
                panel === 'control' && controlPanel
            }
            {
                panel === 'viewport' && tools
            }
        </>
    )
}

export default LaboratoryTemplateMobile;
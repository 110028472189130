import React from 'react';
import {useContext} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import {ThemeContext} from '../../../contexts/ThemeContext';
import { Moon, Sun } from 'react-bootstrap-icons';

const DarkModeToggle = () => {

    const {theme, setTheme} = useContext(ThemeContext);

    const handleSwitch = () => {
        setTheme((prevState) => prevState === 'dark' ? 'light' : 'dark');
    }

    return (
        <div className='d-flex justify-content-between align-items-center'>
            <Moon className="" style={{fontSize:'1.2rem', color: "var(--color-2)"}}/>
            <Form.Check 
                className='ms-2'
                type="switch"
                id="custom-switch-theme"
                style={{fontSize: "1.3rem"}}
                checked={theme === 'light'}
                onChange={handleSwitch}
            />
            <Sun className="pe-0" style={{fontSize:'1.2rem', color: "var(--color-2)"}}/>
        </div>
    );
};

export default DarkModeToggle;
import { useState, createContext, useContext, useEffect} from 'react';

import useAzureBlobList from '../hooks/useAzureListBlobs';

import useFetchData from '../hooks/useFetchData';

import { ABSContext } from './ABSContext';
import { AuthContext } from './AuthContext';

export const UtilsContext = createContext();

export const UtilsProvider = ({ children }) => {

    const { staticContainerClient } = useContext(ABSContext);
    const {blobList, isLoading, error} = useAzureBlobList(staticContainerClient);

    const [imageBankUrlsKeys, setImageBankUrlsKeys] = useState();
    useEffect (() => {
        setImageBankUrlsKeys(blobList);
    }, [blobList]);

    const [orderUtils, getOrderUtils] = useFetchData();
    const { isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        if (isAuthenticated) {
            getOrderUtils('api/order_utils')
        }
    }, [isAuthenticated]);

    return (
        <UtilsContext.Provider value={{imageBankUrlsKeys, orderUtils}}>
            {children}
        </UtilsContext.Provider>
    );
};



const ColorPill = ({width, height, palette}) => {
    return (
        <div style={{
            display: "flex", alignItems: "stretch", borderRadius: "0.3rem", 
            width: width, height: height, border: "0.1rem solid #AAAAAA", overflow: "hidden"
            }}>
            {
                palette && palette.colors.map((color, index) => (
                    <div key={index} 
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            backgroundColor: color,
                            opacity: palette.palette === 'Original' ? "0.3": "1", 
                            flexGrow: "1",
                            textAlign: "center", color: "#DDD", fontSize: "small",
                        }}
                    >
                        {palette.palette === 'EMPTY' ? 'EMPTY' : ' '}
                        {palette.palette === 'Original' ? 'ORIGINAL' : ' '}
                    </div>)
                )
                
            }
        </div>
    )
}

export default ColorPill;




import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {useContext} from 'react';
import {LanguageContext} from '../../../../contexts/LanguageContext';

const TermsAndConditions = ({modalShow, setModalShow}) => {

    const {language} = useContext(LanguageContext);

    return (
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "50vh", overflowY: "auto"}}>
                {{
                    'EN': 
                    <>
                    <h3>Terms and Conditions</h3>

                    <p>These terms and conditions govern your use of the mosaiklab.com website and any services provided through it. By accessing or using the website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not use the website.</p>

                    <h4>1. Use of Website</h4>

                    <p>You must be at least 18 years old to use this website. By using this website and agreeing to these terms and conditions, you represent and warrant that you are at least 18 years of age.</p>

                    <h4>2. Personal Information</h4>

                    <p>We do not collect any personal information, your contact details are used for operational purposes only (payment, delivery).</p>

                    <h4>3. Cookies</h4>

                    <p>We use cookies on this website to track IP addresses for rate limiting purposes. By using this website, you consent to the use of cookies in accordance with our privacy policy.</p>

                    <h4>4. Intellectual Property</h4>

                    <p>The content on this website, including but not limited to text, graphics, logos, and images, is the property of mosaiklab.com and is protected by copyright laws. You may not reproduce, distribute, or transmit any content from this website without our prior written consent.</p>

                    <h4>5. Refund Policy</h4>

                    <p>All sales are final. We do not offer refunds or exchanges unless the product is defective or damaged upon receipt. If you believe you have received a defective or damaged product, please contact us within 7 days of receiving the product.</p>

                    <h4>6. Governing Law</h4>

                    <p>These terms and conditions are governed by and construed in accordance with the laws of [your jurisdiction]. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of [your jurisdiction].</p>

                    <h4>7. Changes to Terms and Conditions</h4>

                    <p>We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use of the website after any such changes constitutes your acceptance of the new terms and conditions.</p>
                    </>,
                    'FR':
                    <>  
                    <h3>Conditions Générales</h3>

                    <p>Ces conditions générales régissent votre utilisation du site mosaiklab.com et de tout service fourni à travers celui-ci. En accédant ou en utilisant le site web, vous acceptez d'être lié par ces conditions générales. Si vous n'êtes pas d'accord avec une partie de ces conditions générales, vous ne devez pas utiliser le site web.</p>

                    <h4>1. Utilisation du Site Web</h4>

                    <p>Vous devez avoir au moins 18 ans pour utiliser ce site web. En utilisant ce site web et en acceptant ces conditions générales, vous déclarez et garantissez que vous avez au moins 18 ans.</p>

                    <h4>2. Informations Personnelles</h4>

                    <p>Nous ne collectons aucune information personnelles, votre coordonées ne sont utilisées qu'à des fins strictement opérationelles (paiement, livraison).</p>

                    <h4>3. Cookies</h4>

                    <p>Nous utilisons des cookies sur ce site web pour suivre les adresses IP à des fins de limitation d'utilisation. En utilisant ce site web, vous consentez à l'utilisation de cookies conformément à notre politique de confidentialité.</p>

                    <h4>4. Propriété Intellectuelle</h4>

                    <p>Le contenu de ce site web est la propriété de site mosaiklab.com et est protégé par les lois sur le droit d'auteur. Vous ne pouvez pas reproduire, distribuer ou transmettre tout contenu de ce site web sans notre consentement écrit préalable.</p>

                    <h4>5. Politique de Remboursement</h4>

                    <p>Conformément à la réglementation sur les produits personnalisables, toutes les ventes sont finales. Nous n'offrons pas de remboursements ou d'échanges sauf si le produit est défectueux ou endommagé à la réception. Si vous pensez avoir reçu un produit défectueux ou endommagé, veuillez nous contacter dans les 7 jours suivant la réception du produit.</p>

                    <h4>6. Droit Applicable</h4>

                    <p>Ces conditions générales sont régies par et interprétées conformément aux lois Française. Tout litige relatif à ces conditions générales sera soumis à la compétence exclusive des tribunaux de France.</p>

                    <h4>7. Modifications des Conditions Générales</h4>

                    <p>Nous nous réservons le droit de mettre à jour ou de modifier ces conditions générales à tout moment et sans préavis. Votre utilisation continue du site web après de tels changements constitue votre acceptation des nouvelles conditions générales.</p>

                    </>
                }[language]}
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalShow(false)}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TermsAndConditions;
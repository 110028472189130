import {useContext} from 'react';

const CartTemplateMobile = ({cartReview, savedDrafts, infoPanel, orderSummary}) => {

    return (
        <div className='px-3 mt-2 mb-5'>
            <div
                className='mb-4'
                style={{
                    display: "flex", flexDirection: "column",
                    height: "25vh"
                }}
            >
                {savedDrafts}
            </div>
            <div
                className='mb-5'
                style={{
                    display: "flex", flexDirection: "column",
                    height: "40vh"
                }}
            >
                {cartReview}
            </div>
            <div 
                className='mb-5'
                style={{
                    display: "flex", flexDirection: "column",
                    height: "auto"
                }}
            >
                {orderSummary}
            </div>
            <div 
                className='mb-5'
                style={{
                    display: "flex", flexDirection: "column",
                    height: "auto"
                }}
            >
                {infoPanel}
            </div>
        </div>
    );
};

export default CartTemplateMobile;
import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import {Row, Col} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav'

import { useTransition, animated } from 'react-spring'

import { RocketTakeoffFill, ArrowRightCircleFill} from 'react-bootstrap-icons';
// import { GearFill} from 'react-bootstrap-icons';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';

import tr from '../../../assets/translations/general.json'

const ControlTemplateMobile = (props) => {

    const {language} = useContext(LanguageContext);
    const {theme} = useContext(ThemeContext);

    const transition = useTransition(props.editMode, {   
        from: {opacity: 0.8, transform: 'translate3d(0%, 100%, 0)' },  
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: {opacity: 0.8, transform: 'translate3d(0, 0%, 0)' }, 
    })

    const disabledStyle = {pointerEvents: "none", opacity: "0.3"};

  return (
    <div className='px-0' style={{backgroundColor: "var(--bg-color-4)"}}>
        <div style={{position: "sticky", top: "0", zIndex: "2"}}>
            <div className="settings-header" style={{backgroundColor: "var(--bg-color-2)"}}> 
                <div className="d-flex w-100 mb-3 justify-content-between">
                    {props.helpButton}
                    {props.randomGenSwitch}
                </div>
                <div className="d-flex w-100" style={{textTransform: 'uppercase'}}> 
                    {props.editModeSelect}
                </div>
            </div>
            <div style={{position: "relative", zIndex: "-1"}}>
                <div className='scroll-fade-top'>{' '}</div>
            </div>
            {props.editMode === 'portrait' &&
                <Nav>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#model">{tr.sections.model[language]}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#background">{tr.sections.background[language]}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#canvas">{tr.sections.canvas[language]}</Nav.Link></Nav.Item>
                </Nav>
            }
            {props.editMode === 'abstract' &&
                <Nav>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#blocks">{tr.sections.blocks[language]}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#layout">{tr.sections.layout[language]}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#canvas">{tr.sections.canvas[language]}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link className="control-settings__navlink" href="#legend">{tr.sections.legend[language]}</Nav.Link></Nav.Item>
                </Nav>
            }
        </div>
        <div 
            style= {props.randomGenActive ? disabledStyle : {}}
        >
            {transition((style, item) => item === 'portrait' ? 
            <animated.div style={style} className="control-settings__content"> 
                {props.modePortrait}
            </animated.div> : 
            <animated.div style={style} className="control-settings__content"> 
            {props.modeAbstract}
            </animated.div>)}; 
        </div>
        <div style={{
            ...{position: "fixed", bottom: "0px",  width: "100%"}, 
            ...{backgroundColor: theme === 'dark' ? "rgba(40, 40, 40, 0.85)": "rgba(193, 200, 204, 0.85)"}
        }}>
            {props.randomGen}
            <Row style={{height: "3.5em"}}>
                <Col xs={3} className='ps-2'></Col>
                <Col xs={6} className='p-0'>
                    <div className='p-2'>
                        <Button 
                            className={'w-100 h-100 p-2 shadowed-text control-run__button--' +  props.runButtonClass}
                            style={{border: '2px solid white', borderRadius:'100px', fontWeight: 'bold'}}
                            onClick={props.runButtonClick} 
                            disabled={props.runButtonDisabled} 
                        >
                            {{'EN': 'Generate', 'FR': 'Génerer'}[language]}
                            <RocketTakeoffFill className='ms-2 shadowed-text'/>
                        </Button>
                    </div>
                </Col>
                <Col xs={3} className='pe-2 h-100'>
                    <div className='p-2 h-100'>
                        <ArrowRightCircleFill
                            style={{color: 'white'}}
                            className='w-100 h-100'
                            onClick={() => props.setPanel('viewport')} 
                            variant="secondary" 
                        />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  );
};

export default ControlTemplateMobile;

import React from 'react';

const FamilyTreePanel = ({build_id}) => {

    return (
        <div className='mt-3'>
            <h5 className='mb-4 w-100' style={{textAlign: "center"}}>What is the poster tree ?</h5>

            <p>
                Ever heard of pyramid schemes ? 
                Well, this is entirely different, it's called the poster tree.
                You could arge that a family tree looks a lot like a pyramid, but that's not the point.
            </p>
            <p>
                So, how does that work ? Well, if you make a purchase using this poster's id as a promocode, 
                your poster will become the "child" of the poster you got your code from. 
                Then, if someone else scans your poster, their poster will be linked to yours, and so on.
                It's like a family tree, but with posters.
            </p>
            <p>
                And eventually, with time, a return will be calculated based on the number of descendants of your posters 
                (indexed on inflation) and the amount will be transferred to a secret crypto account in Panama. 
                No wait, Im kidding, I told you, it's not a pyramid scheme. (FBI, please, don't come.)
            </p>
            <p>
                The idea is that you can access the network of posters 
                that have been created using the algorithm and see how 'far' your poster has traveled. 
                I am also thinking about implementing some additional poster features that you can unlock 
                based on your poster's descendance. 
            </p>
        </div>
    );
};

export default FamilyTreePanel;
import { useContext, useRef, useEffect } from 'react';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { UtilsContext } from '../../../contexts/UtilsProvider';
import { DeviceContext } from '../../../contexts/DeviceContext';

import { BoxSeam, InfoSquareFill } from 'react-bootstrap-icons';

import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';

import useFadeOnScroll from '../../../hooks/useFadeOnScroll';

const InfoPanel = () => {

    const { language } = useContext(LanguageContext);
    const { mobile } = useContext(DeviceContext);
    const { imageBankUrlsKeys, orderUtils } = useContext(UtilsContext);

    const contentRef = useRef(null);
    const { fadeOpacityT, fadeOpacityB } = useFadeOnScroll(contentRef);

    return (
        <>
        <div className='summary-section-title'>
            <InfoSquareFill className='me-2' style={{fontSize: "1em"}}/>    
            {{'EN': 'Information', 'FR': 'Information'}[language]}
        </div>
        {!mobile && <div style={{position: "relative"}}>
            <div 
                style={{borderRadius: "10px 10px 0 0", height: fadeOpacityT * 80}} 
                className='summary-section-scroll-fade-top'>
            {' '}</div>
        </div>}
        <div 
            ref={contentRef} className='py-3 summary-section summary-section--info'
            style={!mobile ? {maxHeight: "100%", overflowY: "auto"}:{}}
        > 

            <h5 className='mt-3 mb-1'>{{'EN': 'Poster', 'FR': 'Poster'}[language]}</h5><hr/>

            <p>{{
                'EN': "Images are generated with low quality for performance purposes: the version of the poster you see is not the final one. A high-resolution (300dpi) version of your mosaik is generated uppon receiving your order. Posters are available in a single 50x70 cm format (B2) and are printed on premium quality paper (170g/m²).",
                'FR': "Les images sont générées en basse qualité pour des raisons de performance: la version du poster que vous voyez n'est pas finale. Une version haute résolution (300dpi) de votre mosaik est générée lors de la réception de votre commande. Les posters sont disponibles dans un format unique 50x70 cm (B2) et sont imprimés sur du papier haute qualité (170g/m²)."
            }[language]}</p>

            {imageBankUrlsKeys && <div className='w-100 d-flex flex-row gap-2 p-2'>
                <Image rounded src={imageBankUrlsKeys['general_sizeref']} style={{height: "auto", width: "55%", border: "3px solid var(--color-3)"}}/>
                <div className='d-flex flex-column gap-2'>
                    <Image rounded src={imageBankUrlsKeys['general_paper_closup']} style={{height: "auto", width: "100%", border: "3px solid var(--color-3)"}}/>
                    <Image rounded src={imageBankUrlsKeys['general_base']} style={{height: "auto", width: "100%", border: "3px solid var(--color-3)"}}/>
                </div>
            </div>}

            <h5 className='mt-5 mb-1'>{{'EN': "Delivery", "FR": "Livraison"}[language]}</h5><hr/>

            <p>{{
                "EN": "Two delivery modes are available, home delivery and pick-up points. Posters are printed on a weekly basis and the standard delivery time is 5-7 working days from printing. Posters are shipped in a protective tube and delivery is free for the purchase of two posters or more.",
                "FR": "Deux modes de livraison sont disponibles, la livraison à domicile et la livraison en point relais. Les posters sont imprimés toutes les semaines et le délai de livraison standard est de 5-7 jours ouvrables à partir de l'impression. Les posters sont livrés dans un tube de protection et la livraison est gratuite pour l'achat de deux posters ou plus."
            }[language]}</p>

            <ListGroup className='mt-3 mb-5 mx-3'>
                <ListGroup.Item variant="secondary" className='d-flex justify-content-between align-items-center'>
                    <BoxSeam className='me-2'/><div>Currently delivering to</div><BoxSeam className='ms-2'/>
                </ListGroup.Item>
                {orderUtils?.data?.delivery_countries && orderUtils?.data?.delivery_countries.map((country, index) => (
                <ListGroup.Item key={`ship-to-${country}`} variant="secondary" className='d-flex justify-content-between'>
                    <div>{country}</div>
                    <div>{(Number(orderUtils?.data?.pricing.price_shipping)/100).toFixed(2)} €</div>
                </ListGroup.Item>
                ))}
            </ListGroup>

            <h5 className='mt-5 mb-1'>{{'EN': "Pricing", "FR": "Prix"}[language]}</h5><hr/>   

            <p>{{
                    "EN": "The creation of custom posters involves additional costs and logistics compared to posters from a pre-printed stock: the website requires computing resources and each poster is printed individually on order.",
                    "FR": "La création de posters personnalisés implique des coûts et une logistique supplémentaires par rapport à des posters issus d'un stock imprimé à l'avance: le site necessite des ressourcers de calcul et chaque poster est imprimé individuellement sur commande." 
            }[language]}</p>

            <ListGroup className='mt-3 mb-5 mx-3'>
                <ListGroup.Item variant="secondary" className='d-flex justify-content-between'>
                    <div>B2 (50x70)</div><div>{(Number(orderUtils?.data?.pricing.price_B2)/100).toFixed(2)} €</div>
                </ListGroup.Item>
                <ListGroup.Item variant="secondary" className='d-flex justify-content-between'>
                    <div>{{'EN': 'Delivery', 'FR': 'Livraison'}[language]}</div><div>{(Number(orderUtils?.data?.pricing.price_shipping)/100).toFixed(2)} €</div>
                </ListGroup.Item>
                <ListGroup.Item variant="secondary" className='d-flex justify-content-between'>
                    <div>{{'EN': 'Free delivery for 2+ posters', 'FR': 'Livraison gratuite pour 2+ posters'}[language]}</div><BoxSeam/>
                </ListGroup.Item>
            </ListGroup>

        </div>
        {!mobile && <div style={{position: "relative"}}>
            <div 
                style={{borderRadius: "10px 10px 0 0", height: fadeOpacityB * 80}} 
                className='summary-section-scroll-fade-bottom'>{' '}</div>
        </div>}

        </>
    );
};

export default InfoPanel;
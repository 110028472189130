import { useState, useEffect, useContext} from "react";
import useImageUrl from "../../../../hooks/useImageUrl";

import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownItem from "react-bootstrap/DropdownItem";
import Button from "react-bootstrap/Button";

import StudioBackground from "../../../../assets/images/backgrounds/studio.png";
import StudioBackgroundLight from "../../../../assets/images/backgrounds/studio_light.jpg";
import Tutorial from "./Tutorial";

import { Eye, EyeFill, RocketTakeoffFill, Eyeglasses, XLg } from "react-bootstrap-icons";

import { LanguageContext } from "../../../../contexts/LanguageContext";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import { DeviceContext } from "../../../../contexts/DeviceContext";
import { DraftContext } from "../../../../contexts/DraftContext";
import { ABSContext } from "../../../../contexts/ABSContext";
import ViewportMockups from "./ViewportMockups";


const Viewport = ({responseLoading}) => {

    // Language Context
    const {language} = useContext(LanguageContext);
    const {theme} = useContext(ThemeContext);
    const {currentDraft, setCurrentDraft} = useContext(DraftContext);
    const {mobile} = useContext(DeviceContext);
    
    // [PRODUCTION] Azure Blob Storage
    const { imagesContainerClient: containerClient } = useContext(ABSContext);

    const { loading: buildImageLoading } = useImageUrl(
        currentDraft?.image_blob_id, 
        containerClient, 
        (url) => {setCurrentDraft((prevDraft) => ({...prevDraft, image_blob_url: url}))}
    );

    const { loading: thumbnailImageLoading } = useImageUrl(
        currentDraft?.thumbnail_blob_id, 
        containerClient, 
        (url) => setCurrentDraft((prevDraft) => ({...prevDraft, thumbnail_blob_url: url}))
    );

    // ------ IMAGE BLOB RETRIEVAL ------

    const MAIN = 0;
    const STUD = 1;
    const MOCK = 2;
    const TUTO = 8;

    const view_dict = {
        0: {"EN": "Main View", "FR": "Vue Principale"},
        1: {"EN": "Studio", "FR": "Studio"},
        2: {"EN": "Mockups", "FR": "Maquettes"},
        8: {"EN": "Tutorial", "FR": "Tutoriel"}
    }

    const DEFAULT_MOBILE = STUD;
    const DEFAULT_DESKTOP = STUD;

    const [view, setView] = useState(
        mobile ? DEFAULT_MOBILE : DEFAULT_DESKTOP
    );

    // -- COMPONENT MANAGEMENT LOGIC -- 

    // Loop back to default/studio when new request 
    useEffect(() => { 
        [MOCK, TUTO].includes(view) && setView(mobile ? DEFAULT_MOBILE : DEFAULT_DESKTOP) 
    }, [responseLoading])

    // Loop back to default/studio when the image is loaded
    useEffect(() => {
        if ([TUTO].includes(view)) {
            currentDraft?.image_blob_id && setView(
                mobile ? DEFAULT_MOBILE : DEFAULT_DESKTOP
            );
        }
    }, [currentDraft?.image_blob_id])

    // -- UTILS - 
    const frame_mockup = { padding: "1em"}
    const frame_loading = { opacity: "0.6" }
    const frame_studio = {
        overflow: "hidden",
        backgroundImage: `url(${{
            'dark': StudioBackground, 
            "light": StudioBackgroundLight}[theme]
        })`, 
        backgroundSize: "cover", 
        backgroundPosition: 'center bottom 0',
        padding: "13%"
    }

    // TUTORIAL TOGGLE 
    const handleTutorial = () => {
        view===TUTO ? setView(mobile ? DEFAULT_MOBILE : DEFAULT_DESKTOP) 
        : setView(TUTO);
    }  

    return (
        <>
            <div className="viewport__frame"
                style={view === 1 ? frame_studio : ([1, 2].includes(view) ? frame_mockup : {overflow: "hidden"})}> 
                <div style={{
                    position: "absolute", top: "0.2em", left: "0.2em", width: "100%",
                    display: "flex", alignItems: "flex-end"
                }}>
                    <Dropdown>
                        <DropdownToggle
                            variant={{"dark": "outline-light", "light": "outline-secondary"}[theme]}
                            size="md" 
                            style={{position: "absolute", top: "0", left: "0", border: "0px"}}
                            className="d-flex flex-row align-items-center"
                        >
                            <EyeFill className="me-2"/>
                            <div style={{fontWeight: "bold", marginRight: "5px"}}>View /</div>
                            {view_dict[view][language]}
                            
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key={"0"} onClick={() => {setView(MAIN)}} className="text-with-icon">
                                {view_dict[MAIN][language]}
                                {view===MAIN ? <Eye/> : <></>}
                            </DropdownItem>
                            <DropdownItem key={"1"} onClick={() => {setView(STUD)}}  className="text-with-icon">
                                {view_dict[STUD][language]}
                                {view===STUD ? <Eye/> : <></>}
                            </DropdownItem>
                            <DropdownItem key={"2"} onClick={() => {setView(MOCK)}}  className="text-with-icon">
                                {view_dict[MOCK][language]}
                                {view===MOCK ? <Eye/> : <></>}
                            </DropdownItem>
                            {!mobile && <DropdownItem key={"8"} onClick={() => {setView(TUTO)}} className="text-with-icon">
                                {view_dict[TUTO][language]}
                                {view===TUTO ? <Eye/> : <></>}
                            </DropdownItem>}
                        </DropdownMenu>
                    </Dropdown>
                    { !mobile && <Button  
                        variant={{"dark": "outline-light", "light": "outline-secondary"}[theme]}
                        style={{position: "absolute", top: "0", right: "0.5em", border: "0px", fontWeight: "bold"}}
                        onClick={handleTutorial}
                    >
                        {view===TUTO ? "Close Tutorial" : "Tutorial"}
                        {view===TUTO ?  <XLg className="ms-2"/>: <Eyeglasses className="ms-2"/>}
                    </Button>}
                </div>
                {[MAIN, STUD].includes(view) && <>
                    {responseLoading && 
                        <Spinner style={{position: "absolute", zIndex: "1"}} size="lg" variant='light'/>
                    }
                    {currentDraft?.image_blob_url ?
                        <Image
                            src={currentDraft.image_blob_url}
                            style={responseLoading ? frame_loading : {}}
                            className="viewport__image rounded"
                        />: 
                        <h5 style={{color: "var(--color-1)", textAlign: "center"}}>
                            {{
                                'EN': <>Click on <strong>Generate</strong> <RocketTakeoffFill/> to create a poster!</>,
                                'FR': <>Clickez sur <strong>Générer</strong> <RocketTakeoffFill/> pour créer un poster!</>
                            }[language]}
                        </h5>
                    }
                </>}
                { view === MOCK && <ViewportMockups view={view} /> }
                { view === 8 && <Tutorial theme={theme} language={language} /> }
            </div>
        </>
    );
}

export default Viewport;
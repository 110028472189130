import { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import NavigationBar from './NavigationBar';
import FamilyTreePanel from '../library/FamilyTreePanel';

import { Badge, Image, Row, Col, Spinner } from 'react-bootstrap';

import ButtonPanel from '../library/ButtonPanel';
import TreeStats from '../library/TreeStats';

import { ABSContext } from '../../../contexts/ABSContext';
import { DeviceContext } from '../../../contexts/DeviceContext';
import { DraftContext } from '../../../contexts/DraftContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { CheckoutContext } from '../../../contexts/CheckoutContext';

import useFetchData from '../../../hooks/useFetchData';
import useImageUrl from '../../../hooks/useImageUrl';

const Library = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const { mobile } = useContext(DeviceContext);
    const { theme } = useContext(ThemeContext);
    const { language } = useContext(LanguageContext);
    const { handleRequestPromo } = useContext(CheckoutContext);
    const { handleDraftTemplate } = useContext(DraftContext);
    const { printsContainerClient } = useContext(ABSContext);


    const [posterData, getPosterData] = useFetchData();

    useEffect(() => {
        getPosterData(`api/poster/${id}`);
        handleRequestPromo(id);
    }, [id]);

    const [imageUrl, setImageUrl] = useState(null);
    const {loading} = useImageUrl(posterData?.data?.image_blob_id, printsContainerClient, setImageUrl);

    const handleTemplate = () => {
        handleDraftTemplate(posterData.data);
        navigate('/laboratory');
    }

    return (
        <>
        <NavigationBar/>
        {mobile ? 
        <div 
            className="w-100 d-flex flex-column align-items-center justify-content-center gap-2"
            style={{maxWidth: "100vh", minHeight: "92vh"}}
        > 
            {imageUrl ? <>
                <div className='m-2 p-2 w-100 d-flex gap-2 flex-column align-items-center justify-content-center' style={{
                    maxWidth: "95%", maxHeight: "95%", color: "var(--color-1)"
                }} >
                    <h4>Poster<strong className='ms-1'>{posterData?.data?.build_id}</strong></h4>
                    <Image 
                        rounded src={imageUrl} className='mb-3'
                        style={{maxHeight: "90%", maxWidth: "90%"}}
                    />
                    <div 
                        className='d-flex w-100 flex-column gap-2 align-items-center justify-content-between p-2'
                        style={{fontWeight: "300", fontSize: "1em"}}
                    >
                        <div className="my-3 mx-3" style={{textAlign: "center"}}>
                            Get a 10% discount with the code 
                            <Badge className='mx-1' bg="dark">{posterData?.data?.build_id}</Badge>
                        </div>
                        <TreeStats language={language} posterData={posterData}/>
                        <ButtonPanel language={language} theme={theme} handleTemplate={handleTemplate} />
                        <div className='w-100'><hr/></div>
                        <FamilyTreePanel/>
                    </div>
                </div>
            </>
            : (posterData?.isLoading || loading) ?
                <Spinner animation="border" variant="light"/> :  
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{width: "20em"}}
                >
                    <h3>Poster {id} Not Found</h3>
                </div>
            }
        </div>:
        <div 
            className="d-flex flex-column justify-content-center align-items-center"
            style={{height: "92vh", maxHeight: "92vh", overflow: "hidden"}}
        >
            {imageUrl ? <>
                <div className='m-2 p-2 w-100 d-flex gap-2 flex-column align-items-center justify-content-center' style={{
                    maxWidth: "95%", maxHeight: "95%", color: "var(--color-1)"
                }} >
                     <Row className='w-100 h-100 px-3 d-flex justify-content-center align-items-center'>
                        <Col className='h-100 w-100 d-flex flex-column gap-2 align-items-center'>
                            <h4>Poster<strong className='ms-1'>{posterData?.data?.build_id}</strong></h4>
                            <Image 
                                rounded src={imageUrl} className='mb-3'
                                style={{maxHeight: "90%", maxWidth: "90%"}}
                            />
                        </Col>
                        <Col className="" style={{maxHeight: "100%", overflowY: "auto"}}>
                            <div 
                                className='d-flex w-100 flex-column gap-2 align-items-center justify-content-between p-2'
                                style={{fontWeight: "300", fontSize: "1em"}}
                            >
                                <div className="my-3 mx-3" style={{textAlign: "center"}}>
                                    Get a 10% discount with the code 
                                    <Badge className='mx-1' bg="dark">{posterData?.data?.build_id}</Badge>
                                </div>
                                <TreeStats language={language} posterData={posterData}/>
                                <ButtonPanel language={language} theme={theme} handleTemplate={handleTemplate} />
                                <div className='w-100'><hr/></div>
                                <FamilyTreePanel/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
            : (posterData?.isLoading || loading) ?
                <Spinner animation="border" variant="light"/> :  
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{width: "20em"}}
                >
                    <h3>Poster {id} Not Found</h3>
                </div>
            }
        </div>
        }
        </>
        );



    };



export default Library;
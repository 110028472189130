import { useEffect, useState } from "react";

import useFetchData from "./useFetchData";

const useImageUrl = (imageId, containerClient, setImageUrl) => {

    const useDB = window.location.hostname === "localhost" && process.env.REACT_APP_ABS_TEST !== "ACTIVE";

    const [loading, setLoading] = useState(false);
    const [imageB64, getImageB64] = useFetchData(null);

    useEffect(() => {

        async function fetchBlob(blobName) {

            setLoading(true); 
            const blobClient = containerClient?.getBlobClient(blobName);
            try { setImageUrl(blobClient.url);} 
            catch (error) { console.error('Error fetching blob:', error); } 
            finally { setLoading(false); }
        }

        if (!useDB && containerClient && imageId) {fetchBlob(imageId);}
        if (useDB && imageId) {getImageB64('api/image_blob?id=' + imageId);}

    }, [imageId, containerClient]);

    useEffect(() => {

        if (imageB64?.data?.image_b64) {
            setImageUrl("data:image/png;base64," + imageB64.data.image_b64);
            setLoading(false);
        } 
    }, [imageB64?.data]);
    
    return { loading }
};

export default useImageUrl;
import React from 'react';
import {useContext, useState} from 'react';

import Form from 'react-bootstrap/Form';

import {ThemeContext} from '../../../contexts/ThemeContext';
import {LanguageContext} from '../../../contexts/LanguageContext';
import { Flag } from 'react-bootstrap-icons';


const LanguageToggle = () => {

    const {theme, setTheme} = useContext(ThemeContext);
    const {language, setLanguage} = useContext(LanguageContext);

    const handleSwitch = () => {
        setLanguage((prevState) => prevState === 'EN' ? 'FR' : 'EN');
    }

    return (
        <div className='d-flex justify-content-between align-items-center'>
            <div style={{color: "var(--color-2)", fontSize: "1.1em"}}>EN</div>
            <Form.Check 
                className='ms-2'
                type="switch"
                id="custom-switch-lang"
                style={{fontSize: "1.3rem"}}
                checked={language === 'FR'}
                onChange={handleSwitch}
            />
            <div style={{color: "var(--color-2)", fontSize: "1.1em"}}>FR</div>
        </div>
    );
};

export default LanguageToggle;
import { Container, Row, Col } from 'react-bootstrap';

const LaboratoryTemplateDefault = (
    {controlPanel, tools, isLoading}
    ) => {

    return(
        <Container fluid className="laboratory-page">
            <Row>
                <Col 
                    style={isLoading ? {pointerEvents: "none", opacity: "0.6"} : {}}
                    className="left-panel" xs={5} sm={4} md={4} xl={4}
                >
                    {controlPanel}
                </Col>
                {tools}
            </Row>
        </Container>
    )
}

export default LaboratoryTemplateDefault;
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Accordion from 'react-bootstrap/Accordion';

import AccordionToggle from '../../ui/AccordionToggle';
import LabeledSelectInBlock from '../../ui/LabeledSelectInBlock';
import ElementSelect from '../../ui/ElementSelect';
import ColorSelectTabs from '../../ui/ColorSelectTabs';

import { useEffect, useState, useContext } from 'react';

import { LanguageContext } from "../../../../contexts/LanguageContext";
import tr from '../../../../assets/translations/general.json';
import tr_opt from '../../../../assets/translations/options.json';

import ColorPill from '../../utils/ColorPill';

const BlockTemplateCollapse = ({
    blockId, mosaicData, handleSelect, handleDeleteBlock, options, utils
}) => {

    // Language
    const {language} = useContext(LanguageContext);

    const blockData = mosaicData.find(b => b.id === blockId);

    // Special Collections
    const [elementsSelection, setElementsSelection] = useState(false);
    const [elementsOptions, setElementsOptions] = useState();
    const handleSelectCollection = (parentBlockId, property, value) => {
        handleSelect(blockId, 'elements', [0]);
        handleSelect(blockId, 'collection', value);
    }

    useEffect(() => {
        if (blockData?.collection) {
            utils && setElementsOptions(utils.collections_elements_dict[blockData.collection]);
            utils && setElementsSelection(utils.collections_special.includes(blockData.collection));
            handleSelect(blockId, 'elements', [0]);
        }
    }, [blockData?.collection]);

    // Current Color to put in header with added colors associated to it  
    const currentColorOpt = utils && blockData?.color && utils.colors.filter(
        item => item.palette === blockData.color)[0];

    return (
        <Accordion defaultActiveKey="0" className="mb-2">
            <Card>
            <Card.Header 
                className="control-settings__card-header d-flex justify-content-between align-items-center"
                style={{...{paddingBottom: "0.3rem"}}}
            >
                <div style={{display: "flex", alignItems: "center"}}>
                    <Card.Title size="sm" style={{marginBottom: "0", marginRight:"1rem", fontSize: "1rem", flexGrow: "1", color: "var(--color-2)"}}>
                        {blockData && tr_opt[blockData.collection][language]}
                    </Card.Title>
                    <ColorPill palette={currentColorOpt} height={"1rem"} width={"3rem"}/>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <AccordionToggle eventKey="0"/>
                    {mosaicData.length > 1 && ![0, 1].includes(blockId) && (
                        <CloseButton 
                            size="sm" 
                            style={{width: "0.5rem", height:"0.5rem", margin: "0 0 0 1rem", backgroundSize:"auto"}}
                            onClick={() => handleDeleteBlock(blockId)}
                        />
                    )}
                </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body className="control-settings__card-body">
                    <Row className='mb-1'>
                        <Col>
                            <LabeledSelectInBlock
                                parentBlockId = {blockId}
                                property = "size"
                                label={{'EN': 'Elements Size', 'FR': 'Taille des éléments'}[language]} 
                                handleSelect={handleSelect}
                                options={options && options.size}
                                currentOption={blockData?.size}
                            />
                        </Col>
                        <Col>
                            <LabeledSelectInBlock
                                parentBlockId = {blockId}
                                property = "layout"
                                label={{'EN': 'Variability', 'FR': 'Variabilité'}[language]} 
                                handleSelect={handleSelect}
                                options={options && options.layout}
                                currentOption={blockData?.layout}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <LabeledSelectInBlock
                                parentBlockId = {blockId}
                                property = "collection"
                                label={{'EN': 'Collection', 'FR': 'Collection'}[language]} 
                                handleSelect={handleSelectCollection}
                                options={options && options.collection}
                                currentOption={blockData?.collection}
                            />
                        </Col>
                    </Row>
                    {
                        elementsSelection && <ElementSelect
                            parentBlockId = {blockId}
                            label={{'EN': 'Elements', 'FR': 'Éléments'}[language]} 
                            handleSelect={handleSelect}
                            collection={blockData?.collection}
                            options={elementsOptions}
                            currentElements={blockData?.elements}
                        />
                    }
                    {
                        <ColorSelectTabs
                            parentBlockId = {blockId}
                            label={{'EN': 'Color', 'FR': 'Couleur'}[language]} 
                            handleSelect={handleSelect}
                            palettesColorsTabs={utils?.color_tabs}
                            options={options && options.color}
                            currentColor={blockData?.color} 
                        />
                    }
                </Card.Body>
            </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default BlockTemplateCollapse;   
import { useState } from 'react';

const useAzureBlobUpload = (containerClient) => {

  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const uploadBlob = async (blob, blobName) => {
    try {
      setUploadProgress(0);
      setIsUploaded(false);
      setError(null);

      // Get a reference to the block blob client for the file
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      // Upload the blob
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(blob, {
        onProgress: (progress) => setUploadProgress((progress.loadedBytes / blob.size) * 100),
      });
      
      setIsUploaded(true);
      return uploadBlobResponse;
    } catch (err) {
      console.error('Error uploading blob:', err);
      setError(err.message);
      setIsUploaded(false);
    }
  };

  return {
    uploadBlob,
    uploadProgress,
    isUploaded,
    error,
  };
};

export default useAzureBlobUpload;
import { useState, useEffect, useContext } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { LanguageContext } from '../../../contexts/LanguageContext';
import { DeviceContext } from '../../../contexts/DeviceContext';
import { ArrowRepeat, ArrowDownUp, Phone, ArrowClockwise } from 'react-bootstrap-icons';


import TutoCaroussel from './TutoCaroussel';

const MobileModal = ({onClose}) => {

    const { language } = useContext(LanguageContext);
    const { mobile } = useContext(DeviceContext);

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (mobile) {
            setShow(true);
        }
    }, [mobile]);

    const handleClose = () => {
        setShow(false);
        if (onClose) {onClose(); }
    }; 

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Phone className="me-2"/>
                    {{'EN': 'Mobile Version', 'FR': 'Version Mobile'}[language]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {{
                    'EN': <>
                        <p className='mt-3'>
                            You are currently using the mobile version of the webapp.  
                            You can still acess the service, but I highly recommend using a desktop or laptop computer for the best experience! 
                        </p>
                    </>,
                    'FR': <>
                        <p className='mt-3'>
                            Vous utilisez actuellement la version mobile de l'application web.  
                            Vous pouvez toujours accéder au service, mais je vous recommande d'utiliser un ordinateur pour une meilleure expérience! 
                        </p>
                    </>
                }[language]}
                <TutoCaroussel/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"dark"} onClick={handleClose}>
                    {{
                        'EN': 'Skip', 
                        'FR': 'Passer'}[language]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MobileModal;
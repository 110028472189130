import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import alphabetLookup from "./../../../assets/alphabetLookup";

import { useContext } from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";

const ElementSelect = ({parentBlockId, label, collection, options, currentElements, handleSelect}) => {

    const {language} = useContext(LanguageContext);

    const formatElement = (collection, opt) => {
        if (collection === "Hebrew") {
            return alphabetLookup.hebrew[opt.id.toString()];
        }
        else if (collection === "Arabic") {
            return alphabetLookup.arabic[opt.id.toString()];
        }
        else 
        return opt.name;
    };

    return (
        <InputGroup className="mt-1">
            <Dropdown autoClose="outside">
                <DropdownToggle size="sm" id="dropdown-autoclose-outside" variant="outline-light">
                    {label}
                </DropdownToggle>
                <DropdownMenu className="element-select__dropdown-menu">
                    <Row className="d-flex justify-content-center">
                        <DropdownItem
                            key={0}
                            className="element-select__top-dropdown-item"
                            style={ currentElements.includes(0) ?
                                { backgroundColor:"#E9ECEF", borderRadius: "5px", border: "2px solid #CED4DA" } :
                                { backgroundColor: "transparent" }
                            }
                            onClick={ () => { !currentElements.includes(0) && handleSelect(parentBlockId, 'elements', [0]) }}
                        >
                            {{'EN': 'All elements', 'FR': 'Tout les éléments'}[language]}
                        </DropdownItem>
                    </Row>
                    <Row className="d-flex justify-content-around w-100 m-0"
                        style={{flexFlow: ['Hebrew', 'Arabic'].includes(collection) ? "row-reverse wrap":  "row wrap"}}
                    > 
                        {
                            options && options.map( (opt) => (
                                opt.id !== 0 && <DropdownItem
                                    key={opt.id}
                                    className="element-select__dropdown-item"
                                    style={ currentElements.includes(opt.id) ?
                                        { backgroundColor:"#E9ECEF", width: "3rem",borderRadius: "5px", border: "2px solid #CED4DA"} :
                                        { backgroundColor:"#FFFFFF",  width: "3rem" }
                                    }
                                    onClick={
                                        () => {
                                            // Element is not yet selected 
                                            if (!currentElements.includes(opt.id)) {
                                                const newElementsList = currentElements.includes(0) ?
                                                    [opt.id] : currentElements.concat(opt.id)
                                                handleSelect(parentBlockId, 'elements', newElementsList)
                                            } else { 
                                                let newElementsList = currentElements.filter(
                                                    function(item) { return item !== opt.id });
                                                if (newElementsList.length === 0) {newElementsList = [0]}
                                                handleSelect(parentBlockId, 'elements', newElementsList)
                                            }
                                        }
                                    }
                                >{formatElement(collection, opt)}</DropdownItem>   
                            ))
                        }
                    </Row>
                </DropdownMenu>
            </Dropdown>
            <Form.Control
                size="sm" name="elements"
                value={
                    currentElements.includes(0) ? 'Full Alphabet' : 
                    currentElements.map(element => (collection ? alphabetLookup[collection.toLowerCase()][element] : ''))
                }
                readOnly
            />
        </InputGroup>
    );
}

export default ElementSelect;


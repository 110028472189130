import React from 'react';
import { useState, useEffect } from 'react';

const InfiniteSlider = ({direction, position, content}) => {

    const [contentShuffled, setContentShuffled] = useState([]);
    useEffect(() => {
        content && setContentShuffled([...content].sort(() => Math.random() - 0.5));
    }, [content]);

    return (
        <>
            <div className={`scroller scroller-${position}`} data-direction={direction}>
                <ul className="tag-list scroller__inner h-100">
                    {contentShuffled}
                    {contentShuffled}
                </ul>
            </div>
        </>
        
    );
};

export default InfiniteSlider;
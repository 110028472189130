import { InfoCircle } from 'react-bootstrap-icons';

import Modal from 'react-bootstrap/Modal';

const ModalGeneric = ({title, show, setShow, content}) => {

    return (
        <Modal show={show} onHide={() => {setShow(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <InfoCircle className='me-2'/>{title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>
                {content}
            </Modal.Body>
        </Modal>
    );
};

export default ModalGeneric;
import Form from "react-bootstrap/Form";

import {useContext} from 'react';
import {LanguageContext} from '../../../contexts/LanguageContext';
import tr_opt from '../../../assets/translations/options.json';

const LabeledSelect = ({label, options, currentOption, handleSelect}) => {

    const {language} = useContext(LanguageContext);

    return (
        <>
         <Form.Label className="control-settings__form-label">{label}</Form.Label>
         <Form.Select 
            value={currentOption ? currentOption : (options ? options[0] : '')}
            size="sm" variant="outline-light" 
            aria-label="Default select example"
            onChange={(e) => {handleSelect(e.target.value)}}
        >
            {
                // props.options example ==> paletteColors.data
                options && options.map(
                    (opt) => (
                        <option 
                            key={label + opt}
                            value={opt}
                            size="sm"
                        >
                            {tr_opt[opt] ? tr_opt[opt][language] : opt}
                        </option>
                    )
                )
            }
        </Form.Select>
        </>
    );
}

export default LabeledSelect;

import {useEffect} from 'react';

import MondialRelay from "../../../../assets/svg/MondialRelay.svg";
import { Badge, Card, ListGroup } from "react-bootstrap";

import countryLookup from '../../../../assets/countryLookup.json';

const ServicePointsInfo = ({selectedSP, validCountry, warningSelectedSP, addressList, orderUtils, language}) => {

    return (
        <div>

            {!validCountry &&
            <Card border={warningSelectedSP ?  "danger" : ""}>
                <Card.Header>
                    {{'EN': 'Delivery not available', 'FR': 'Livraison non disponible'}[language]}
                </Card.Header>
                <Card.Body>
                    <div style={{fontSize: "small", fontWeight: "700", marginBottom: "0.5em"}}>
                        {{'EN': 'Pickup point available in: ', 'FR': 'Points de collecte disponibles en :'}[language]}
                    </div>
                    <div className="d-flex flex-row gap-2 flex-wrap">
                        {orderUtils?.data?.delivery_countries && orderUtils.data.delivery_countries.map((country, index) => 
                            <Badge key={index} bg="secondary">{countryLookup[country]}</Badge>
                        )}
                    </div>
                </Card.Body>
            </Card>}


            {validCountry && !selectedSP &&
            <Card border={warningSelectedSP ?  "danger" : ""}>
                <Card.Header>
                    {{'EN': 'Select a collect point', 'FR': 'Sélectionnez un point de collecte'}[language]}
                </Card.Header>
                <Card.Body>
                    {addressList.length > 0 && <>
                        {{
                            'EN': 'Select a collect point on the map', 
                            'FR': 'Sélectionnez un point de collecte sur la carte'
                        }[language]}</>}
                    {addressList.length === 0 && <>
                        {{
                            'EN': 'No collect points available in your area.', 
                            'FR': 'Aucun point de collecte disponible dans votre région.'
                        }[language]}
                    </>}
                </Card.Body>
            </Card>}


            {validCountry && selectedSP && <Card>
                <Card.Header>
                    <img src={MondialRelay} alt="Mondial Relay" style={{width: "2em", paddingRight: "0.3em"}}/>
                    <span style={{paddingRight: "0.5em", fontSize: "0.8em", fontWeight: "600"}}>
                        {selectedSP.name ? selectedSP.name : "Mondial Relay"}
                    </span>
                </Card.Header>
                <Card.Body>
                    <div className="mb-2" style={{fontSize:"small"}}>{selectedSP.address}</div>
                    <ListGroup>
                    {selectedSP.formatted_opening_times ? selectedSP.formatted_opening_times.map((opn_time, index) => {
                        return(
                        <ListGroup.Item 
                            className="py-1 d-flex justify-content-between flex-wrap align-items-center"
                            style={{fontSize: "small"}} key={index}
                        >
                            <div>{opn_time.day}</div>
                            <div>{opn_time.hours}</div>
                        </ListGroup.Item>
                        )
                    }): <>{{'EN': 'Select a collect point', 'FR': 'Sélectionnez un point de collecte'}[language]}</>}
                    </ListGroup>
                </Card.Body>
            </Card>}

            {false && validCountry && (!selectedSP?.shipment_methods?.length) && <Card>
                <Card.Header>
                    {{'EN': 'Shipping unavailable', 'FR': 'Méthodes d\'expédition introuvables'}[language]}
                </Card.Header>
                <Card.Body>
                    {{
                        'EN': 'Please select another collect point or delivery method.', 
                        'FR': 'Veuillez sélectionner un autre point de collecte ou une autre méthode de livraison.'
                    }[language]}
                </Card.Body>
            </Card>}

        </div>
    );
};

export default ServicePointsInfo;
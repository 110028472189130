import { useContext } from 'react';

import { UtilsContext } from '../../../../contexts/UtilsProvider';

import { Image, Nav, Spinner } from 'react-bootstrap';

const Tutorial = ({ theme, language }) => {

    const { imageBankUrlsKeys } = useContext(UtilsContext);

    return (
        <div className='h-100 w-100 d-flex flex-column'>
            <h1 className='w-100 text-center' style={{ color: "var(--color-1)" }}>
                {{ 'EN': 'Tutorial', 'FR': 'Tutoriel' }[language]}
            </h1>
            <Nav 
                className="pb-2 d-flex flex-row gap-3 mb-3 justify-content-center align-content-center" variant="underline"
                style={{ borderBottom: "1px solid var(--bg-color-4)" }}>
                <Nav.Item key={'abstract'}>
                    <Nav.Link href={'#abstract'} style={{ color: "var(--color-1)" }}>
                        {{ 'EN': 'Abstract Mode', 'FR': 'Mode Abstrait' }[language]}
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item key={'portrait'}>
                    <Nav.Link href={'#portrait'} style={{ color: "var(--color-1)" }}>
                        {{ 'EN': 'Portrait Mode', 'FR': 'Mode Portrait' }[language]}
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item key={'random'}>
                    <Nav.Link href={'#random'} style={{ color: "var(--color-1)" }}>
                        {{ 'EN': 'Random Mode', 'FR': 'Mode Aléatoire' }[language]}
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <div className='px-3' style={{ flexGrow: "1", overflowY: "auto", scrollBehavior: "smooth" }}>
                {!imageBankUrlsKeys ? 
                    <div className='w-100 mt-5 d-flex justify-content-center'><Spinner variant={'light'} /></div> : 
                    <>
                        <a name="abstract" href='#abstract' style={{ cursor: "default" }}>
                            <Image 
                                src={imageBankUrlsKeys[`tutorial_abstract_${theme}`]}
                                className='w-100 my-5' alt='abstract'
                            />
                        </a>
                        <a name="portrait" href='#portrait' style={{ cursor: "default" }}>
                            <Image
                                src={imageBankUrlsKeys[`tutorial_portrait_${theme}`]}
                                className='w-100 my-5' alt='portrait'
                            />
                        </a>
                        <a name="random" href='#random' style={{ cursor: "default" }}>
                            <Image 
                                src={imageBankUrlsKeys[`tutorial_random_${theme}`]}
                                className='w-100 my-5' alt='random'
                            />
                        </a>
                    </>
                }
            </div>
        </div>
    );
};

export default Tutorial;
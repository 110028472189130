import { Col, Row } from 'react-bootstrap';

const ToolsTemplateDefault = ({
        fileName,
        viewPort,
        loadDraftDropdown,
        buttonDelete,
        buttonRenaming,
        buttonSave,
        buttonToggleCart,
        posterSizeChoice,
        buttonToCart, 
}) => {

    return (
        <>
            <Col 
                className="center-panel" 
                xs={4} sm={6} md={6} xl={6}
            >
                <Row className="center-panel__viewport">
                    {viewPort}
                </Row>
            </Col>
            <Col 
                className="right-panel px-0" 
                xs={3} sm={2} md={2} xl={2}
            >
                <Row className="right-panel__file-panel ms-0 me-2">
                <div>
                    <div className="d-grid gap-2 mt-2">
                        {loadDraftDropdown}
                    </div>
                    {fileName}
                    <div className="d-grid gap-2 mb-4">
                        {buttonSave}
                        {buttonDelete}
                        {buttonRenaming}
                        {buttonToggleCart}
                        
                    </div>
                    <div>
                        {posterSizeChoice}
                    </div>
                </div>
                </Row>
                <Row>
                    {buttonToCart}
                </Row>
            </Col>
        </>
    );
};

export default ToolsTemplateDefault;
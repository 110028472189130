import Button from 'react-bootstrap/Button';
import { Trash } from 'react-bootstrap-icons';

const ButtonDelete = ({handleDraftDelete, disabled, theme, lang}) => {
    return (
        <Button
            className="px-3 button-with-icon"
            variant={theme}
            onClick={handleDraftDelete}
            disabled={disabled}
        >{{'EN': 'Delete', 'FR': 'Supprimer'}[lang]}<Trash/></Button>
    );
};

export default ButtonDelete;
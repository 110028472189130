import { useContext } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {ThemeContext} from '../../../../contexts/ThemeContext';
import {LanguageContext} from '../../../../contexts/LanguageContext';

import tr_opt from '../../../../assets/translations/options.json';

const EditModeSelect = ({options, editMode, setEditMode}) => {

  const {theme} = useContext(ThemeContext);
  const {language} = useContext(LanguageContext);

  return (
    <ButtonGroup style={{ width: '100%' }}>
    {
      options && options.map((option, idx) => (
        <ToggleButton
          key={idx} id={`radio-${idx}`} type="radio" 
          variant={theme==='dark' ? 'outline-light': 'outline-secondary'} 
          style={{fontSize: "0.8rem", fontWeight: '800'}}
          value={option} checked={editMode === option}
          onChange={(e) => setEditMode(e.currentTarget.value)}
        >
          {tr_opt[option] ? tr_opt[option][language] : option}
        </ToggleButton>
      ))
    }
    </ButtonGroup>
  );
};

export default EditModeSelect;
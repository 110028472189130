
import {useEffect, useState, useContext, useRef} from "react";

import RandomGen from './control/RandomGen';
import RandomGenSwitch from './control/RandomGenSwitch';
import EditModeSelect from './control/EditModeSelect';
import ModeAbstract from './control/ModeAbstract';
import ModePortrait from './control/ModePortrait';
import HelpButton from './control/HelpButton';
import ServerUnavailable from "./control/ServerUnavailable";

import {MosaikUtilsContext} from '../../../contexts/MosaikUtilsContext';

import ControlTemplate from '../../templates/control/ControlTemplate';


// The control panel corresponds to the left panel in the laboratory page. 
// It contains the settings, the random generation, and the run button.

const ControlPanel = ({
    currentDraft, 
    handleSubmitData,
    setDataBundle,
    setPanel__mobile,
}) => {

    // -- CONTEXT --
    const {
        optionsData: options, 
        utilsData: utils, 
        defaultOptionData:defaultOption
    } = useContext(MosaikUtilsContext);

    // -- EDIT MODE 

    const [editMode, setEditMode] = useState('abstract');
    useEffect(() => {
        setEditMode(currentDraft.editMode);
    }, [currentDraft.editMode])

    // -- SEED MECHANISM -- 

    const [randomGenActive, setRandomGenActive] = useState(false);
    const [seed, setSeed] = useState(0);
    const runButtonClass = randomGenActive ? "random" : editMode
    const handleRandomGenToggle = (eventKey) => {
        setRandomGenActive((prevState) => !prevState);
    };

    // -- DATA TOGGLE --
    const [dataAM, setDataAM] = useState(); 
    const [dataPM, setDataPM] = useState();

    useEffect(() => {
        const bundle = {
            'edit_mode': editMode, 
            'random_gen': randomGenActive,
            'random_seed': seed,
        }
        const params = editMode === 'abstract' ? dataAM : dataPM
        setDataBundle({...bundle, ...params})
    }, [editMode, randomGenActive, seed, dataAM, dataPM])

    return (
        <>
            {utils && options ?
            <ControlTemplate
                randomGenSwitch={<RandomGenSwitch 
                    handleSwitch={handleRandomGenToggle} 
                    setRandomGenActive={setRandomGenActive} randomGenActive={randomGenActive}/>
                }
                editModeSelect={<EditModeSelect
                    options={options.editMode}
                    editMode={editMode} setEditMode={setEditMode}
                />}
                randomGen={<RandomGen   
                    randomGen={randomGenActive} setRandomGen={setRandomGenActive} 
                    setSeed={setSeed} handleRandomGenToggle={handleRandomGenToggle}
                />}
                randomGenActive={randomGenActive}
                modeAbstract = {<ModeAbstract
                    options={options} utils={utils} defaultOption={defaultOption}
                    currentDraft={currentDraft} setData={setDataAM}
                />}
                modePortrait = {<ModePortrait
                    options={options} utils={utils} defaultOption={defaultOption}
                    currentDraft={currentDraft} setData={setDataPM}
                />}
                helpButton={<HelpButton/>}
                editMode={editMode}
                runButtonClass = {runButtonClass}
                runButtonClick = {handleSubmitData}
                runButtonDisabled = {currentDraft?.image_blob_loading}
                setPanel__mobile = {setPanel__mobile}
            />: 
            <ServerUnavailable/>
            }
        </>
        
    )
}

export default ControlPanel;

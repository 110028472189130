import { useEffect, useState, useContext, createContext } from 'react';
import useFetchData from '../hooks/useFetchData';
import usePostData from '../hooks/usePostData';

import useImagesUrls from '../hooks/useImagesUrls';

import { ABSContext } from './ABSContext';
import { AuthContext } from './AuthContext';

export const MosaikUtilsContext = createContext();

export const MosaikUtilsProvider = (props) => {

    // Utils 
    const [utils, getUtils] = useFetchData();
    const [options, getOptions] = useFetchData();
    const [defaultOption, getDefaultOption] = useFetchData();
    const [computedSeeds, getComputedSeeds] = useFetchData();
    const [seedBank, getSeedBank] = useFetchData();

    // Post Data
    const [buildResponse, runBuild] = usePostData();
    const [warmupResponse, runWarmup] = usePostData();

    // Authentication
    const { isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        if (isAuthenticated) {

            // Fetch Data
            getUtils('api/mosaik/utils');
            getOptions('api/mosaik/options');
            getDefaultOption('api/mosaik/default');
            getComputedSeeds('api/mosaik/random_seeds');
            getSeedBank('api/mosaik/seedbuild_bank');

            // Warmup Function App
            (process.env.REACT_APP_TEST_FA || 'INACTIVE') === 'ACTIVE' &&
                runWarmup('api/mosaik', {'warmup': true}, 'FA');
        }
    }, [isAuthenticated]);

    // Function App Readyness
    const [faReady, setFaReady] = useState(false);
    useEffect(() => {
        console.log('FA Ready:', faReady);
        warmupResponse && warmupResponse.data && setFaReady(true);
    }, [warmupResponse]);

    // Fallback to blocking container app call if function app is not ready
    const handleRequestBuildFA = (dataBundle) => {runBuild('api/mosaik', dataBundle, 'FA')}
    const handleRequestBuildAPI = (dataBundle) => {runBuild('api/build', dataBundle, 'API')}
    const handleRequestBuild = (dataBundle) => {
        (process.env.REACT_APP_TEST_FA || 'INACTIVE') === 'ACTIVE' && faReady ?
            handleRequestBuildFA(dataBundle) : handleRequestBuildAPI(dataBundle);
    }

    const [utilsData, setUtilsData] = useState({});
    const [optionsData, setOptionsData] = useState({});
    const [defaultOptionData, setDefaultOptionData] = useState({});
    const [seedBankUrls, setSeedBankUrls] = useState({});

    const { imagesContainerClient  } = useContext(ABSContext);
    const { loading } = useImagesUrls(seedBank, imagesContainerClient, setSeedBankUrls);

    useEffect(() => {
        utils && setUtilsData(utils.data);
    }, [utils.data]);

    useEffect(() => {
        options.data && setOptionsData(options.data);
    }, [options.data]);

    useEffect(() => {
        defaultOption.data && setDefaultOptionData(defaultOption.data);
    }, [defaultOption.data]);

    return (
        <MosaikUtilsContext.Provider value={{
            handleRequestBuild, handleRequestBuildAPI, buildResponse,
            utilsData, optionsData, defaultOptionData, 
            computedSeeds, seedBank, seedBankUrls
        }}>
            {props.children}
        </MosaikUtilsContext.Provider>
    );
};



import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownItem from "react-bootstrap/DropdownItem";
import Form from "react-bootstrap/Form";

import ColorTemplate from "../utils/ColorTemplate";
import ColorPill from "../utils/ColorPill";

import {useEffect, useState, useContext} from "react";
import {Nav, Tab} from "react-bootstrap";

import {LanguageContext} from "../../../contexts/LanguageContext";
import tr_opt from "../../../assets/translations/options.json";


const ColorSelectTabs = ({parentBlockId, label, currentColor, palettesColorsTabs, handleSelect}) => {

    const {language} = useContext(LanguageContext);

    const [currentOpt, setCurrentOpt] = useState();
    useEffect(() => {
        palettesColorsTabs && setCurrentOpt(Object.entries(palettesColorsTabs).flatMap(
            ([key, value]) => value.find(item => item.palette === currentColor)).find(item => item !== undefined));
    }, [currentColor, palettesColorsTabs])

    const defaultTab = 'adjacent'

    return (
        <>  
            <Form.Label style={{color: "var(--color-1)", fontSize:"0.8rem", marginBottom:"2px"}}>{label}</Form.Label>
            <Dropdown>
                <Row>
                    <Col>
                        <DropdownToggle size="sm" variant="light" style={{width: "100%", alignContent: "left"}}>
                            {label}
                        </DropdownToggle>
                        <Dropdown.Menu className="w-100 ps-2">
                            <Tab.Container id="left-tabs-example" defaultActiveKey={defaultTab}>
                                <Nav className="flex-row row-wrap mb-3" variant="underline" style={{alignContent:"space-between"}}>
                                {
                                    palettesColorsTabs && Object.keys(palettesColorsTabs).map((option, idx) => (
                                        <Nav.Item key={idx} style={{minWidth: '20%', maxWidth: '20%'}}>
                                        <Nav.Link eventKey={option} style={{color:"#555", textAlign: 'center'}}>
                                            {tr_opt[option] ? tr_opt[option][language] : option}
                                        </Nav.Link>
                                        </Nav.Item>
                                    ))
                                }
                                </Nav>
                                <Dropdown.Divider/>
                                <Tab.Content>
                                {
                                    palettesColorsTabs && Object.keys(palettesColorsTabs).map((option, idx) => (
                                        <Tab.Pane key={idx} eventKey={option}>
                                            <div
                                                style={{
                                                    display: "flex", width: "100%", margin: "0",
                                                    flexFlow: "column wrap", 
                                                    justifyContent: "space-between"
                                                }}
                                            > 
                                                {
                                                   palettesColorsTabs && palettesColorsTabs[option].map( (opt) => (
                                                        <DropdownItem
                                                            key={opt.palette}
                                                            className="color-select__dropdown-item"
                                                            onClick={() => {handleSelect(parentBlockId, 'color', opt.palette);}}
                                                        >
                                                            <ColorTemplate colors={opt} paletteName={opt.palette}/>
                                                        </DropdownItem>   
                                                    ))
                                                }
                                            </div>
                                        </Tab.Pane>
                                    ))
                                }
                                </Tab.Content>
                            </Tab.Container>
                        </Dropdown.Menu>
                    </Col>
                    <Col>
                        <ColorPill palette={currentOpt} height={"2rem"} width={"100%"}/>
                    </Col>
                </Row>
            </Dropdown>
        </>
    );
}

export default ColorSelectTabs;

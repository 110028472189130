import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { DeviceContext } from '../../../../contexts/DeviceContext';

import { HouseFill } from 'react-bootstrap-icons';
import MondialRelay from '../../../../assets/svg/MondialRelay.svg';

import Button from 'react-bootstrap/Button';

const ShippingMethodToggle = ({shippingMethod, setShippingMethod}) => {

    const {language} = useContext(LanguageContext);
    const {mobile} = useContext(DeviceContext);

    return (
        <div className={`d-flex gap-3 justify-content-end mb-${mobile ? 3 : 0}`}>
            <Button
                className={'button-shipmethod' + (shippingMethod === "homeDelivery" ? ' button-shipmethod--selected' : '')}
                onClick={() => setShippingMethod("homeDelivery")}
            >   
                <HouseFill style={{fontSize: "2em", marginRight: "0.3em"}}/>
                <span>{{'EN': 'Home Delivery', 'FR': 'Livraison à domicile'}[language]}</span>
            </Button>
            <Button
                className={'button-shipmethod' + (shippingMethod === "mondialRelay" ? ' button-shipmethod--selected' : '')}
                onClick={() => setShippingMethod("mondialRelay")}
            >
                <img src={MondialRelay} alt="Mondial Relay" style={{width: "3em", paddingRight: "0.3em"}}/>
                <span style={{paddingRight: "0.5em"}}>Mondial Relay</span>
            </Button>
        </div>
    );
};

export default ShippingMethodToggle;